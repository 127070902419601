import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Grid, Stack, Button, Divider, Tooltip, IconButton, Typography } from '@mui/material';

import { getAllBrands } from 'src/server/api/brand';
import { getAllCategory } from 'src/server/api/category';
import { getAllProductLabel } from 'src/server/api/productLabel';

import { RHFTextField } from 'src/components/hook-form';
import OspSelectAutocomplete from 'src/components/hook-form/osp-select';

function CategoriesFeatures({ control }) {
  const dispatch = useDispatch();
  const { categoryList, brandList, productDetails, productLabels } = useSelector((state) => ({
    categoryList: state.category.categories,
    productLabels: state.productLabel.productLabels,
    brandList: state.brand.brands,
    productDetails: state.product.productDetails,
  }));

  // ////// brand  select start ////////

  const [searchValueBrand, setSearchValueBrand] = useState('');

  const handleTextFieldChangeBrand = (value) => {
    setSearchValueBrand(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueBrand,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };

    dispatch(getAllBrands(credentials));
  }, [dispatch, searchValueBrand]);

  const brandOptions = [
    ...(brandList?.brands || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const defaultBrand = useMemo(
    () =>
      productDetails?.brands?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [productDetails]
  );

  // ////// brand  select end ////////

  //  product label select end
  const [searchValueProductLabel, setSearchValueProductLabel] = useState('');

  const handleTextFieldChangeProductLabel = (value) => {
    setSearchValueProductLabel(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueProductLabel || '',
      dispatch,
      limit: 10,
      state: filterDataObj,
    };
    dispatch(getAllProductLabel(credentials));
  }, [dispatch, searchValueProductLabel]);

  const productLabelOptions = [
    ...(productLabels?.productLabels || []).map((results) => ({
      label: results?.title?.english,
      value: results?.uid,
    })),
  ];

  const defaultProductLabel = useMemo(
    () =>
      productDetails?.product_labels?.map((item) => ({
        label: item?.title?.english || '',
        value: item?.uid || '',
      })) || [],
    [productDetails]
  );

  // ////// product label select end ////////

  // //////category select start /////////
  const [searchValueCategory, setSearchValueCategory] = useState('');

  const handleTextFieldChangeCategory = (value) => {
    setSearchValueCategory(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };

    const credentials = {
      page: 1,
      search: searchValueCategory,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };
    dispatch(getAllCategory(credentials));
  }, [dispatch, searchValueCategory]);

  const categoryOptions = [
    ...(categoryList?.categories || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const defaultCategory = useMemo(
    () =>
      productDetails?.categories?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [productDetails]
  );
  console.log(defaultCategory);
  //  //////// category select end ////////

  const {
    fields: englishFields,
    // append: appendEnglish,
    insert: insertEnglish, 

    remove: removeEnglish,
  } = useFieldArray({
    control,
    name: 'specifications.english',
  });

  // const handleAddEnglish = () => {
  //   appendEnglish({ title: '', value: '' });
  // };
 
  const handleAddEnglish = () => {
    const newField = { title: '', value: '' };
    insertEnglish(0, newField);
  };


  const handleRemoveEnglish = (index) => {
    removeEnglish(index);
  };

  const {
    fields: arabicFields,
    // append: appendArabic,
    insert: insertArabic, 

    remove: removeArabic,
  } = useFieldArray({
    control,
    name: 'specifications.arabic',
  });

  const handleAddArabic = () => {
    const newField = { title: '', value: '' };
    insertArabic(0, newField);
  };


  const handleRemoveArabic = (index) => {
    removeArabic(index);
  };

  // useEffect(() => {
  //   if (defaultMainCategory.value) {
  //     setValue('categories', defaultMainCategory);
  //   }
  // }, [defaultMainCategory, setValue]);

  

  return (
    <Stack spacing={3} sx={{ p: 2, mt: 5 }}>
      <Grid container maxWidth="xl" spacing={2}>
        <Grid xs={12} md={6} item>
          <OspSelectAutocomplete
            name="categories"
            control={control}
            defaultValue={defaultCategory || []}
            options={categoryOptions}
            multiple
            label="Categories"
            handleTextFieldChange={handleTextFieldChangeCategory}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <OspSelectAutocomplete
            name="brands"
            control={control}
            options={brandOptions}
            defaultValue={defaultBrand || []}
            multiple
            label="Brands"
            handleTextFieldChange={handleTextFieldChangeBrand}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <OspSelectAutocomplete
            name="product_labels"
            control={control}
            options={productLabelOptions}
            multiple
            label="Product Labels"
            handleTextFieldChange={handleTextFieldChangeProductLabel}
            defaultValue={defaultProductLabel}
            // required
          />
        </Grid>
      </Grid>
      {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

      <Typography variant="subtitle2">
        Specifications
        <Divider sx={{ mt: 1 }} />
      </Typography>
      <Grid container maxWidth="xl" spacing={3} mt={0}>
        <Grid xs={12} md={6} item>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="caption">In English</Typography>
            <Button
              type="button"
              onClick={handleAddEnglish}
              variant="contained"
              size="small"
              color="success"
              sx={{ width: 'fit-content' }}
              startIcon={<AddRoundedIcon fontSize="small" />}
            >
              Add
            </Button>
          </Stack>
          <Divider sx={{ my: 3 }} />

          <Box sx={{ p: 0, borderRadius: '13px' }}>
            <Stack
              sx={{
                p: 1,
                mx: 1,
                height: 260,
                overflowY: 'auto',
                flexDirection: 'column',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'grey',
                  borderRadius: '15px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#F5F5F5',
                  borderRadius: '15px',
                },
              }}
            >
              {englishFields?.map((item, index) => (
                <>
                  <Stack key={item.id} direction="row" alignItems="flex-start" spacing={1.5}>
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{
                        width: 1,
                        alignItems: 'center',
                      }}
                    >
                      <RHFTextField
                        size="small"
                        name={`specifications.english[${index}].title`}
                        label="Title"
                      />
                      <RHFTextField
                        size="small"
                        name={`specifications.english[${index}].value`}
                        label="Value"
                      />
                    </Stack>
                    <Tooltip title="Remove this specification">
                      <IconButton
                        onClick={() => handleRemoveEnglish(index)}
                        className="icon-button-delete"
                        sx={{ width: '35px', height: '35px' }}
                      >
                        <CloseIcon fontSize="small" color="disabled" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {englishFields?.length !== index + 1 && <Divider sx={{ my: 3 }} />}
                </>
              ))}
            </Stack>
          </Box>
        </Grid>
        <Grid xs={12} md={6} item>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="caption">In Arabic</Typography>
            <Button
              type="button"
              onClick={handleAddArabic}
              variant="contained"
              size="small"
              color="success"
              sx={{ width: 'fit-content' }}
              startIcon={<AddRoundedIcon fontSize="small" />}
            >
              Add
            </Button>
          </Stack>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ p: 0, borderRadius: '13px' }}>
            <Stack
              sx={{
                p: 1,
                mx: 1,
                height: 260,
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                  width: '8px', // Width of the scrollbar
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'grey', // Color of the scrollbar thumb
                  borderRadius: '15px', // Border radius of the scrollbar thumb
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#F5F5F5', // Color of the scrollbar track
                  borderRadius: '15px', // Border radius of the scrollbar track
                },
              }}
            >
              {arabicFields?.map((item, index) => (
                <>
                  <Stack key={item.id} alignItems="flex-start" direction="row" spacing={1.5}>
                    <Stack direction="column" spacing={2} sx={{ width: 1, alignItems: 'center' }}>
                      <Stack className="input-arabic" width="100%">
                        <RHFTextField
                          size="small"
                          name={`specifications.arabic[${index}].title`}
                          label="عنوان"
                        />
                      </Stack>
                      <Stack className="input-arabic" width="100%">
                        <RHFTextField
                          size="small"
                          name={`specifications.arabic[${index}].value`}
                          label="تخصيص"
                        />
                      </Stack>
                    </Stack>
                    <Tooltip title="Remove this specification">
                      <IconButton
                        onClick={() => handleRemoveArabic(index)}
                        className="icon-button-delete"
                        sx={{ width: '35px', height: '35px' }}
                      >
                        <CloseIcon fontSize="small" color="disabled" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {arabicFields?.length !== index + 1 && <Divider sx={{ my: 3 }} />}
                </>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default CategoriesFeatures;

CategoriesFeatures.propTypes = {
  control: PropTypes.any,
};
