import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Divider, Typography } from '@mui/material';

import { createSeoItem, updateSeoItemModule } from 'src/server/api/seoData';

import FormProvider, { RHFEditor, RHFTextField } from 'src/components/hook-form';

import OspImageUploader from '../upload';

function SEOPage({ moduleName, moduleId, updateData }) {
  const { loading } = useSelector((state) => ({
    loading: state.seoData.loading,
  }));
  const dispatch = useDispatch();
  // ..
  const theme = useTheme();

  const SeoSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string().required('Title in English is required'),
      arabic: Yup.string(),
    }),
    descriptionEnglish: Yup.string(),
    descriptionArabic: Yup.string(),
    og_tag_title: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    og_tag_description: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    // og_tag_description: Yup.string(),
    // og_tag_url: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (updateData) {
      return {
        title: {
          english: updateData?.title?.english,
          arabic: updateData?.title?.arabic,
        },
        og_tag_title: {
          english: updateData?.og_tag_title?.english,
          arabic: updateData?.og_tag_title?.arabic,
        },
        // description: {
        //   english: updateData?.description?.english,
        //   arabic: updateData?.description?.arabic,
        // },
        descriptionEnglish: updateData?.description?.english || '',
        descriptionArabic: updateData?.description?.arabic || '',
        og_tag_description: {
          english: updateData?.og_tag_description?.english,
          arabic: updateData?.og_tag_description?.arabic,
        },
        // og_tag_url: updateData?.og_tag_url,
      };
    }
    return {
      title: {
        english: '',
        arabic: '',
      },
      og_tag_title: {
        english: '',
        arabic: '',
      },
      // description: {
      //   english: '',
      //   arabic: '',
      // },
      og_tag_description: {
        english: '',
        arabic: '',
      },
      descriptionEnglish: '',
      descriptionArabic: '',
      // og_tag_url: '',
    };
  }, [updateData]);

  const [imagePreview, setImagePreview] = useState([]);

  const methods = useForm({
    resolver: yupResolver(SeoSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (moduleName && moduleId) {
      const createCredentials = {
        state: {
          ...data,
          module: moduleName,
          module_id: moduleId,
          og_tag_image: imagePreview?.length > 0 ? imagePreview[0] : {},
          description: {
            english: data?.descriptionEnglish,
            arabic: data?.descriptionArabic,
          },
        },
        dispatch,
      };
      ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
        delete createCredentials?.state[key];
      });
      const updateCredentials = {
        state: {
          ...data,
          module: moduleName,
          module_id: moduleId,
          // og_tag_image: imagePreview,
          og_tag_image: imagePreview?.length > 0 ? imagePreview[0] : {},

          description: {
            english: data?.descriptionEnglish,
            arabic: data?.descriptionArabic,
          },
        },
        seoId: updateData?.uid,
        dispatch,
      };
      ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
        delete updateCredentials?.state[key];
      });

      if (updateData?.uid) {
        dispatch(updateSeoItemModule(updateCredentials));
      } else {
        dispatch(createSeoItem(createCredentials));
      }
    } else {
      console.log('no module found');
    }
  });

  useEffect(() => {
    if (updateData?.og_tag_image?.public_id) {
      setImagePreview([updateData?.og_tag_image]);
    }
  }, [updateData?.og_tag_image]);

  console.log(imagePreview);
  console.log(updateData?.og_tag_image);
  return (
    <Stack spacing={3} sx={{ p: 2, mt: 0, width: '100%' }}>
      <FormProvider sx={{ width: '100%' }} methods={methods} onSubmit={onSubmit}>
        <Stack direction="row" justifyContent="end" ml={2} mb={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="small"
            loading={isSubmitting || loading}
            sx={{
              ml: 2,
              // width: '150px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            Save Changes
          </LoadingButton>
        </Stack>
        <Grid container maxWidth="xl" spacing={2}>
          <Grid xs={12} md={6} item>
            <RHFTextField
              name="title.english"
              label="English"
              type="string"
              required
              size="small"
              inputShrink
              placeholder="Title"
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack
              className={theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}
              width="100%"
            >
              <RHFTextField
                name="title.arabic"
                label="Arabic"
                type="string"
                size="small"
                inputShrink
                placeholder="Title"
              />
            </Stack>
          </Grid>
          {/* <Grid xs={12} md={6} item>
            <RHFTextField
              name="description.english"
              label="English"
              type="string"
              size="small"
              multiline
              rows={6}
              inputShrink
              placeholder="Description here"
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack className="input-arabic" width="100%">
              <RHFTextField
                name="description.arabic"
                label="Arabic"
                type="string"
                size="small"
                multiline
                rows={6}
                inputShrink
                placeholder="Description here"
              />
            </Stack>
          </Grid> */}
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5}>
              <Typography variant="caption" sx={{ pl: 1 }}>
                In English
              </Typography>
              <RHFEditor  placeholder="Write description" name="descriptionEnglish" />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5} className="editor-arabic">
              <Typography variant="caption" sx={{ pl: 1 }}>
                In Arabic
              </Typography>
              <RHFEditor  name="descriptionArabic" placeholder="اكتب الوصف" />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" sx={{ mb: 2, mt: 3 }}>
          OG Tag <Divider sx={{ mt: 1 }} />
        </Typography>
        <Grid container maxWidth="xl" spacing={2} mt={2}>
          <Grid xs={12} md={12} item>
            <Grid container spacing={2}>
              <Grid xs={12} md={12} item>
                <RHFTextField
                  name="og_tag_title.english"
                  label="Title"
                  type="string"
                  inputShrink
                  size="small"
                  placeholder="Title"
                />
              </Grid>
              <Grid xs={12} md={12} item>
                <Stack
                  className={theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}
                  width="100%"
                >
                  <RHFTextField
                    name="og_tag_title.arabic"
                    label="Arabic"
                    type="string"
                    inputShrink
                    size="small"
                    placeholder="Title"
                  />
                </Stack>
              </Grid>
              <Grid xs={12} md={12} item sx={{ gap: 2, display: 'flex', flexDirection: 'row' }}>
                <RHFTextField
                  name="og_tag_description.english"
                  label="Description"
                  type="string"
                  size="small"
                  placeholder="Description"
                  multiline
                  rows={3}
                />
                <Stack
                  className={theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}
                  width="100%"
                >
                  <RHFTextField
                    name="og_tag_description.arabic"
                    label="Arabic"
                    type="string"
                    size="small"
                    placeholder="Arabic"
                    multiline
                    rows={3}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid xs={12} md={12} item>
            <RHFTextField
              name="og_tag_url"
              label="URL"
              type="string"
              required
              size="small"
              inputShrink
              placeholder=" Tag Url"
            />
          </Grid> */}
        </Grid>

        <Stack
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            alignItems: 'end',
            justifyContent: 'end',
            mt: 3,
            mr: 2,
            mb: 2,
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="small"
            loading={isSubmitting || loading}
            sx={{
              ml: 2,
              // width: '150px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </FormProvider>
      <Stack sx={{ pt: 0, mb: 5 }}>
        <Typography variant="subtitle2" sx={{ mb: 0, pb: 2 }}>
          Image <Divider sx={{ mt: 1 }} />
        </Typography>
        <OspImageUploader single setImagesPreview={setImagePreview} imagesPreview={imagePreview} />
      </Stack>
    </Stack>
  );
}

export default SEOPage;

SEOPage.propTypes = {
  moduleName: PropTypes.any,
  moduleId: PropTypes.any,
  updateData: PropTypes.any,
};
