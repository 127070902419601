import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProductGroup,
  getAllProductGroup,
  deleteProductGroup,
  archiveProductGroup,
  restoreProductGroup,
  getProductGroupCounts,
  getProductGroupDetails,
  updateProductGroupStatus,
  updateProductGroupDetails,
} from '../api/productGroup';

export const productGroupSlice = createSlice({
  name: 'product-group',
  initialState: {
    loading: false,
    error: {},
    productGroups: {},
    productGroupCounts: {},
    subcategories: {},
    staffDropList: [],
    productGroupDetails: {},
    statusLoading: false,
  },
  reducers: {
    clearProductGroupError: (state) => {
      state.error = true;
    },
    clearProductGroupDetailsState: (state) => {
      state.productGroupDetails = {};
    },
  },
  extraReducers: {
    // create
    [createProductGroup.pending]: (state) => {
      state.loading = true;
    },
    [createProductGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    [createProductGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllProductGroup.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.productGroups = action.payload;
      state.error = {};
    },
    [getAllProductGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get product-group count
    [getProductGroupCounts.pending]: (state) => {
      state.loading = true;
    },
    [getProductGroupCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.productGroupCounts = action.payload;
      state.error = {};
    },
    [getProductGroupCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getProductGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.productGroupDetails = action.payload;
      state.error = {};
    },
    [getProductGroupDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updateProductGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductGroupDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updateProductGroupDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const modifiedDistrictList = {
        ...jsonState,
        subcategories: jsonState.subcategories?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },

    [updateProductGroupDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateProductGroupStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    // [updateProductGroupStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.productGroups;
    //   const modifiedProductGroupList = {
    //     ...jsonState,
    //     productGroups: jsonState?.productGroups?.map((item) =>
    //       item?.uid === action.payload?.uid
    //         ? { ...item, is_active: action.payload?.is_active }
    //         : item
    //     ),
    //   };
    //   state.statusLoading = false;
    //   state.productGroups = modifiedProductGroupList;
    //   state.error = {};
    // },

    // [updateProductGroupStatus.rejected]: (state, action) => {
    //   state.statusLoading = false;
    //   state.error = action.error;
    // },
    [updateProductGroupStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productGroups;
      const modifiedProductGroupList = {
        ...jsonState,
        productGroups: jsonState?.productGroups?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedProductGroupList2 = {
        ...jsonState,
        productGroups: jsonState?.productGroups?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.productGroups =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedProductGroupList2
          : modifiedProductGroupList;
      state.error = {};
    },
    // archive product
    [archiveProductGroup.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveProductGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productGroups;
      const modifiedList = {
        ...jsonState,
        productGroups: jsonState.productGroups?.filter(
          (productGroups) => productGroups.uid !== action.payload
        ),
      };
      state.loading = false;
      state.productGroups = modifiedList;
      state.error = {};
    },
    [archiveProductGroup.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // delete
    [deleteProductGroup.pending]: (state) => {
      state.loading = true;
    },
    [deleteProductGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productGroups;
      const modifiedList = {
        ...jsonState,
        productGroups: jsonState.productGroups?.filter(
          (productGroups) => productGroups.uid !== action.payload
        ),
      };
      state.loading = false;
      state.productGroups = modifiedList;
      state.error = {};
    },
    [deleteProductGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // restore product
    [restoreProductGroup.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreProductGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productGroups;
      const modifiedList = {
        ...jsonState,
        productGroups: jsonState.productGroups?.filter(
          (productGroups) => productGroups.uid !== action.payload
        ),
      };
      state.loading = false;
      state.productGroups = modifiedList;
      state.error = {};
    },
    [restoreProductGroup.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductGroupError, clearProductGroupDetailsState } = productGroupSlice.actions;

export default productGroupSlice.reducer;
