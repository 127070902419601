import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Typography } from '@mui/material';

import { RHFSwitch, RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

function ExtraDetails({ watch }) {
  const type = watch('order_type');
  const isPreorderAdvance = watch('is_preorder_advance_needed');
  return (
    <Stack spacing={3} sx={{ p: 2, mt: 3 }}>
      <Grid container maxWidth="xl" spacing={3}>
        <Grid xs={12} md={3} item>
          <RHFSwitch name="is_free_delivery_product" label="Is free delivery available" />
        </Grid>
        <Grid xs={12} md={3} item>
          <RHFSwitch name="is_return_viable" label="Is return viable" />
        </Grid>

        <Grid xs={12} md={6} item />

        <Grid xs={12} md={6} item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" mr={2.5}>
              Order Type:
            </Typography>
            <RHFRadioGroup
              row
              name="order_type"
              options={[
                { label: 'Direct Order', value: 'DIRECT' },
                { label: 'Preorder', value: 'PREORDER' },
              ]}
            />
            {type === 'PREORDER' && (
              <RHFSwitch name="is_preorder_advance_needed" label="Is Preorder Advance Needed" />
            )}
          </Stack>
        </Grid>

        {isPreorderAdvance && (
          <Grid xs={12} md={4} item>
            <RHFTextField
              name="preorder_advance_amount"
              label="Preorder Advance Amount"
              type="number"
              placeholder="Preorder Advance Amount"
              size="small"
            />
          </Grid>
        )}
        {!isPreorderAdvance && <Grid xs={12} md={3} item />}
        <Grid xs={12} md={4} item>
          <RHFTextField
            name="weight"
            label="Weight"
            type="number"
            placeholder="Weight"
            size="small"
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <RHFTextField
            name="dimension"
            label="Dimension"
            type="string"
            placeholder="Dimension"
            size="small"
          />
        </Grid>

        {/* <Grid xs={12} md={12} item>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Pages
            <Divider sx={{ mt: 1 }} />
          </Typography>

          <Grid xs={12} md={12} item mb={2}>
            <RHFSwitch name="is_page_attached" label="Is Page Available" />
          </Grid>
          <Grid xs={12} md={4} item>
            <OspSelectAutocomplete
              name="productPage"
              control={control}
              options={OPTIONS}
              label="Select Page"
              handleTextFieldChange={handleTextFieldChangePage}
            />
          </Grid>
        </Grid> */}
      </Grid>
    </Stack>
  );
}

export default ExtraDetails;

ExtraDetails.propTypes = {
  watch: PropTypes.any,
};
