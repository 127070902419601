import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
// @mui
import { Stack, Dialog, IconButton, DialogTitle, DialogContent } from '@mui/material';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

UserAddressDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setOpenUserAddressId: PropTypes.func,
};

export default function UserAddressDialog({ open, setOpen, setOpenUserAddressId, isUpdate }) {
  // const theme = useTheme();
  // const router = useRouter();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setOpenUserAddressId({});
  };
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => ({
    loading: state.user.loading,
  }));

  const AddressSchema = Yup.object().shape({
    address_line_1: Yup.string(),
    address_line_2: Yup.string(),
    pincode: Yup.number(),
    district: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?.uid) {
      return {
        address_line_1: isUpdate?.address_line_1 || '',
        address_line_2: isUpdate?.address_line_2 || '',
        pincode: isUpdate?.pincode || 0,
        district: isUpdate?.district || '',
        state: isUpdate?.state || '',
        country: isUpdate?.country || '',
      };
    }
    return {
      address_line_1: '',
      address_line_2: '',
      pincode: '',
      district: '',
      state: '',
      country: '',
    };
  }, [
    isUpdate?.uid,
    isUpdate?.address_line_1,
    isUpdate?.address_line_2,
    isUpdate?.pincode,
    isUpdate?.district,
    isUpdate?.state,
    isUpdate?.country,
  ]);
  const methods = useForm({
    resolver: yupResolver(AddressSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  console.log(errors);

  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const CreateCredentials = {
      state: {
        ...data,
      },
      dispatch,
      handleClose,
      handleClear,
    };

    const UpdateCredentials = {
      state: {
        ...data,
      },
      bannerId: isUpdate?.uid,

      dispatch,
      handleClose,
      handleClear,
    };
    if (isUpdate?.uid) {
      console.log('update', UpdateCredentials);
      // dispatch(updateBannerDetails(UpdateCredentials));
    } else {
      console.log('create', CreateCredentials);

      // dispatch(createBanner(CreateCredentials));
    }
  });

  const renderAddressForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <Stack spacing={1.5}>
        <RHFTextField
          name="address_line_1"
          label="Address line 1"
          type="string"
          placeholder="Address line 1"
          size="small"
        />
      </Stack>
      <Stack>
        <RHFTextField
          name="address_line_2"
          label="Address line 2"
          type="string"
          placeholder="Address line 2"
          size="small"
        />
      </Stack>

      <Stack direction="row" gap={2}>
        <RHFTextField
          name="country"
          label="Country"
          type="string"
          placeholder="Country"
          size="small"
        />
        <RHFTextField name="state" label="State" type="string" placeholder="State" size="small" />
      </Stack>
      <Stack direction="row" gap={2}>
        <RHFTextField
          name="district"
          label="District"
          type="string"
          placeholder="District"
          size="small"
        />
        <RHFTextField
          name="pincode"
          label="PIN Code"
          type="number"
          size="small"
          placeholder="PIN Code"
        />
      </Stack>
      <Stack sx={{ marginBottom: '1rem' }} justifyContent="end" alignItems="center">
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting || loading}
          sx={{ color: 'white', ml: 'auto' }}
          color="inherit"
        >
          {isUpdate?.uid ? 'Update Address' : 'Create Address'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?.uid ? 'Update Address' : 'Create Address'}

          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              p: 0.5,
              top: 10,
              right: 10,
              transform: 'scale(0.8)',
              position: 'absolute',
              color: 'common.white',
              bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme1) => alpha(theme1.palette.grey[900], 0.72),
              },
            }}
          >
            <Iconify icon="mingcute:close-line" width={14} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderAddressForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
