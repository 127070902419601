import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import { paths } from 'src/routes/routes/paths';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ComponentTitle() {
  const location = useLocation()
  const pageTitle = location?.pathname?.split('/dashboard/')[1]
  const formattedSting = String(pageTitle)

  const renderPageTitle = (title) => {
    const parts = title.split('/');
    const updateIndex = parts.indexOf('update');
    const displayParts = updateIndex !== -1 ? parts.slice(0, updateIndex + 1) : parts;

    const getLinkPath = (part) => {
      if (['collection', 'category', 'brand'].includes(part)) {
        return paths.dashboard.inventory[part];
      }

      switch (part) {
        case 'product':
          return paths.dashboard.inventory.root;
        case 'inventory':
          return paths.dashboard.inventory.root;
        case 'special-sale':
          return paths.dashboard.inventory.special_sale
        case 'product-group':
          return paths.dashboard.inventory.product_group
        case 'site-settings':
          return paths.dashboard.settings

        default:
          return null;
      }
    };

    return displayParts?.map((part, index) => {
      const linkPath = getLinkPath(part);

      return (
        <Fragment key={index}>
          {linkPath ? (
            <Link to={linkPath} style={{ textDecoration: 'none', color: 'inherit' }}>
              {part.replace(/-/g, ' ')}
            </Link>
          ) : (
            part.replace(/-/g, ' ')
          )}
          {index < displayParts.length - 1 && <KeyboardArrowRightRoundedIcon color='disabled' fontSize='small' />}
        </Fragment>
      );
    });

    // return displayParts?.map((part, index) => (
    //   <Fragment key={index}>
    //     {part}
    //     {index < displayParts.length - 1 && <KeyboardArrowRightRoundedIcon color='disabled' fontSize='small' />}
    //   </Fragment>
    // ));
  };
  return (
    <Stack >
      <Typography variant="subtitle2" textAlign="start" sx={{ display: "flex", alignItems: "center", mx: 2, textTransform: "capitalize" }}>
        {renderPageTitle(formattedSting)}
      </Typography>
    </Stack>
  );

}
