import { useState,useEffect,useCallback } from 'react';

// Import the user icon

// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import { useSelector,useDispatch } from 'react-redux';

import Card from '@mui/material/Card';
import {  Tab,Container } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import { getUserDetails } from 'src/server/api/user';
import Avatar from 'src/assets/images/common/img.png';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import coverUrl from 'src/assets/images/common/Image.svg';

import Iconify from 'src/components/iconify';

import Profile from './Profile';
import Favorites from './Favourite';
import ProfileCover from './ProfileCover';
import UserCartList from './Cart/userCartList';
import UserOrderList from './Order/UserOrderList';

// ----------------------------------------------------------------------

export default function UserViewPage() {
  const params = useParams();

  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }));
  const userId = params.id;
  // console.log(userId);

  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'favorites',
      label: 'Favorites',
      icon: <Iconify icon="solar:heart-bold" width={24} />,
    },
    {
      value: 'cart',
      label: 'Cart',
      icon: <Iconify icon="flowbite:cart-outline" width={24} />,
    },
    {
      value: 'orders',
      label: 'Orders',
      icon: <Iconify icon="material-symbols:order-approve" width={24} />,
    },
  ];
  useEffect(() => {
    if (userId) {
      const credentials = {
        userId,
        dispatch,
      };
      dispatch(getUserDetails(credentials));
    }
  }, [dispatch, userId]);

  return (
    <>
      <MetaHelmet title="User view" />

      <Container>
        <Card >

        <Card
          sx={{
            mb: 3,
            height: 290, 
            borderRadius: '15px 15px 0 0px', 

          }}
        >
          <ProfileCover 
          role={userDetails?.phone} name={userDetails?.name} 
          avatarUrl={Avatar} 

          coverUrl={coverUrl} />

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {currentTab === 'profile' && <Profile />}

        {currentTab === 'favorites' && <Favorites />}

        {currentTab === 'cart' && <UserCartList />}

        {currentTab === 'orders' && <UserOrderList />}
        </Card>

      </Container>
    </>
  );
}

UserViewPage.propTypes = {};
