import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCurrency,
  getAllCurrency,
  deleteCurrency,
  archiveCurrency,
  restoreCurrency,
  getCurrencyCounts,
  getCurrencyDetails,
  changeCurrencyStatus,
  updateCurrencyDetails,
  checkCurrencyTitleValid,
} from '../api/currency';

export const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    loading: false,
    error: {},
    currencies: {},
    staffDropList: [],
    CurrencyDetails: {},
    isCurrencyTitleValid: {},
    currencyCounts: {},
    CurrencyTitleLoading: false,
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearCurrencyError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createCurrency.pending]: (state) => {
      state.loading = true;
    },
    [createCurrency.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const newCurrencyList = jsonState?.currencies;

      const modifiedCurrencyList = {
        ...jsonState,
        currencies: Array.isArray(newCurrencyList)
          ? [...newCurrencyList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.currencies = action.payload.uid ? modifiedCurrencyList : jsonState;
      state.error = {};
    },
    [createCurrency.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCurrency.pending]: (state) => {
      state.loading = true;
    },
    [getAllCurrency.fulfilled]: (state, action) => {
      state.loading = false;
      state.currencies = action.payload;
      state.error = {};
    },
    [getAllCurrency.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get currency status
    [changeCurrencyStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [changeCurrencyStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const modifiedCurrencyList = {
        ...jsonState,
        currencies: jsonState.currencies?.map((i) =>
          i.uid === action?.payload?.response?.uid ? action?.payload?.response : i
        ),
      };
      const modifiedCurrencyList2 = {
        ...jsonState,
        currencies: jsonState?.currencies?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.currencies =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedCurrencyList2
          : modifiedCurrencyList;
      state.error = {};
    },
    [changeCurrencyStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // check currency title
    [checkCurrencyTitleValid.pending]: (state) => {
      state.currencyTitleLoading = true;
    },
    [checkCurrencyTitleValid.fulfilled]: (state, action) => {
      state.currencyTitleLoading = false;
      state.isCurrencyTitleValid = action.payload;
      state.error = {};
    },
    [checkCurrencyTitleValid.rejected]: (state, action) => {
      state.currencyTitleLoading = false;
      state.error = action.error;
    },

    [getCurrencyDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCurrencyDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.CurrencyDetails = action.payload;
      state.error = {};
    },
    [getCurrencyDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCurrencyDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCurrencyDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const modifiedCurrencyList = {
        ...jsonState,
        currencies: jsonState.currencies?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };
      state.loading = false;
      state.currencies = modifiedCurrencyList;
      state.error = {};
    },

    [updateCurrencyDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteCurrency.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteCurrency.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const modifiedCurrencyList = {
        ...jsonState,
        currencies: jsonState.currencies?.filter((currencies) => currencies.uid !== action.payload),
      };
      state.delLoading = false;
      state.currencies = modifiedCurrencyList;
      state.error = {};
    },
    [deleteCurrency.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive currency
    [archiveCurrency.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveCurrency.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const modifiedCurrencyList = {
        ...jsonState,
        currencies: jsonState.currencies?.filter((currency) => currency.uid !== action.payload),
      };

      state.delLoading = false;
      state.currencies = modifiedCurrencyList;
      state.error = {};
    },
    [archiveCurrency.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore currency
    [restoreCurrency.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreCurrency.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.currencies;
      const modifiedCurrencyList = {
        ...jsonState,
        currencies: jsonState.currencies?.filter((currency) => currency.uid !== action.payload),
      };

      state.delLoading = false;
      state.currencies = modifiedCurrencyList;
      state.error = {};
    },
    [restoreCurrency.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    [getCurrencyCounts.pending]: (state) => {
      state.loading = true;
    },
    [getCurrencyCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.currencyCounts = action.payload;
      state.error = {};
    },
    [getCurrencyCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearCurrencyError } = currencySlice.actions;

export default currencySlice.reducer;
