import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import Stack from '@mui/material/Stack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Card, Tooltip, CardMedia, Typography, IconButton } from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import NoImg from 'src/assets/other/ImgNull.jpg';

import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function ProductDetails({ productDetails, handleRemoveProduct, index, moveImage }) {
  const ItemType = 'IMAGE';

  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Card
      ref={(node) => ref(drop(node))}
      sx={{
        p: 1.5,
        borderRadius: '10px',
        position: 'relative',
        overflow: 'unset',
        cursor: 'grab',
      }}
    >
      <CardMedia
        component="img"
        className="custom-img-size"
        // src={item?.images ? item?.images[0]?.url : demoImg}
        src={
          productDetails?.images.length > 0 // eslint-disable-next-line react-hooks/rules-of-hooks
            ? useResource(productDetails?.images[0]?.url, 'w_500')
            : NoImg
        }
        alt="products"
        sx={{ aspectRatio: 1 / 1 }}
      />
      <Stack direction="row" justifyContent="space-between" py={1}>
        <Typography variant="caption" color="#637381">
          {productDetails?.sapId}
        </Typography>
        <Typography variant="caption" color="#14AE5C">
          In Stock({productDetails?.stock})
        </Typography>
      </Stack>
      <TextMaxLine line={2} variant="subtitle2">
        {productDetails?.label}
      </TextMaxLine>
      <Typography display="flex" alignItems="center" pt={1} variant="subtitle1">
        OMR{' '}
        <Typography pl={1} variant="h6">
          {productDetails?.offer_price || productDetails?.price}
        </Typography>
        {productDetails?.offerPrice && (
          <Typography pl={0.5} color="#637381" sx={{ textDecoration: 'line-through' }}>
            {productDetails?.price}
          </Typography>
        )}
      </Typography>
      <Tooltip title="Remove">
        <IconButton
          size="small"
          color="default"
          sx={{ backgroundColor: '#dadada', position: 'absolute', top: -10, right: -10 }}
        >
          <CloseOutlinedIcon
            fontSize="small"
            onClick={() => handleRemoveProduct(productDetails?.value, index)}
          />
        </IconButton>
      </Tooltip>
    </Card>
  );
}

ProductDetails.propTypes = {
  productDetails: PropTypes.any,
  handleRemoveProduct: PropTypes.any,
  index: PropTypes.any,
  moveImage: PropTypes.any,
};
