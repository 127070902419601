import { createAsyncThunk } from '@reduxjs/toolkit';

// import { paths } from 'src/routes/routes/paths';

import { get, put } from '../http';
import { activeSnack } from '../store/common';

export const getSettingsDetails = createAsyncThunk('settings/details', async ({ dispatch }) => {
  try {
    const response = await get(`/settings/admin`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSettingsDetails = createAsyncThunk(
  'settings/update',
  async ({ state, settingsId, dispatch, navigate }) => {
    try {
      const response = await put(`/settings/admin/${settingsId} `, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Settings updated successfully!' }));
        // navigate(paths.dashboard.root);
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
