import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// import Avatar from '@mui/material/Avatar';
import { Stack, Switch, Tooltip, IconButton, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onViewRow, onChangeStatus, tab }) {
  const { name, email, country_code, phone, is_active, uid, total_loyalty_points } = row;
  console.log(total_loyalty_points);
  // const confirm = useBoolean();

  // const handleDeleteRow = () => {
  //   onDeleteRow(uid);
  //   confirm.onFalse();
  // };

  return (
    <TableRow hover selected={selected}>
      <TableCell
        sx={{
          // cursor: 'pointer',
          maxWidth: '200px',
          minWidth: '150px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          // whiteSpace: 'nowrap',
          pr: 2,
        }}
      >
        <TextMaxLine line={2}>
          <Typography variant="caption">{name || '--'}</Typography>
        </TextMaxLine>
      </TableCell>
      <TableCell>
        {/* <Typography variant="caption">{{country_code}{phone} || '--'}</Typography> */}
        <Typography variant="caption">{`${country_code} ${phone || '--'} `}</Typography>
      </TableCell>
      <TableCell
        sx={{
          // cursor: 'pointer',
          maxWidth: '200px',
          minWidth: '150px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          // whiteSpace: 'nowrap',
          pr: 2,
        }}
      >
        <TextMaxLine line={2}>
          <Typography variant="caption">{email || '--'}</Typography>
        </TextMaxLine>
      </TableCell>

      <TableCell>
        <Typography variant="caption">{total_loyalty_points || 0}</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center">
          <Switch
            color="success"
            checked={is_active}
            onChange={(e) => onChangeStatus(uid, e.target.checked)}
          />{' '}
          <Typography variant="caption">Active</Typography>
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ px: 1 }}>
        <Stack
          columnGap={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: 'text.secondary',
            typography: 'caption',
            mb: 2,
            border: '1px solid #919eab24',
            padding: 0.6,
            borderRadius: '14px',
            width: 'fit-content',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Tooltip title="View User">
              <IconButton
                color="info"
                onClick={onViewRow}
                // onClick={() => handleViewClick(uid)}

                sx={{ borderRadius: '6px' }}
              >
                <Iconify icon="eva:eye-outline" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

UserTableRow.propTypes = {
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  onChangeStatus: PropTypes.any,
  tab: PropTypes.any,
};
