import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCoupon,
  getAllCoupon,
  deleteCoupon,
  archiveCoupon,
  restoreCoupon,
  getCouponCounts,
  getCouponDetails,
  changeCouponStatus,
  updateCouponDetails,
  getRedeemedUserList,
  checkCouponNameValid,
} from '../api/coupon';

export const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    loading: false,
    error: {},
    coupons: {},
    redeemedUsers: {},
    credLoading: false,
    staffDropList: [],
    couponDetails: {},
    couponCounts: {},
    isCouponValid: {},
    couponNameLoading: false,
    delLoading: false,
    statusLoading: false,
  },
  reducers: {
    clearCouponError: (state) => {
      state.error = true;
    },
    clearCouponDetailsState: (state) => {
      state.couponDetails = {};
    },
  },
  extraReducers: {
    // create coupon details
    [createCoupon.pending]: (state) => {
      state.credLoading = true;
    },
    [createCoupon.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const newCouponList = jsonState?.coupons;

      const modifiedCouponList = {
        ...jsonState,
        coupons: Array.isArray(newCouponList)
          ? [...newCouponList, action.payload]
          : [action.payload],
      };

      state.credLoading = false;
      state.couponDetails = action.payload;
      state.coupons = modifiedCouponList;
      state.error = {};
    },
    [createCoupon.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    // get all coupons
    [getAllCoupon.pending]: (state) => {
      state.loading = true;
    },
    [getAllCoupon.fulfilled]: (state, action) => {
      state.loading = false;
      state.coupons = action.payload;
      state.error = {};
    },
    [getAllCoupon.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCouponCounts.pending]: (state) => {
      state.loading = true;
    },
    [getCouponCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.couponCounts = action.payload;
      state.error = {};
    },
    [getCouponCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single coupon details
    [getCouponDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCouponDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.couponDetails = action.payload;
      state.error = {};
    },
    [getCouponDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update coupon details
    [updateCouponDetails.pending]: (state) => {
      state.credLoading = true;
    },
    // [updateCouponDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCouponDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const modifiedCouponList = {
        ...jsonState,
        coupons: jsonState.coupons?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.credLoading = false;
      // state.coupons = modifiedCouponList;
      state.couponDetails = action.payload;
      state.coupons = modifiedCouponList;
      state.error = {};
    },
    // [updateCouponDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.coupons;
    //   console.log(jsonState)
    //   const modifiedCouponList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCouponDetails.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    //  coupon status change
    [changeCouponStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [changeCouponStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const modifiedCouponList = {
        ...jsonState,
        coupons: jsonState?.coupons?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedCategoryList2 = {
        ...jsonState,
        coupons: jsonState?.coupons?.filter((item) => item?.uid !== action.payload?.response?.uid),
      };
      state.statusLoading = false;
      state.coupons =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedCategoryList2
          : modifiedCouponList;
      state.error = {};
    },

    [changeCouponStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
    // check coupon name
    [checkCouponNameValid.pending]: (state) => {
      state.couponNameLoading = true;
    },
    [checkCouponNameValid.fulfilled]: (state, action) => {
      state.couponNameLoading = false;
      state.isCouponValid = action.payload;
      state.error = {};
    },
    [checkCouponNameValid.rejected]: (state, action) => {
      state.couponNameLoading = false;
      state.error = action.error;
    },
    [deleteCoupon.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteCoupon.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const modifiedCouponList = {
        ...jsonState,
        coupons: jsonState.coupons?.filter((coupons) => coupons.uid !== action.payload),
      };
      state.delLoading = false;
      state.coupons = modifiedCouponList;
      state.error = {};
    },
    [deleteCoupon.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive coupon
    [archiveCoupon.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveCoupon.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const modifiedCouponList = {
        ...jsonState,
        coupons: jsonState.coupons?.filter((coupon) => coupon.uid !== action.payload),
      };

      state.delLoading = false;
      state.coupons = modifiedCouponList;
      state.error = {};
    },
    [archiveCoupon.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore coupon
    [restoreCoupon.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreCoupon.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.coupons;
      const modifiedCouponList = {
        ...jsonState,
        coupons: jsonState.coupons?.filter((coupon) => coupon.uid !== action.payload),
      };

      state.delLoading = false;
      state.coupons = modifiedCouponList;
      state.error = {};
    },
    [restoreCoupon.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // redeemed users list
    [getRedeemedUserList.pending]: (state) => {
      state.loading = true;
    },
    [getRedeemedUserList.fulfilled]: (state, action) => {
      state.loading = false;
      state.redeemedUsers = action.payload;
      state.error = {};
    },
    [getRedeemedUserList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearCouponDetailsState } = couponSlice.actions;

export default couponSlice.reducer;
