import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
// import Avatar from '@mui/material/Avatar';
import { Stack, Switch, Divider, Tooltip, MenuItem, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import { restoreSale, hardDeleteSale, deleteSpecialSale } from 'src/server/api/specialSale';

import Iconify from 'src/components/iconify';
import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover from 'src/components/custom-popover/custom-popover';

// ----------------------------------------------------------------------

export default function SpecialSaleTableRow({
  row,
  selected,
  onViewRow,
  onChangeStatus,
  loading,
  tab,
}) {
  const { title, is_deleted, uid, is_active, start_date, end_date } = row;

  const confirm = useBoolean();

  const popover = usePopover();

  const dispatch = useDispatch();

  const today = new Date();
  const start = new Date(start_date);
  const end = new Date(end_date);

  const formattedToday = moment(today).format('YYYY-MM-DD');
  console.log(formattedToday);
  const formattedStart = moment(start).format('YYYY-MM-DD');
  const formattedEnd = moment(end).format('YYYY-MM-DD');

  const handleCurrentPhase = () => {
    if (formattedToday < formattedStart) {
      return {
        text: 'Upcoming',
        color: 'secondary',
      };
    }
    if (formattedToday >= formattedStart && formattedToday <= formattedEnd) {
      return {
        text: 'Ongoing',
        color: 'success',
      };
    }
    if (formattedToday > formattedEnd) {
      return {
        text: 'Expired',
        color: 'error',
      };
    }
    return {
      text: 'Expired',
      color: 'error',
    };
  };

  const handleClose = () => {
    confirm.onFalse();
  };

  const handleArchive = () => {
    const credentials = {
      dispatch,
      specialSaleId: uid,
      handleClose,
    };
    dispatch(deleteSpecialSale(credentials));
  };

  const onDeleteRow = () => {
    const credentials = {
      dispatch,
      specialSaleId: uid,
      handleClose,
    };
    dispatch(hardDeleteSale(credentials));
  };

  const handleHardDelete = () => {
    confirm.onTrue();
    popover.onClose();
  };

  const handleUnarchive = () => {
    const credentials = {
      dispatch,
      specialSaleId: uid,
      handleClose,
    };
    dispatch(restoreSale(credentials));
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">{title?.english || '-----'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">{start_date ? fDate(start_date) : '-----'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">{end_date ? fDate(end_date) : '-----'}</Typography>
          </TextMaxLine>
        </TableCell>
        {/* <TableCell>
          <Stack>
            {logo?.url ? (
              <img src={logo?.url} alt={logo?.alt_text?.english} className="product-all-list-img" />
            ) : (
              <img src={NoImg} alt="No" className="product-all-list-img" />
            )}
          </Stack>
        </TableCell> */}
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <Label variant="soft" color={handleCurrentPhase()?.color || 'error'}>
            {handleCurrentPhase()?.text || 'Expired'}
          </Label>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Switch
              color="success"
              checked={is_active}
              onChange={(e) => onChangeStatus(uid, e.target.checked)}
            />{' '}
            <Typography variant="caption">Active</Typography>
          </Stack>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <Stack
            columnGap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" alignItems="center">
              <Tooltip title="Edit sale">
                <IconButton onClick={onViewRow} color="info" sx={{ borderRadius: '6px' }}>
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems="center">
              {/* <IconButton
                sx={{ borderRadius: '6px' }}
                color={popover.open ? 'inherit' : 'default'}
                onClick={popover.onOpen}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton> */}
              {tab === 'isdelete' ? (
                <>
                  <Tooltip title="Restore Sale">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="default"
                      onClick={handleUnarchive}
                    >
                      <UnarchiveRoundedIcon color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Sale">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="error"
                      onClick={handleHardDelete}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Archive Sale">
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleArchive}
                  >
                    <ArchiveRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={onViewRow}>
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem onClick={onViewRow}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        {is_deleted === false && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <MenuItem
              onClick={() => {
                confirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          </>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        // content="Are you sure want to delete?"
        content={`Are you sure want to delete '${title?.english}' ?`}
        action={
          <LoadingButton
            variant="contained"
            color="error"
            loading={loading}
            // onClick={onDeleteRow(uid);handleDeleteRow()}
            onClick={onDeleteRow}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}

SpecialSaleTableRow.propTypes = {
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  onChangeStatus: PropTypes.any,
  loading: PropTypes.any,
  tab: PropTypes.any,
};
