import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import PickupLocationList from './pickupLocationList';

export default function PickupLocation() {
  return (
    <>
      <MetaHelmet title="Pickup Location" />
      <PickupLocationList />
    </>
  );
}
