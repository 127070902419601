import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import moment from 'moment';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tab, Tabs, Stack, alpha } from '@mui/material';

import SEOPage from 'src/pages/Common/Others/SEOPage';
import OspImageUploader from 'src/pages/Common/upload';
import { getSeoItemModule } from 'src/server/api/seoData';
import { getProductDetails, updateProductDetails } from 'src/server/api/product';

import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';

import Appearance from './sections/Appearance';
import PriceDetails from './sections/PriceDetails';
import ExtraDetails from './sections/ExtraDetails';
import BasicDetails from './sections/BasicDetails';
import WarrantyExchange from './sections/WarrantyExchange';
import CategoriesFeatures from './sections/CategoriesFeatures';

// ----------------------------------------------------------------------

export default function UpdateProductForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const productId = params.id;

  const { loading, productDetails, seoData } = useSelector((state) => ({
    loading: state.product.loading,
    productDetails: state.product.productDetails,
    error: state.product.error,
    seoData: state.seoData.seoData,
  }));

  useEffect(() => {
    if (productId) {
      const credentials = {
        productId,
        dispatch,
      };
      dispatch(getProductDetails(credentials));
    }
  }, [dispatch, productId]);

  // get current seo data
  useEffect(() => {
    const filterDataObj = {
      module: 'PRODUCT',
      module_id: productId,
    };

    const credentials = {
      search: '',
      dispatch,
      state: filterDataObj,
    };

    if (productId) {
      dispatch(getSeoItemModule(credentials));
    }
  }, [productId, dispatch]);

  const currentSeoData = seoData;

  // state
  const [imagePreview, setImagePreview] = useState([]); // data : public_id:'', url:"", alt_text:{arabic:"",english:""}

  const ProductSchema = Yup.object().shape({
    // url: Yup.string(),
    sap_id: Yup.string().required('SAP ID required'),
    name: Yup.object().shape({
      english: Yup.string().required('Product name required'),
      arabic: Yup.string(),
    }),

    // description: Yup.object().shape({
    //   english: Yup.string(),
    //   arabic: Yup.string(),
    // }),
    descriptionArabic: Yup.string(),
    descriptionEnglish: Yup.string(),

    specifications: Yup.object().shape({
      english: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          value: Yup.string(),
        })
      ),
      arabic: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          value: Yup.string(),
        })
      ),
    }),
    video_id: Yup.string(),
    search_keywords: Yup.array().of(Yup.string()),
    product_labels: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    categories: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    brands: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    tax_categories: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        percentage: Yup.number(),
      })
    ),

    type: Yup.boolean(),
    order_type: Yup.string(),
    url: Yup.string(),
    is_preorder_advance_needed: Yup.boolean(),
    preorder_advance_amount: Yup.number(),
    has_variants: Yup.boolean(),
    // is_variant: Yup.boolean(),
    cost: Yup.number(),
    price: Yup.number(), // calculate the price
    price_without_tax: Yup.number(),
    offer_price: Yup.number(),
    offer_price_without_tax: Yup.number(),
    offer_start_date: Yup.string(),
    offer_end_date: Yup.string(),
    stock: Yup.number(),
    min_order_quantity: Yup.number(),
    max_order_quantity: Yup.number(),
    weight: Yup.number(),
    dimension: Yup.string(),
    is_return_viable: Yup.boolean(),
    is_free_delivery_available: Yup.boolean(),

    // warranty_note: Yup.object().shape({
    //   english: Yup.string(),
    //   arabic: Yup.string(),
    // }),
    warrantyNoteArabic: Yup.string(),
    warrantyNoteEnglish: Yup.string(),

    // exchange_policy_note: Yup.string(),
    exchangePolicyArabic: Yup.string(),
    exchangePolicyEnglish: Yup.string(),

    is_page_attached: Yup.boolean(),
    page: Yup.object(),
    product_groups: Yup.array().of(Yup.string()),
    // average_rating: Yup.number(),
    // review_count: Yup.number(),
    //
    is_free_delivery_product: Yup.boolean(),
  });

  console.log(productDetails?.url);

  const defaultValues = useMemo(() => {
    if (productId) {
      return {
        // url: '',
        specifications: productDetails?.specifications,
        sap_id: productDetails?.sap_id || '',
        name: {
          english: productDetails?.name?.english || '',
          arabic: productDetails?.name?.arabic || '',
        },
        descriptionArabic: productDetails?.description?.arabic || '',
        descriptionEnglish: productDetails?.description?.english || '',
        video_id: productDetails?.video_id || '',
        search_keywords: productDetails?.search_keywords || '',
        url: productDetails?.url || '',

        type: productDetails?.type === 'SPECIAL',
        order_type: productDetails?.order_type || '',
        is_preorder_advance_needed: productDetails?.is_preorder_advance_needed || false,
        preorder_advance_amount: productDetails?.preorder_advance_amount || 0,
        has_variants: productDetails?.has_variants || false,
        cost: productDetails?.cost || 0,
        price: productDetails?.price || 0,
        price_without_tax: productDetails?.price_without_tax || 0,
        offer_price: productDetails?.offer_price || 0,
        offer_price_without_tax: productDetails?.offer_price_without_tax || 0,
        // offer_start_date: moment(productDetails?.offer_start_date).format('YYYY-MM-DD') || null,
        // offer_end_date: moment(productDetails?.offer_end_date).format('YYYY-MM-DD') || null,
        offer_start_date: productDetails?.offer_start_date
          ? moment(productDetails.offer_start_date).format('YYYY-MM-DD')
          : '',

        offer_end_date: productDetails?.offer_end_date
          ? moment(productDetails.offer_end_date).format('YYYY-MM-DD')
          : '',

        // tax_categories: { // single dropdown
        //   label: productDetails?.tax_categories?.title?.english || '',
        //   value: productDetails?.tax_categories?.percentage || '',
        // },
        // tax_categories: productDetails?.tax_categories?.map((i) => i.uid), // multiple select default value set in custom file
        // product_labels: productDetails?.product_labels?.map((i) => i.uid), // multiple select default value set in custom file
        // categories: productDetails?.categories?.map((i) => i.uid), // multiple select default value set in custom file
        // brands: productDetails?.brands, // multiple select default value set in custom file

        stock: productDetails?.stock || 0,
        min_order_quantity: productDetails?.min_order_quantity || 0,
        max_order_quantity: productDetails?.max_order_quantity || 0,
        weight: productDetails?.weight || 0,
        dimension: productDetails?.dimension || '',
        is_return_viable: productDetails?.is_return_viable || false,
        is_free_delivery_available: productDetails?.is_free_delivery_available || false,

        warrantyNoteArabic: productDetails?.warranty_note?.arabic || '',
        warrantyNoteEnglish: productDetails?.warranty_note?.english || '',

        exchangePolicyArabic: productDetails?.exchange_policy_note?.arabic || '',
        exchangePolicyEnglish: productDetails?.exchange_policy_note?.english || '',

        is_page_attached: productDetails?.is_page_attached || false,
        is_free_delivery_product: productDetails?.is_free_delivery_product || false,
        is_active: productDetails?.is_active || false,

        page: {},
        product_groups: [],
      };
    }
    return {
      specifications: {
        english: [
          {
            title: '',
            value: '',
          },
        ],
        arabic: [
          {
            title: '',
            value: '',
          },
        ],
      },
      sap_id: '',
      // url: '',
      name: {
        english: '',
        arabic: '',
      },
      // description: {
      //   english: '<p><br></p>',
      //   arabic: '<p><br></p>',
      // },
      descriptionArabic: '',
      descriptionEnglish: '',
      video_id: '',
      url: '',
      search_keywords: [],
      product_labels: [],
      categories: [],
      brands: [],
      tax_categories: [],

      type: false,
      order_type: '',
      is_preorder_advance_needed: false,
      preorder_advance_amount: 0,
      has_variants: false,
      // is_variant: false,
      cost: 0,
      price: 0,
      offer_price: 0,
      price_without_tax: 0,
      offer_price_without_tax: 0,
      offer_start_date: '',
      offer_end_date: '',
      stock: 0,
      min_order_quantity: 0,
      max_order_quantity: 0,
      weight: 0,
      dimension: '',
      is_return_viable: false,
      is_free_delivery_available: false,

      // warranty_note: '',
      warrantyNoteArabic: '',
      warrantyNoteEnglish: '',

      // exchange_policy_note: '',
      exchangePolicyArabic: '',
      exchangePolicyEnglish: '',

      is_page_attached: false,
      is_free_delivery_product: false,
      page: {},
      product_groups: [], // not in  ui
      is_active: true,
    };
  }, [productDetails, productId]);

  console.log('defaultValues', defaultValues);

  const methods = useForm({
    resolver: yupResolver(ProductSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  // default data
  useEffect(() => {
    if (productDetails?.images?.length >= 1) {
      setImagePreview(productDetails?.images);
    }
  }, [productDetails?.images]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  //   useEffect(() => {
  //     const initialProductName = watch('name.english');
  //     if (initialProductName) {
  //       const formattedUrl = formatUrl(initialProductName);
  //       reset({
  //         ...defaultValues,
  //         url: formattedUrl
  //       })
  //     }
  //   }, [defaultValues, reset, watch]);

  //  useEffect(() => {
  //   const productName = watch('name.english');
  //     const formattedUrl = formatUrl(productName);
  //     const productUrl = watch('url');

  //     const finalUrl = formattedUrl;

  //     if (productUrl) {
  //       setValue('url', productUrl);
  //     } else if (formattedUrl) {
  //       setValue('url', finalUrl);
  //     }
  //   }, [setValue, watch]);

  // useEffect(() => {
  //   const unFormattedProductUrl = unFormatUrl(productUrl)
  // if (productName === unFormattedProductUrl) {
  //   reset({
  //     ...defaultValues,
  //     url: productDetails?.url,
  //   });
  // }
  // }, [productName, productUrl, reset, defaultValues, productDetails?.url]);

  // Watch for changes to the URL and update the manual change state
  // useEffect(() => {
  //   const subscription = watch((value, { name }) => {
  //     if (name === 'url') {
  //       setIsUrlManuallyChanged(true);
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // const [isUrlManuallyChanged, setIsUrlManuallyChanged] = useState(false);

  // const productName = watch('name.english');
  // const productUrl = watch('url');

  // useEffect(() => {
  //   const formattedProductUrl = formatUrl(productName);
  //   if (productDetails?.url && productDetails?.url !== formattedProductUrl) {
  //     reset({
  //       ...defaultValues,
  //       url: productDetails?.url,
  //     });
  //   }
  // }, [defaultValues, productDetails?.url, productName, reset]);

  // useEffect(() => {
  //   // const unFormattedProductUrl = unFormatUrl(productUrl)
  //   // const formattedProductUrl = formatUrl(productName)
  //   if (productName && !productUrl && !isUrlManuallyChanged) {
  //     const formattedUrl = formatUrl(productName);
  //     reset({
  //       ...defaultValues,
  //       url: formattedUrl,
  //     });
  //   }
  // }, [productName, productUrl, isUrlManuallyChanged, reset, defaultValues]);

  // useEffect(() => {
  //   const unFormattedProductUrl = unFormatUrl(productUrl);
  //   if (isUrlManuallyChanged) {
  //     const formattedUrl = formatUrl(productUrl);
  //     setValue('url', formattedUrl);
  //   } else if (productName && productName !== unFormattedProductUrl) {
  //     const formattedUrl = formatUrl(productName);
  //     setValue('url', formattedUrl);
  //   }
  // }, [productName, productUrl, isUrlManuallyChanged, setValue]);

  // const handleUrlManuallyChange = (value) => {
  //   const formattedProductName = formatUrl(productName);
  //   if (value === formattedProductName) {
  //     setIsUrlManuallyChanged(false);
  //   } else {
  //     setIsUrlManuallyChanged(true);
  //   }
  // };

  const onSubmit = async (data) => {
    console.log(data);
    const initialData = {
      ...data,
      description: {
        arabic: data.descriptionArabic,
        english: data.descriptionEnglish,
      },
      is_preorder_advance_needed: watch('preorder_advance_amount') >= 1,
      type: data?.type ? 'SPECIAL' : 'NORMAL',
      images: imagePreview,
      categories: data?.categories?.map((i) => i.value),
      brands: data?.brands?.map((i) => i.value),
      product_labels: data?.product_labels?.map((i) => i.value),
      tax_categories: data?.tax_categories?.map((i) => i.value),
      warranty_note: {
        arabic: data.warrantyNoteArabic || '',
        english: data.warrantyNoteEnglish || '',
      },
      exchange_policy_note: {
        arabic: data?.exchangePolicyArabic || '',
        english: data?.exchangePolicyEnglish || '',
      },
      price: 0,
      offer_price: 0,
      offer_start_date: data?.offer_start_date === 'Invalid date' ? null : data?.offer_start_date,
      offer_end_date: data?.offer_end_date === 'Invalid date' ? null : data?.offer_end_date,

      page: data?.productPage?.value,
    };

    // Remove the unwanted keys
    [
      'descriptionArabic',
      'descriptionEnglish',
      'orderType',
      'exchangePolicyArabic',
      'exchangePolicyEnglish',
      'warrantyNoteArabic',
      'warrantyNoteEnglish',
    ]?.forEach((key) => {
      delete initialData[key];
    });

    const credentials = {
      state: initialData,
      productId,
      dispatch,
    };
    console.log(credentials);
    dispatch(updateProductDetails(credentials));
  };

  // useEffect(() => {
  //   reset({});
  // }, [reset]);

  const STATUS_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'appearance', label: 'Appearance' },
    { value: 'categoriesFeatures', label: 'Classifications' },
    { value: 'priceDetails', label: 'Price & Stock' },
    // { value: 'variants', label: 'Variants' },
    { value: 'extras', label: 'Extras' },
    { value: 'seo', label: 'SEO' },
    { value: 'warrantyExchange', label: 'Warranty & Exchange' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  return (
    <>
      <Typography variant="subtitle1">Update Product</Typography>
      <Typography variant="caption">Please complete the product information.</Typography>
      <Grid container maxWidth="xl" spacing={2} mt={2}>
        <Grid xs={12} md={12}>
          {loading ? (
            <Stack sx={{ height: '50px' }}>
              <LoadingScreen />
            </Stack>
          ) : (
            <Card sx={{ pb: 2 }}>
              <Tabs
                value={tabChange.value}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab, key) => (
                  <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
                ))}
              </Tabs>

              {tabChange.value === 'appearance' && (
                <Stack sx={{ p: 2 }} spacing={0} width="100%" direction="column">
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: '600', mb: 0, pb: imagePreview?.length > 0 ? 0 : 2 }}
                  >
                    Product Images
                  </Typography>
                  <OspImageUploader
                    setImagesPreview={setImagePreview}
                    imagesPreview={imagePreview}
                    folder="products"
                  />
                </Stack>
              )}

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ width: '400px', top: '50px', position: 'absolute', right: 0 }}>
                  <Grid
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                    }}
                  >
                    {errors?.name?.english?.message ? (
                      <Typography variant="caption" color="red">
                        Please fill the required fields!
                      </Typography>
                    ) : (
                      <Stack />
                    )}
                    {tabChange.value !== 'seo' && (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="small"
                        loading={isSubmitting || loading}
                        sx={{
                          ml: 2,
                          // width: '150px',
                          backgroundColor: '#106E58',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#0b4e3e',
                          },
                        }}
                      >
                        Save Changes
                      </LoadingButton>
                    )}
                  </Grid>
                </Stack>
                <>
                  {/* // Tab component */}
                
                  {tabChange.value === 'basicDetails' && (
                    <BasicDetails
                      watch={watch}
                      setValue={setValue}
                    // handleUrlManuallyChange={handleUrlManuallyChange}
                    />
                  )}
                    {tabChange.value === 'appearance' && (
                    <Appearance
                      watch={watch}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                    />
                  )}
                  {tabChange.value === 'categoriesFeatures' && (
                    <CategoriesFeatures control={control} />
                  )}
                  {tabChange.value === 'priceDetails' && (
                    <PriceDetails watch={watch} control={control} />
                  )}
                  {/* {tabChange.value === 'variants' && <Variants />} */}
                  {tabChange.value === 'extras' && <ExtraDetails control={control} watch={watch} />}
                  {tabChange.value === 'warrantyExchange' && <WarrantyExchange />}
                </>
                <Grid
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                  }}
                >
                  {errors?.name?.english?.message ? (
                    <Typography variant="caption" color="red">
                      Please fill the required fields!
                    </Typography>
                  ) : (
                    <Stack />
                  )}
                  {tabChange.value !== 'seo' && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="small"
                      loading={isSubmitting || loading}
                      sx={{
                        ml: 2,
                        width: '150px',
                        backgroundColor: '#106E58',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Save Changes
                    </LoadingButton>
                  )}
                </Grid>
              </FormProvider>

              {tabChange.value === 'seo' && (
                <SEOPage
                  moduleName="PRODUCT"
                  moduleId={productDetails?.uid}
                  updateData={currentSeoData}
                />
              )}
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
