import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
// import Avatar from '@mui/material/Avatar';
import { Stack, Switch, Tooltip, IconButton, Typography, CircularProgress } from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import { useBoolean } from 'src/hooks/use-boolean';

import NoImg from 'src/assets/other/ImgNull.jpg';
import { deleteBrand, archiveBrand, restoreBrand } from 'src/server/api/brand';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function BrandTableRow({ row, tab, selected, onViewRow, onChangeStatus }) {
  const { name, main_brand, uid, logo, is_active } = row;

  const confirm = useBoolean();
  const dispatch = useDispatch();

  const popover = usePopover();
  const handleClose = () => {
    confirm.onFalse();
  };
  // console.log('tab', tab);
  const { delLoading } = useSelector((state) => ({
    delLoading: state.brand.delLoading,
  }));
  const handleDeleteBrandConfirmClick = () => {
    const credentials = {
      dispatch,
      brandId: uid,
      handleClose,
    };
    dispatch(deleteBrand(credentials));
  };

  const handleArchiveBrandClick = () => {
    const credentials = {
      dispatch,
      brandId: uid,
      handleClose,
    };
    dispatch(archiveBrand(credentials));
  };
  const handleDeleteBrand = () => {
    confirm.onTrue();
    popover.onClose();
  };
  const handleUnarchiveBrand = () => {
    const credentials = {
      dispatch,
      brandId: uid,
      handleClose,
    };
    dispatch(restoreBrand(credentials));
  };
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">{name?.english || '--'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell>
          <Stack>
            {logo?.url ? (
              // <img src={logo?.url} alt={logo?.alt_text?.english} className="product-all-list-img" />
              <img
                src={
                  logo?.url
                    ? // eslint-disable-next-line react-hooks/rules-of-hooks
                      useResource(logo?.url, 'w_500')
                    : NoImg
                }
                alt="First"
                className="product-all-list-img"
              />
            ) : (
              <img src={NoImg} alt="No" className="product-all-list-img" />
            )}
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">{main_brand?.name?.english || '--'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Switch
              color="success"
              checked={is_active}
              onChange={(e) => onChangeStatus(uid, e.target.checked)}
            />{' '}
            <Typography variant="caption">Active</Typography>
          </Stack>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          {/* <Stack
            columnGap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" alignItems="center">
              <IconButton onClick={onViewRow} color="info" sx={{ borderRadius: '6px' }}>
                <Iconify icon="solar:eye-bold" />
              </IconButton>
            </Stack>

            <Stack direction="row" alignItems="center">
              <IconButton
                sx={{ borderRadius: '6px' }}
                color={popover.open ? 'inherit' : 'default'}
                onClick={popover.onOpen}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </Stack>
          </Stack> */}
          <Stack
            columnGap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" alignItems="center">
              <Tooltip title="Edit Brand">
                <IconButton color="info" onClick={onViewRow} sx={{ borderRadius: '6px' }}>
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems="center">
              {tab === 'is_deleted' ? (
                <>
                  <Tooltip title="Restore Brand">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="default"
                      onClick={handleUnarchiveBrand}
                    >
                      <UnarchiveRoundedIcon color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Brand">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="error"
                      onClick={handleDeleteBrand}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Archive Brand">
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleArchiveBrandClick}
                  >
                    <ArchiveRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        // content="Are you sure want to delete?"
        content={`Are you sure want to delete '${name?.english}' ?`}
        action={
          <Button
            variant="contained"
            color="error"
            // onClick={onDeleteRow(uid);handleDeleteRow()}
            onClick={handleDeleteBrandConfirmClick}
          >
            Delete {delLoading && <CircularProgress size={14} sx={{ mx: 1 }} />}
          </Button>
        }
      />
    </>
  );
}

BrandTableRow.propTypes = {
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  onChangeStatus: PropTypes.any,
  tab: PropTypes.any,
};
