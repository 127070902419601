import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createCategory = createAsyncThunk(
  'category/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/category/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        router.push(paths.dashboard.inventory.category);
        dispatch(activeSnack({ type: 'success', message: 'Category created' }));
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCategoryDetails = createAsyncThunk(
  'category/update',
  async ({ state, categoryId, dispatch }) => {
    try {
      const URL = `/category/admin/${categoryId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Category updated' }));
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCategory = createAsyncThunk(
  'category/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/category/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getCategoryCounts = createAsyncThunk('category/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/category/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getAllMainCategory = createAsyncThunk(
  'main/category/list',
  async ({ search, dispatch, state }) => {
    try {
      const response = await post(
        `/category/admin/all?page=${1}&search=${search && search}&limit=${10}`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getCategoryDetails = createAsyncThunk(
  'category/single',
  async ({ categoryId, dispatch }) => {
    try {
      const response = await get(`/category/admin/single/${categoryId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeCategoryStatus = createAsyncThunk(
  'category/status',
  async ({ state, categoryId, dispatch, tab }) => {
    try {
      const URL = `/category/admin/status/active/${categoryId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const checkCategoryNameValid = createAsyncThunk(
  'category/check/category-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/category/admin/english-name/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const archiveCategory = createAsyncThunk(
  'category/archive',
  async ({ categoryId, dispatch }) => {
    try {
      const response = await post(`/category/admin/delete/${categoryId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Category archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const restoreCategory = createAsyncThunk(
  'category/unarchive',
  async ({ categoryId, dispatch }) => {
    try {
      const response = await post(`/category/admin/restore/${categoryId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Category archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteCategory = createAsyncThunk(
  'category/delete',
  async ({ categoryId, dispatch, handleClose }) => {
    try {
      const response = await del(`/category/admin/hard-delete/${categoryId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Category deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
