import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createTaxCategory,
  getAllTaxCategory,
  deleteTaxCategory,
  archiveTaxCategory,
  restoreTaxCategory,
  getTaxCategoryCounts,
  getTaxCategoryDetails,
  changeTaxCategoryStatus,
  updateTaxCategoryDetails,
  checkTaxCategoryTitleValid,
} from '../api/taxCategory';

export const taxCategorySlice = createSlice({
  name: 'taxCategory',
  initialState: {
    loading: false,
    error: {},
    taxCategories: {},
    staffDropList: [],
    TaxCategoryDetails: {},
    isTaxCategoryTitleValid: {},
    taxTitleLoading: false,
    statusLoading: false,
    delLoading: false,

    taxCategoryCounts: {},
  },
  reducers: {
    clearTaxCategoryError: (state) => {
      state.error = true;
    },
    clearTaxCategoryData: (state) => {
      state.TaxCategoryDetails = {};
    },
  },
  extraReducers: {
    [createTaxCategory.pending]: (state) => {
      state.loading = true;
    },
    [createTaxCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const newTaxCategoryList = jsonState?.taxCategories;

      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: Array.isArray(newTaxCategoryList)
          ? [action.payload, ...newTaxCategoryList]
          : [action.payload],
      };
      state.loading = false;
      state.taxCategories = action.payload.uid ? modifiedTaxCategoryList : jsonState;
      state.error = {};
    },
    [createTaxCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTaxCategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllTaxCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.taxCategories = action.payload;
      state.error = {};
    },
    [getAllTaxCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getTaxCategoryCounts.pending]: (state) => {
      state.loading = true;
    },
    [getTaxCategoryCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.taxCategoryCounts = action.payload;
      state.error = {};
    },
    [getTaxCategoryCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get tax category status
    [changeTaxCategoryStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    // [changeTaxCategoryStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.taxCategories;
    //   const modifiedTaxCategoryList = {
    //     ...jsonState,
    //     taxCategories: jsonState.taxCategories?.map((i) =>
    //       i.uid === action.payload.uid ? action?.payload : i
    //     ),
    //   };
    //   state.statusLoading = false;
    //   state.taxCategories = modifiedTaxCategoryList;
    //   state.error = {};
    // },
    [changeTaxCategoryStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: jsonState?.taxCategories?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedTaxCategoryList2 = {
        ...jsonState,
        taxCategories: jsonState?.taxCategories?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.taxCategories =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedTaxCategoryList2
          : modifiedTaxCategoryList;
      state.error = {};
    },
    [changeTaxCategoryStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // check tax-category title
    [checkTaxCategoryTitleValid.pending]: (state) => {
      state.taxTitleLoading = true;
    },
    [checkTaxCategoryTitleValid.fulfilled]: (state, action) => {
      state.taxTitleLoading = false;
      state.isTaxCategoryTitleValid = action.payload;
      state.error = {};
    },
    [checkTaxCategoryTitleValid.rejected]: (state, action) => {
      state.taxTitleLoading = false;
      state.error = action.error;
    },

    [getTaxCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getTaxCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.TaxCategoryDetails = action.payload;
      state.error = {};
    },
    [getTaxCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateTaxCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateTaxCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: jsonState.taxCategories?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };
      state.loading = false;
      state.taxCategories = modifiedTaxCategoryList;
      state.error = {};
    },

    [updateTaxCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // delete tax category
    [deleteTaxCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteTaxCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: jsonState.taxCategories?.filter(
          (taxCategories) => taxCategories.uid !== action.payload
        ),
      };
      state.delLoading = false;
      state.taxCategories = modifiedTaxCategoryList;
      state.error = {};
    },
    [deleteTaxCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive  tax category
    [archiveTaxCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveTaxCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: jsonState.taxCategories?.filter(
          (category) => category.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.taxCategories = modifiedTaxCategoryList;
      state.error = {};
    },
    [archiveTaxCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore tax category
    [restoreTaxCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreTaxCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.taxCategories;
      const modifiedTaxCategoryList = {
        ...jsonState,
        taxCategories: jsonState.taxCategories?.filter(
          (category) => category.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.taxCategories = modifiedTaxCategoryList;
      state.error = {};
    },
    [restoreTaxCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearTaxCategoryError, clearTaxCategoryData } = taxCategorySlice.actions;

export default taxCategorySlice.reducer;
