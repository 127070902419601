import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Typography } from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import NoImg from 'src/assets/other/ImgNull.jpg';

import TextMaxLine from 'src/components/text-max-line';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

// ----------------------------------------------------------------------

export default function UserCartTableRow({ row, selected, onChangeStatus, tab }) {
  const { name, sap_id, stock, offer_price, price, categories, images } = row;

  return (
    <TableRow hover selected={selected}>
        <TableCell sx={{ minWidth: '250px' }}>
          <Stack direction="row" alignItems="center" gap="17px">
            <Stack>
              {images.length > 0 ? (
                // <img src={images[0].url} alt="First" className="product-all-list-img" />
                <img
                  src={
                    images[0]?.url
                      ? // eslint-disable-next-line react-hooks/rules-of-hooks
                        useResource(images[0]?.url, 'w_500')
                      : NoImg
                  }
                  alt="First"
                  className="product-all-list-img"
                />
              ) : (
                <img src={NoImg} alt="No" className="product-all-list-img" />
              )}
            </Stack>
            <Stack>
             
                <TextMaxLine line={2} sx={{ maxWidth: 150, textAlign: 'start' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {name?.english}
                  </Typography>
                </TextMaxLine>
              <Typography color="#919EAB" variant="caption" pl={1}>
                {sap_id}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

      <TableCell>
        <Stack spacing={1} flexDirection="row" alignItems="center" flexWrap="wrap">
          {categories?.map((item, key) => (
            // <Button variant='text' key={key} size='small'>
            //   {item?.name?.english}
            // </Button>
            <Typography variant="caption" key={key} sx={{ fontWeight: '500' }}>
              {item?.name?.english}{' '}
              {categories?.length > 1 && categories?.length !== key + 1 ? ',' : ''}
            </Typography>
            // <Chip label={item?.name?.english} key={key} variant="filled" color="default" />
          ))}
        </Stack>
      </TableCell>

      <TableCell>
        {stock <= 0 ? (
          <Typography color="red" variant="caption" sx={{}}>
            Out of stock
          </Typography>
        ) : (
          stock
        )}
      </TableCell>

      <TableCell>
        {/* OMR {offer_price || price} */}
        {offer_price ? (
          <>
            <Typography variant="caption" sx={{ textDecoration: 'line-through' }}>
              {price}
            </Typography>
            &nbsp;{' '}
            <Typography variant="caption" fontWeight="bold">
              OMR {offer_price}
            </Typography>
          </>
        ) : (
          <Typography variant="caption" fontWeight="bold">
            OMR {price}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

UserCartTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onChangeStatus: PropTypes.any,
  tab: PropTypes.any,
};
