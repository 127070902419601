import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import UserList from './sections/userList';

export default function Users() {
  return (
    <>
      <MetaHelmet title="User" />
      <UserList />
    </>
  );
}
