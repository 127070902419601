import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';

import { getAllProduct } from 'src/server/api/product';

import OspSelectAutocomplete from 'src/components/hook-form/osp-select';

import ProductDetails from './ProductDetails';

// ----------------------------------------------------------------------

export default function ProductData({
  control,
  handleAddProduct,
  productDetails,
  isDisabled,
  addItems,
  handleRemoveProduct,
  setValue,
}) {
  const dispatch = useDispatch();

  const { products } = useSelector((state) => ({
    products: state.product.products,
  }));

  const [searchValueProduct, setSearchValueProduct] = useState('');

  const mainProductOptions = [
    ...(products?.products || []).map((results) => ({
      label:`${results?.sap_id || ''} - ${results?.name?.english || ''}`,
      value: results?.uid,
      sapId: results?.sap_id,
      price: results?.price,
      offer_price: results?.offer_price,
      stock: results?.stock,
      images: results?.images,
    })),
  ];

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
        is_active: true,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueProduct,
      dispatch,
      state: filterDataObj,
    };
    dispatch(getAllProduct(credentials));
  }, [dispatch, searchValueProduct]);

  const handleTextFieldChangeProduct = (value) => {
    setSearchValueProduct(value);
  };

  useEffect(() => {
    if (products?.products?.length > 0) {
      const data = productDetails?.map((item) => item?.value);
      const filterDataObj = {
        filter_data: {
          is_deleted: false,
          is_active: true,
          uid: {
            $nin: data,
          },
        },
      };
      const credentials = {
        dispatch,
        state: filterDataObj,
      };
      dispatch(getAllProduct(credentials));
    }
  }, [dispatch, productDetails, products?.products?.length]);

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...productDetails];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setValue('items', updatedImages);
  };

  return (
    <>
      <Stack direction="row" p={4} spacing={2}>
        <OspSelectAutocomplete
          name="product"
          control={control}
          options={mainProductOptions}
          label="Product"
          placeholder="Search a product"
          handleTextFieldChange={handleTextFieldChangeProduct}
        />
        <Button
          // type="button"
          onClick={handleAddProduct}
          variant="contained"
          color="success"
          startIcon={<AddIcon sx={{ m: 0 }} />}
          sx={{ height: 40 }}
          disabled={isDisabled}
        >
          Add
        </Button>
      </Stack>
      <AnimatePresence initial={false}>
        <DndProvider backend={HTML5Backend}>
          <Stack
            mx={4}
            mb={4}
            py={4}
            px={2}
            sx={{ backgroundColor: '#919EAB14', borderRadius: 1, minHeight: 350 }}
          >
            {productDetails?.length <= 0 && (
              <Typography color="#637381" margin="auto" display="block">
                No products added{' '}
              </Typography>
            )}
            <Box
              columnGap={2}
              rowGap={5}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
            >
              {productDetails?.map((item, key) => (
                <ProductDetails
                  index={key}
                  productDetails={item}
                  handleRemoveProduct={handleRemoveProduct}
                  moveImage={moveImage}
                />
              ))}
            </Box>
          </Stack>
        </DndProvider>
      </AnimatePresence>
    </>
  );
}

ProductData.propTypes = {
  control: PropTypes.any,
  handleAddProduct: PropTypes.any,
  productDetails: PropTypes.any,
  isDisabled: PropTypes.any,
  handleRemoveProduct: PropTypes.any,
  addItems: PropTypes.any,
  setValue: PropTypes.any,
};
