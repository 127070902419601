import PropTypes from 'prop-types';

import { Box, Grid, Stack, Divider, TextField, Typography, Autocomplete } from '@mui/material';

import OspImageUploader from 'src/pages/Common/upload';

import { RHFTextField } from 'src/components/hook-form';

import { CountryCode } from './countryValues';
import MyGoogleMap from './Gmap/Gmap/googleMap';

function BasicDetails({
  imagePreview,
  setImagePreview,
  setCountryCode,
  countryCode,
  markers,
  setMarkers,
}) {
  const handleAutocompleteChangeCountry = (e, newValue) => {
    setCountryCode(newValue?.value || '');
  };

  const optionCountry = [
    ...(CountryCode || []).map((results) => ({
      label: `${results?.code} ${results?.name}`,
      // label: results?.code,
      value: results?.code,
    })),
  ];

  return (
    <Stack spacing={3} sx={{ p: 2, mt: 1 }}>
      <Grid pt={3} container maxWidth="xl" spacing={2}>
        <Grid item md={6} lg={6} sm={12}>
          <RHFTextField
            name="title.english"
            required
            label="Title"
            type="string"
            placeholder="Title"
            size="small"
          />
        </Grid>
        <Grid item md={6} lg={6} sm={12}>
          <RHFTextField
            name="title.arabic"
            label="Arabic Title"
            type="string"
            size="small"
            placeholder="Arabic Title"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid md={4} lg={4} sm={2}>
          <Stack className="country-code-input">
            <Autocomplete
              id="country-select-demo"
              sx={{ width: '100%' }}
              options={optionCountry}
              autoHighlight
              value={optionCountry.find((option) => option.value === countryCode) || null}
              size="small"
              getOptionLabel={(option) => option.label}
              onChange={handleAutocompleteChangeCountry}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  placeholder="Select Country with code"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid md={4} lg={4} xs={12} pl={2}>
          <RHFTextField
            name="phone"
            label="Phone"
            type="number"
            size="small"
            placeholder="enter phone"
          />
        </Grid>
        <Grid md={3} lg={3} xs={12} pl={2}>
          <RHFTextField
            name="priority"
            label="Priority"
            type="number"
            size="small"
            placeholder="enter Priority"
          />
        </Grid>
      </Grid>

      <Stack>
        <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
          Image
        </Typography>
        <Divider sx={{ pt: 2, mt: 0, mb: -2 }} />
        <Grid container>
          <Grid item>
            <Stack sx={{ pt: 3 }} spacing={0} width="100%" direction="column">
              <OspImageUploader setImagesPreview={setImagePreview} imagesPreview={imagePreview} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Box>
        <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
          Address & Location
        </Typography>
        <Divider sx={{ pt: 2, mt: 0, mb: -2 }} />
        {/* <Grid container spacing={2}>
          <Grid item md={6} lg={6} mt={4}>
            <RHFTextField
              rows={15}
              multiline
              name="address"
              label="Address"
              type="text"
              size="small"
              placeholder="Enter Address"
            />
          </Grid>
          <Grid item md={6} lg={6} mt={4}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: '' }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onClick={handleMap}
            >
              <Marker lat={59.955413} lng={30.337844} text="My Marker" />
            </GoogleMapReact>
          </Grid>
        </Grid> */}
        <Stack flexDirection="row" mt={3}>
          <Grid pt={3} width="50%">
            <RHFTextField
              rows={5}
              multiline
              name="address"
              label="Address"
              type="text"
              size="small"
              placeholder="Enter Address"
            />
          </Grid>
          <Grid width="100%" height="100%" ml={2} mt={2}>
            {' '}
            {/* Adjust the height of the map as needed */}
            <MyGoogleMap
              MAP_PUBLIC_KEY="AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8"
              markers={markers}
              setMarkers={setMarkers}
            />
          </Grid>
        </Stack>
      </Box>
    </Stack>
  );
}

BasicDetails.propTypes = {
  imagePreview: PropTypes.any,
  setImagePreview: PropTypes.func,
  setValue: PropTypes.func,
  ratingError: PropTypes.bool,
  setCountryCode: PropTypes.func,
  countryCode: PropTypes.string,
  markers: PropTypes.array,
  setMarkers: PropTypes.func,
};

export default BasicDetails;

const Marker = ({ text }) => (
  <Stack
    style={{
      color: 'white',
      background: 'red',
      padding: '10px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {text}
  </Stack>
);
Marker.propTypes = {
  text: PropTypes.string,
};
