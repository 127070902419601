import { Card } from '@mui/material';

import BannerIndex from './index';

export default function Banner() {
  return (
    <Card>
      <BannerIndex />
    </Card>
  );
}
