import { Link } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { InventoryWidgetSummary } from 'src/sections/inventory/inventory-widget-summary';

export default function SettingsForm() {
  const settingsItems = [
    {
      title: 'Financial Attributes',
      value: 20,
      path: paths.dashboard.settings.finance,
      icon: '/assets/icons/inventory/category.svg',
      color: 'success',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },
    {
      title: 'Banners',
      value: 6,
      path: paths.dashboard.settings.finance,
      color: 'secondary',
      icon: '/assets/icons/inventory/ic-courses-completed.svg',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },
    // {
    //   title: 'Collections',
    //   value: 6,
    //   path: paths.dashboard.inventory.collection,

    //   color: 'error',
    //   icon: '/assets/icons/inventory/collection.svg',
    //   subItems: [
    //     {
    //       title: '',
    //       value: '',
    //     },
    //   ],
    // },
    // {
    //   title: 'Product Variations',
    //   path: paths.dashboard.inventory.product_attributes,

    //   value: 6,
    //   icon: '/assets/icons/inventory/ic-courses-progress.svg',
    //   color: 'warning',
    //   subItems: [
    //     {
    //       title: '',
    //       value: '',
    //     },
    //   ],
    // },
    // {
    //   title: 'Product Labels',
    //   path: paths.dashboard.inventory.product_attributes,

    //   value: 6,
    //   icon: '/assets/icons/inventory/label.svg',
    //   color: 'success',
    //   subItems: [
    //     {
    //       title: '',
    //       value: '',
    //     },
    //   ],
    // },
    // {
    //   title: 'Product Groups',
    //   path: paths.dashboard.inventory.product_group,

    //   value: 6,
    //   icon: '/assets/icons/inventory/group.svg',
    //   color: 'secondary',

    //   subItems: [
    //     {
    //       title: '',
    //       value: '',
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <MetaHelmet title="Product" />
      <Grid container spacing={2}>
        <Grid xs={12} md={6} lg={8} item>
          <Box
            sx={{
              gap: 2,
              mb: 3,
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            }}
          >
            {settingsItems?.map((item, key) => (
              <Link to={item?.path} style={{ textDecoration: 'none' }}>
                <InventoryWidgetSummary
                  key={key}
                  title={item?.title}
                  total={item.value}
                  icon={item.icon}
                  color={item?.color}
                />
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
