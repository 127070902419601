import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import { getAllBrands } from 'src/server/api/brand';
import { getAllProduct } from 'src/server/api/product';
import { getAllCategory } from 'src/server/api/category';
import { getAllCollection } from 'src/server/api/collections';
import { getAllSpecialSale } from 'src/server/api/specialSale';

import SelectedDetails from './dataDetails';

// ----------------------------------------------------------------------

export default function GroupProductData({
  control,
  handleAdd,
  selectedValue,
  isDisabled,
  handleRemoveProduct,
  watch,
  searchText,
  isUpdate,
}) {
  const dispatch = useDispatch();

  const { products, allCategory, allBrand, allCollection, allSpecialSale } = useSelector(
    (state) => ({
      products: state.product.products?.products,
      allCategory: state.category?.categories,
      allBrand: state.brand?.brands,
      allCollection: state.collection?.collections,
      allProductGroup: state.productGroup?.productGroups,
      allSpecialSale: state?.specialSale?.specialSales,
      allBanners: state?.bannerData?.banners,
    })
  );

  const setType = watch('type');
  console.log(setType);
  console.log(allSpecialSale);

  // product

  useEffect(() => {
    if (['PRODUCTS', 'SINGLE_PRODUCT'].includes(setType)) {
      const filterSortObj = {
        filter_data: '',
        sort_data: '',
      };
      const credentials = {
        page: 1,
        search: searchText || '',
        dispatch,
        limit: 20,
        state: filterSortObj,
      };
      dispatch(getAllProduct(credentials));
    }
  }, [dispatch, searchText, setType]);

  // category

  useEffect(() => {
    if (['CATEGORIES', 'SINGLE_CATEGORY'].includes(setType)) {
      const filterDataObj = {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
      };
      const credentials = {
        page: 1,
        search: searchText,
        dispatch,
        state: filterDataObj,
      };
      dispatch(getAllCategory(credentials));
    }
  }, [dispatch, searchText, setType]);

  // brand

  useEffect(() => {
    if (['BRANDS', 'SINGLE_BRAND'].includes(setType)) {
      const filterDataObj = {
        filter_data: {
          is_deleted: false,
        },
      };
      const credentials = {
        page: 1,
        search: searchText,
        dispatch,
        state: filterDataObj,
      };
      dispatch(getAllBrands(credentials));
    }
  }, [dispatch, searchText, setType]);

  // collections

  useEffect(() => {
    if (['COLLECTIONS', 'SINGLE_COLLECTION'].includes(setType)) {
      const filterDataObj = {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
      };
      const credentials = {
        page: 1,
        search: searchText,
        dispatch,
        state: filterDataObj,
      };
      dispatch(getAllCollection(credentials));
    }
  }, [dispatch, searchText, setType]);

  // do not remove

  // // product group
  // const [searchValueProductGroup, setSearchValueProductGroup] = useState('');
  // const mainProductGroupOptions = [
  //   ...(allProductGroup?.productGroups || []).map((results) => ({
  //     label: results?.product?.name?.english,
  //     value: results?.uid,
  //   })),
  // ];

  // useEffect(() => {
  //   if (['PRODUCT_GROUPS', 'SINGLE_PRODUCT_GROUP'].includes(setType)) {
  //     const filterDataObj = {
  //       filter_data: {
  //         is_deleted: false,
  //         is_active: true,
  //       },
  //     };
  //     const credentials = {
  //       page: 1,
  //       search: searchValueProductGroup,
  //       dispatch,
  //       state: filterDataObj,
  //     };
  //     dispatch(getAllProductGroup(credentials));
  //   }
  // }, [dispatch, searchValueProductGroup, setType]);

  // const handleTextFieldChangeProductGroup = (value) => {
  //   setSearchValueProductGroup(value);
  // };

  // special sale
  useEffect(() => {
    if (['SPECIAL_SALES', 'SINGLE_SPECIAL_SALE'].includes(setType)) {
      const filterDataObj = {
        filter_data: {
          is_deleted: false,
        },
      };
      const credentials = {
        page: 1,
        search: searchText,
        limit: 10,
        dispatch,
        state: filterDataObj,
      };
      dispatch(getAllSpecialSale(credentials));
    }
  }, [dispatch, searchText, setType]);

  // banner

  // useEffect(() => {
  //   if (['BANNERS', 'SINGLE_BANNER'].includes(setType)) {
  //     const filterDataObj = {
  //       filter_data: {
  //         is_deleted: false,
  //         is_active: true,
  //       },
  //     };
  //     const credentials = {
  //       page: 1,
  //       search: searchText,
  //       dispatch,
  //       state: filterDataObj,
  //     };
  //     dispatch(getAllBanner(credentials));
  //   }
  // }, [dispatch, searchText, setType]);

  return (
    <Stack direction="row" px={2} spacing={2}>
      {setType === 'PRODUCTS' || setType === 'SINGLE_PRODUCT' ? (
        <Stack sx={{ backgroundColor: '#919EAB14', borderRadius: 1, minHeight: 350 }}>
          {products?.length === 0 ? (
            <Typography color="#637381" margin="auto" display="block">
              Added products will be shown here
            </Typography>
          ) : (
            <SelectedDetails
              selectedValue={selectedValue}
              handleAdd={handleAdd}
              products={products}
              setType={setType}
            />
          )}
        </Stack>
      ) : null}

      {[
        { multiple: 'CATEGORIES', single: 'SINGLE_CATEGORY', data: allCategory?.categories },
        { multiple: 'BRANDS', single: 'SINGLE_BRAND', data: allBrand?.brands },
        { multiple: 'COLLECTIONS', single: 'SINGLE_COLLECTION', data: allCollection?.collections },
        {
          multiple: 'SPECIAL_SALES',
          single: 'SINGLE_SPECIAL_SALE',
          data: allSpecialSale?.specialSales,
        },
        // { multiple: 'BANNERS', single: 'SINGLE_BANNER', data: allBanners?.banners },
      ].map(
        ({ multiple, single, data }) =>
          (setType === multiple || setType === single) && (
            <SelectedDetails
              key={multiple}
              selectedValue={selectedValue}
              handleAdd={handleAdd}
              products={data}
              setType={setType}
            />
          )
      )}
    </Stack>
  );
}

GroupProductData.propTypes = {
  control: PropTypes.any,
  isUpdate: PropTypes.bool,
  handleAdd: PropTypes.func,
  selectedValue: PropTypes.any,
  isDisabled: PropTypes.any,
  handleRemoveProduct: PropTypes.any,
  watch: PropTypes.any,
  searchText: PropTypes.any,
};
