import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createBanner = createAsyncThunk(
  'banner/create',
  async ({ state, dispatch, handleClose, handleClear, filterDataObj }) => {
    try {
      const URL = `banner/admin/new`;
      const response = await post(URL, state);
      console.log(response);

      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }

        dispatch(
          getBannerModule({
            page: 1,
            search: '',
            dispatch,
            limit: 20,
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Banner created successfully' }));
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;

      // dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllBanner = createAsyncThunk(
  'banner/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/banner/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`,
        state
      );
      console.log(response);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getBannerCounts = createAsyncThunk('banner/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/banner/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getBannerModule = createAsyncThunk(
  'banner/admin',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      // const URL = `/banner/admin/all`;
      const response = await post(
        `/banner/admin/module/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }`,
        state
      );

      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBannerDetails = createAsyncThunk(
  'banner/single',
  async ({ bannerId, dispatch }) => {
    try {
      const response = await get(`/banner/admin/${bannerId}`);
      if (response?.isSuccess) {
        return response;
      }
      // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateBannerDetails = createAsyncThunk(
  'banner/update',
  async ({
    state,
    bannerId,
    dispatch,
    handleClose,
    handleClear,
    handleMenuClose,
    filterDataObj,
  }) => {
    try {
      const URL = `/banner/admin/${bannerId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (handleMenuClose) {
          handleMenuClose();
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getBannerModule({
            page: 1,
            search: '',
            dispatch,
            limit: 20,
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Banner updated Successfully' }));
        return response;
        // dispatch(activeSnack({ type: 'success', message: 'Banner updated Successfully' }));
        // return response;0
      }
      // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteBanner = createAsyncThunk(
  'banner/delete',
  async ({ bannerId, dispatch, handleClose }) => {
    try {
      const response = await del(`/banner/admin/hard-delete/${bannerId}`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Banner deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const archiveBanner = createAsyncThunk('banner/archive', async ({ bannerId, dispatch }) => {
  try {
    const response = await post(`/banner/admin/delete/${bannerId}`);
    if (response?.isSuccess) {
      dispatch(activeSnack({ type: 'success', message: 'Banner archive' }));
      return response?.uid;
    }
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const restoreBanner = createAsyncThunk(
  'banner/unarchive',
  async ({ bannerId, dispatch }) => {
    try {
      const response = await post(`/banner/admin/restore/${bannerId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Banner archive' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
