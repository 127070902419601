import { createSlice } from '@reduxjs/toolkit';

import { createSeoItem, getSeoItemModule, updateSeoItemModule } from '../api/seoData';

export const SEOSlice = createSlice({
  name: 'SEOData',
  initialState: {
    loading: false,
    error: {},
    seoData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create seo-item details /seo-item/admin/new
    [createSeoItem.pending]: (state) => {
      state.loading = true;
    },
    [createSeoItem.fulfilled]: (state, action) => {
      state.loading = false;
      state.seoData = action.payload;
      state.error = {};
    },
    [createSeoItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get seo item
    [getSeoItemModule.pending]: (state) => {
      state.loading = true;
    },
    [getSeoItemModule.fulfilled]: (state, action) => {
      state.loading = false;
      state.seoData = action.payload;
      state.error = {};
    },
    [getSeoItemModule.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update-seo item
    [updateSeoItemModule.pending]: (state) => {
      state.loading = true;
    },
    [updateSeoItemModule.fulfilled]: (state, action) => {
      state.loading = false;
      state.seoData = action.payload;
      state.error = {};
    },
    [updateSeoItemModule.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = SEOSlice.actions;

export default SEOSlice.reducer;
