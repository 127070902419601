import { useState, useEffect } from 'react';

// Import the user icon

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Tab, Tabs, alpha } from '@mui/material';

import SEOPage from 'src/pages/Common/Others/SEOPage';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getSeoItemModule } from 'src/server/api/seoData';
import BannerList from 'src/pages/Admin/Banners/sections/bannerList';

import BasicDetailsForm from '../BasicDetails';

// ----------------------------------------------------------------------

export default function BrandCreatePage() {
  const params = useParams();
  const dispatch = useDispatch();

  const { seoData } = useSelector((state) => ({
    seoData: state.seoData.seoData,
  }));
  // console.log(seoData);

  const brandId = params.id;

  const STATUS_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'banners', label: 'Banners' },
    { value: 'seo', label: 'SEO' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  useEffect(() => {
    const filterDataObj = {
      module: 'BRAND',
      module_id: brandId,
    };

    const credentials = {
      search: '',
      dispatch,
      state: filterDataObj,
    };

    if (brandId) {
      dispatch(getSeoItemModule(credentials));
    }
  }, [brandId, dispatch]);

  const currentSeoData = seoData;

  return (
    <>
      <MetaHelmet title="Brand Crud" />

      <Grid container spacing={2}>
        <Grid xs={12} md={10} xl={8}>
          <Card>
            {brandId && (
              <Tabs
                value={tabChange.value}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab, key) => (
                  <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            )}

            {tabChange.value === 'basicDetails' && <BasicDetailsForm />}
            {tabChange.value === 'banners' && <BannerList moduleName="BRAND" moduleId={brandId} />}
            {tabChange.value === 'seo' && (
              <SEOPage
                moduleName="BRAND"
                moduleId={brandId} // category uid
                updateData={currentSeoData} // current seo data
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

BrandCreatePage.propTypes = {};
