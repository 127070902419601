import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createCollection = createAsyncThunk(
  'collection/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/collection/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router.push(paths.dashboard.inventory.collection);
        }
        dispatch(activeSnack({ type: 'success', message: 'Collection created successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCollection = createAsyncThunk(
  'collection/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `collection/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCollectionCounts = createAsyncThunk(
  'collection/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/collection/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeCollectionStatus = createAsyncThunk(
  'collection/status',
  async ({ state, collectionId, dispatch,tab }) => {
    try {
      const URL = `/collection/admin/status/active/${collectionId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkCollectionTitleValid = createAsyncThunk(
  'collection/check/collection-title',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/collection/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCollectionDetails = createAsyncThunk(
  'collection/single',
  async ({ collectionId, dispatch }) => {
    try {
      const response = await get(`/collection/admin/single/${collectionId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCollectionDetails = createAsyncThunk(
  'collection/update',
  async ({ state, collectionId, dispatch, isImageRemove }) => {
    try {
      const URL = `/collection/admin/${collectionId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (isImageRemove) {
          dispatch(getCollectionDetails({ collectionId: response?.uid, dispatch }));
        }
        dispatch(activeSnack({ type: 'success', message: 'Collection updated Successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCollection = createAsyncThunk(
  'collection/delete',
  async ({ collectionId, dispatch, confirm }) => {
    try {
      const response = await post(`/collection/admin/delete/${collectionId}/`);
      if (response?.isSuccess) {
        if (confirm) {
          confirm.onFalse();
        }
        dispatch(activeSnack({ type: 'success', message: 'Collection deleted successfully' }));
        return response?.uid;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreCollection = createAsyncThunk(
  'collection/unarchive',
  async ({ collectionId, dispatch, handleClose }) => {
    try {
      const response = await post(`/collection/admin/restore/${collectionId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Collection archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const hardDeleteCollection = createAsyncThunk(
  'collection/hard/delete',
  async ({ collectionId, dispatch, handleClose }) => {
    try {
      const response = await del(`/collection/admin/hard-delete/${collectionId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Collection deleted successfully' }));
        return response?.uid;
      }

      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
