import { createSlice } from '@reduxjs/toolkit';

import {
  logoutApi,
  adminLoginApi,
  getUserProfile,
  forgotPassword,
  updateUserProfile,
} from '../api/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    passwordLoading: false,
    forgottLoading: false,
    loading: false,
    loginLoading: false,
    error: {},
    user: {
      email: '',
      username: '',
      isActive: false,
    },
  },
  reducers: {},
  extraReducers: {
    /// admin login
    [adminLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [adminLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [adminLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // profile
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update profile
    [updateUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // logout
    [logoutApi.fulfilled]: (state) => {
      state.user = {};
      state.error = {};
    },
    [logoutApi.rejected]: (state, action) => {
      state.error = action.error;
    },

    [forgotPassword.pending]: (state) => {
      state.forgottLoading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.forgottLoading = false;
      state.error = {};
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgottLoading = false;
      state.error = action.error;
    },
  },
});
// export const {} = authSlice.actions;

export default authSlice.reducer;
