import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createBrand = createAsyncThunk('brand/create', async ({ state, dispatch, router }) => {
  try {
    const URL = `/brand/admin/new`;
    const response = await post(URL, state);
    if (response?.isSuccess) {
      router.push(paths.dashboard.inventory.brand);
      dispatch(activeSnack({ type: 'success', message: 'Brand created' }));
      return response;
    }

    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateBrandDetails = createAsyncThunk(
  'brand/update',
  async ({ state, brandId, dispatch }) => {
    try {
      const URL = `/brand/admin/${brandId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Brand updated' }));
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllBrands = createAsyncThunk(
  'brand/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/brand/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getBrandCounts = createAsyncThunk('brand/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/brand/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getAllMainBrand = createAsyncThunk(
  'main/brand/list',
  async ({ search, dispatch, state }) => {
    try {
      const response = await post(
        `/brand/admin/all?page=${1}&search=${search && search}&limit=${10}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getBrandDetails = createAsyncThunk('brand/single', async ({ brandId, dispatch }) => {
  try {
    const response = await get(`/brand/admin/single/${brandId}`);
    if (response?.isSuccess) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const changeBrandStatus = createAsyncThunk(
  'brand/status',
  async ({ state, brandId, dispatch,tab }) => {
    try {
      const URL = `/brand/admin/status/active/${brandId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const checkBrandNameValid = createAsyncThunk(
  'brand/check/brand-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/brand/admin/english-name/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteBrand = createAsyncThunk(
  'brand/delete',
  async ({ brandId, dispatch, handleClose }) => {
    try {
      const response = await del(`/brand/admin/hard-delete/${brandId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Brand deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const archiveBrand = createAsyncThunk('brand/archive', async ({ brandId, dispatch }) => {
  try {
    const response = await post(`/brand/admin/delete/${brandId}`);
    if (response?.isSuccess) {
      dispatch(activeSnack({ type: 'success', message: 'Brand archived' }));
      return response?.uid;
    }
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const restoreBrand = createAsyncThunk('brand/unarchive', async ({ brandId, dispatch }) => {
  try {
    const response = await post(`/brand/admin/restore/${brandId}`);
    if (response?.isSuccess) {
      dispatch(activeSnack({ type: 'success', message: 'Brand archived' }));
      return response?.uid;
    }
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
