import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Stack, Button, Divider, Typography } from '@mui/material';

import { RHFTextField } from 'src/components/hook-form';

import VideoPreviewYoutube from './VideoPreviewYutube';

function Appearance({ watch }) {
  const [videoPreviewPopup, setVideoPreviewPopup] = useState({
    data: {},
    status: false,
  });

  return (
    <Stack spacing={3} sx={{ p: 2 }} direction="column">
      <Stack spacing={2} width="100%" direction="column">
        <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
          Product Video (Youtube)
          <Divider sx={{ mt: 1 }} />
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '60%' }}
        >
          <RHFTextField
            name="video_id"
            label="Video ID"
            type="string"
            placeholder="eg: 38395525"
            size="small"
            sx={{ width: '100%' }}
          />
          <Button
            sx={{ width: '150px', mx: 1 }}
            variant="contained"
            color="inherit"
            disabled={!watch('video_id')}
            onClick={() =>
              setVideoPreviewPopup({
                status: true,
                data: watch('video_id'),
              })
            }
          >
            Show Preview
          </Button>
        </Stack>
      </Stack>

      <VideoPreviewYoutube
        setOpen={setVideoPreviewPopup}
        open={videoPreviewPopup.status}
        data={videoPreviewPopup.data}
      />
    </Stack>
  );
}

export default Appearance;

Appearance.propTypes = {
  watch: PropTypes.any,
};
