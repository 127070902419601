import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Stack, Divider, Typography } from '@mui/material';

export default function BrandFilterDrawer({ open, setOpen }) {
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250, p: 2 }} role="presentation" onClick={toggleDrawer(false)}>
      <Typography variant="subtitle2">Brand Filters</Typography>
      <Divider sx={{ mt: 0.5, mb: 2 }} />
      <Stack>
        <Typography variant="caption">Filter Options here </Typography>
      </Stack>
    </Box>
  );
  // console.log(open);

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
BrandFilterDrawer.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.func,
};
