import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Grid, Card, Stack, Divider, Typography } from '@mui/material';

import { getAllTaxCategory } from 'src/server/api/taxCategory';

import { RHFTextField } from 'src/components/hook-form';
import OspSelectAutocomplete from 'src/components/hook-form/osp-select';

function PriceDetails({ control, watch }) {
  const dispatch = useDispatch();

  const { taxCategories, productDetails } = useSelector((state) => ({
    taxCategories: state.taxCategory.taxCategories,
    loading: state.taxCategory.loading,
    productDetails: state.product.productDetails,

  }));

  // tax category start
  const [searchValueTaxCategory, setSearchValueTaxCategory] = useState('');

  const handleTextFieldChangeTaxCategory = (value) => {
    setSearchValueTaxCategory(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: { is_deleted: false },
    };
    const credentials = {
      page: 1,
      search: searchValueTaxCategory,
      dispatch,
      limit: 10,
      state: filterDataObj,
    };

    dispatch(getAllTaxCategory(credentials));
  }, [dispatch, searchValueTaxCategory]);

  const taxCategoryOptions = [
    ...(taxCategories?.taxCategories || []).map((results) => ({
      label: `${results?.title?.english} (${results?.percentage}%)`,
      value: results?.uid,
      percentage: results?.percentage,
    })),
  ];
console.log(productDetails)
  const defaultTaxCategory = useMemo(
    () =>
      productDetails?.tax_categories?.map((item) => ({
        label: item?.title?.english || '',
        value: item?.uid || '',
        percentage: item?.percentage || '',
      })) || [],
    [productDetails]
  );
  // tax category end

  // calculate tax
  const currentPrice = watch('offer_price_without_tax')
    ? watch('offer_price_without_tax')
    : watch('price_without_tax'); // todo add tax

  const tax_categories = watch('tax_categories');

  const handleCalculateTax = (percentage) => {
    const calculatedPercentagePrice = currentPrice * (percentage / 100);
    return calculatedPercentagePrice?.toFixed(3);
  };

  const [totalPrice, setTotalPrice] = useState(currentPrice);

  const calculateTheTotalPrice = useCallback(() => {
    let additionalPrice = 0;

    tax_categories?.forEach((item) => {
      const percentageValue = (item.percentage / 100) * currentPrice;
      additionalPrice += percentageValue;
    });

    const calculatedTotalPrice = currentPrice + additionalPrice;
    return calculatedTotalPrice?.toFixed(3);
  }, [tax_categories, currentPrice]);

  useEffect(() => {
    const newTotalPrice = calculateTheTotalPrice();
    setTotalPrice(newTotalPrice);
  }, [tax_categories, currentPrice, calculateTheTotalPrice]);

  console.log(defaultTaxCategory);

  return (
    <Stack spacing={3} sx={{ p: 2, mt: 2 }}>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          Price
        </Typography>
        <Divider />
      </Stack>
      <Grid container maxWidth="xl" spacing={2}>
        <Grid xs={12} md={8} item>
          <Stack spacing={2.5}>
            <OspSelectAutocomplete
              name="tax_categories"
              multiple
              control={control}
              defaultValue={defaultTaxCategory || []}
              options={taxCategoryOptions}
              label="Tax Categories"
              handleTextFieldChange={handleTextFieldChangeTaxCategory}
            />
            <Grid container maxWidth="xl" spacing={2.5}>
              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="cost"
                  label="Cost"
                  type="number"
                  placeholder="Cost"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="price_without_tax"
                  label="Price without Tax"
                  type="number"
                  placeholder="Price without Tax"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="offer_price_without_tax"
                  label="Offer Price without Tax"
                  type="number"
                  placeholder="Offer Price without Tax"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item />

              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="offer_start_date"
                  label="Offer Start Date"
                  type="date"
                  placeholder="Offer Start Date"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="offer_end_date"
                  label="Offer End Date"
                  type="date"
                  placeholder="Offer End Date"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12} md={4} item>
          <Card sx={{ p: 2, pb: 0, minHeight: '220px' }}>
            <Typography variant="subtitle2">Summary</Typography>
            <Stack direction="row" justifyContent="space-between" my={2} width="100%">
              <Typography variant="caption" color="GrayText">
                Product Price
              </Typography>
              <Typography variant="caption">OMR {watch('price_without_tax')}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" my={2} width="100%">
              <Typography variant="caption" color="green">
                Offer Price
              </Typography>
              <Typography variant="caption" color="green">
                OMR {watch('offer_price_without_tax')}
              </Typography>
            </Stack>
            {/* // todo list all selected categories */}
            {tax_categories?.map((item, key) => (
              <Stack key={key} direction="row" justifyContent="space-between" my={2} width="100%">
                <Typography variant="caption" color="GrayText">
                  {item?.label}
                </Typography>
                <Typography variant="caption">
                  OMR {handleCalculateTax(item?.percentage)}
                </Typography>
              </Stack>
            ))}

            <Stack direction="row" justifyContent="space-between" my={2} width="100%">
              <Typography variant="caption" sx={{ fontWeight: '600' }}>
                Listing Price (Total)
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: '600' }}>
                OMR {totalPrice}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ mt: 2, mb: 1, p: 2, flexGrow: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Stock
            </Typography>
            <Divider />
          </Stack>

          <Grid container spacing={2} maxWidth="xl" pl={2}>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="stock"
                label="Stock"
                type="number"
                placeholder="Stock"
                size="small"
              />
            </Grid>

            <Grid xs={12} md={4} item>
              <RHFTextField
                name="min_order_quantity"
                label="Min Order quantity"
                type="number"
                placeholder="Min Order quantity"
                size="small"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="max_order_quantity"
                label="Max Order quantity"
                type="number"
                placeholder="Max Order quantity"
                size="small"
              />
            </Grid>
          </Grid>
        </Stack>

        {/* <Grid container spacing={2} maxWidth="xl" pl={2}>
          <Grid xs={12} md={4} item>
            <RHFTextField
              name="offer_price"
              label="Offer Price"
              type="number"
              placeholder="Offer Price" // calculate
              size="small"
            />
          </Grid>
        </Grid> */}
        {/* <Grid xs={12} md={6} item> //calculate price
                <RHFTextField
                  name="price"
                  label="Price"
                  type="number"
                  placeholder="Price"
                  size="small"
                />
              </Grid> */}
      </Grid>
    </Stack>
  );
}

export default PriceDetails;

PriceDetails.propTypes = {
  control: PropTypes.any,
  watch: PropTypes.any,
};
