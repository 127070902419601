import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Stack, Rating, Divider, CardMedia, Typography } from '@mui/material';

import { getAllProduct } from 'src/server/api/product';
import OspImageUploader from 'src/pages/Common/upload';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import OspSelectAutocomplete from 'src/components/hook-form/osp-select';

function BasicDetails({
  watch,
  control,
  selectedValue,
  handleRemoveProduct,
  isUpdate,
  setValue,
  rating,
  setRating,
  imagePreview,
  setImagePreview,
  ratingError,
}) {
  const dispatch = useDispatch();

  const { allProducts } = useSelector((state) => ({
    allProducts: state.product.products,
  }));

  const [searchText, setSearchText] = useState();

  useEffect(() => {
    const filterSortObj = {
      filter_data: { is_deleted: false },
      sort_data: '',
    };
    const credentials = {
      page: 1,
      search: searchText,
      dispatch,
      limit: 10,
      state: filterSortObj,
    };
    dispatch(getAllProduct(credentials));
  }, [dispatch, searchText]);

  const optionProduct = [
    ...(allProducts?.products || []).map((results) => ({
      // label: results?.name?.english,
      label: `${results?.sap_id || ''} - ${results?.name?.english || ''}`,
      images: results?.images,
      value: results?.uid,
    })),
  ];
  const handleTextFieldChangeProduct = (value) => {
    setSearchText(value);
  };
  const handleRating = (value) => {
    setRating((prevRating) => {
      const newRating = prevRating === value ? null : value;
      console.log('New Rating:', newRating); // Log to check state change
      return newRating;
    });
  };

  const selectedProduct = watch('product');
  console.log(rating);
  return (
    <Stack spacing={3} sx={{ p: 2, mt: 1 }}>
      {/* <Grid container pt={3} spacing={2}>
        <Grid xs={12} md={6} lg={6} item>
          <OspSelectAutocomplete
            name="user"
            control={control}
            options={optionProduct}
            label="User"
            placeholder="Search user"
            handleTextFieldChange={handleTextFieldChangeProduct}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} item>
          <OspSelectAutocomplete
            name="order"
            control={control}
            options={optionProduct}
            label="User"
            placeholder="Search order"
            handleTextFieldChange={handleTextFieldChangeProduct}
          />
        </Grid>
      </Grid> */}
      <Grid pt={3} container maxWidth="xl" spacing={2}>
        <Grid xs={6} md={6} item>
          <RHFTextField
            name="name"
            required
            label=" Name"
            type="string"
            placeholder=" Name"
            size="small"
          />
        </Grid>
        <Grid xs={6} md={6} item>
          <Stack>
            <RHFTextField
              name="phone"
              label="Phone"
              type="number"
              size="small"
              placeholder="enter phone"
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} md={6} lg={6} item spacing={2}>
          <OspSelectAutocomplete
            required
            name="product"
            control={control}
            options={optionProduct}
            label="Product"
            placeholder="Search a product"
            handleTextFieldChange={handleTextFieldChangeProduct}
          />
          <Stack pt={2}>
            <RHFTextField
              rows={5}
              multiline
              name="message"
              label="Message"
              type="text"
              size="small"
              placeholder="Enter Message"
            />
          </Stack>
          <Grid pt={2}>
            <Rating
              required
              name="rating"
              size="medium"
              value={rating}
              onChange={(event, newValue) => handleRating(newValue)}
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          {selectedProduct?.value ? (
            <Stack flexDirection="row" alignItems="center" justifyContent="center" ml={2}>
              <CardMedia
                sx={{
                  height: 250,
                  width: 250,
                  objectFit: 'contain',
                }}
                component="img"
                src={selectedProduct?.images?.length > 0 && selectedProduct?.images[0]?.url}
                alt="prodcut image"
              />
            </Stack>
          ) : (
            <Stack flexDirection="row" alignItems="center" justifyContent="center">
              <Box
                mt={0}
                sx={{
                  height: 250,
                  width: 410,
                  backgroundColor: '#919EAB14',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Stack alignItems="center" justifyContent="center" textAlign="center">
                  <Iconify icon="humbleicons:image" />
                  <Typography variant="caption">product Preview</Typography>
                </Stack>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>

      <Stack>
        <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
          Image
        </Typography>
        <Divider sx={{ pt: 2, mt: 0, mb: -2 }} />
        <Grid container>
          <Grid item>
            <Stack sx={{ pt: 3 }} spacing={0} width="100%" direction="column">
              <OspImageUploader setImagesPreview={setImagePreview} imagesPreview={imagePreview} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

BasicDetails.propTypes = {
  watch: PropTypes.func,
  isUpdate: PropTypes.bool,
  control: PropTypes.any,
  selectedValue: PropTypes.any,
  handleRemoveProduct: PropTypes.func,
  rating: PropTypes.any,
  setRating: PropTypes.func,
  imagePreview: PropTypes.any,
  setImagePreview: PropTypes.func,
  setValue: PropTypes.func,
  ratingError: PropTypes.bool,
};

export default BasicDetails;
