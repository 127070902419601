import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
// @mui
import {
  Tab,
  Card,
  Tabs,
  Chip,
  Stack,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { getBannerCounts, getBannerModule } from 'src/server/api/banner';

import Label from 'src/components/label/label';
// components
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import BannerTableRow from './bannerTableRow';
import CreateBannerDialog from './crud-modal';
// sections

export default function BannerList({ moduleId, moduleName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { banners, loading, bannerCount } = useSelector((state) => ({
    banners: state.bannerData.banners,
    loading: state.bannerData.loading,
    bannerCount: state.bannerData.bannerCounts,
  }));
  // console.log(bannerCount);
  // console.log(banners);
  const [openCreateBannerDialog, setOpenCreateBannerDialog] = useState(false);
  const [openBannerId, setOpenBannerId] = useState({});

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  const [filterData, setFilterData] = useState({
    is_deleted: false,
  });
  const limit = 20;
  const total = banners?.total;

  const TABLE_HEAD = [
    { id: 'bannerType', label: 'Type' },
    { id: 'bannerPreview', label: 'Preview', align: 'left' },
    { id: 'position', label: 'Position' },
    { id: 'platform', label: 'Platform' },
    { id: 'actions', label: 'Actions' },
  ];

  useEffect(() => {
    setTableData(banners?.banners);
  }, [banners]);
  const innerFilterDataObj = useMemo(
    () => ({
      module: moduleName || 'HOME',
      module_id: moduleId,
      filter_data: filterData,
      sort_data: { priority: 1 },
    }),
    [moduleName, moduleId, filterData]
  );
  console.log(innerFilterDataObj)
  useEffect(() => {
    // const filterDataObj = {
    //   module: moduleName || 'HOME',
    //   module_id: moduleId,
    //   filter_data: filterData,
    //   sort_data: { priority: 1 },
    // };
    const credentials = {
      page: table.page + 1,
      search: '',
      dispatch,
      limit: table.rowsPerPage || limit,
      state: innerFilterDataObj,
    };
    // if (!moduleId) {
    //   dispatch(getAllBanner(credentials));
    // } else {
    dispatch(getBannerModule(credentials));
    // }
  }, [
    dispatch,
    filterData,
    innerFilterDataObj,
    moduleId,
    moduleName,
    table.page,
    table.rowsPerPage,
  ]);

  const handleViewRow = (id) => {
    navigate(paths.dashboard.rental_view(id));
  };

  // const handleCreateClick = () => {
  //   router.push({
  //     pathname: paths.dashboard.inventory.banners_create,
  //     query: {},
  //   });
  // };
  // const onEditRow = (row) => {
  //   router.push({
  //     pathname: paths.dashboard.inventory.category_update(row?.uid),
  //     query: {},
  //   });
  // };
  useEffect(() => {
    const filterDataObj = {
      count_list: [
        {
          title: 'all_banners_count',
          filter_data: {
            is_deleted: false,
            module: moduleName || 'HOME',
            module_id: moduleId,
          },
        },
        {
          title: 'all_common_platform_count',
          filter_data: {
            platform: 'COMMON',
            is_deleted: false,
            module: moduleName || 'HOME',
            module_id: moduleId,
          },
        },
        {
          title: 'all_app_platform_count',
          filter_data: {
            platform: 'APP',
            is_deleted: false,
            module: moduleName || 'HOME',
            module_id: moduleId,
          },
        },
        {
          title: 'all_web_platform_count',
          filter_data: {
            platform: 'WEB',
            is_deleted: false,
            module: moduleName || 'HOME',
            module_id: moduleId,
          },
        },
        {
          title: 'all_deleted_banner_count',
          filter_data: {
            is_deleted: true,
            module: moduleName || 'HOME',
            module_id: moduleId,
          },
        },
      ],
    };
    const credentials = {
      dispatch,
      state: filterDataObj,
    };
    dispatch(getBannerCounts(credentials));
  }, [dispatch, moduleId, moduleName, banners]);
  const TABS = [
    { value: 'all', label: 'All', color: 'default', count: bannerCount?.all_banners_count },
    {
      value: 'common',
      label: 'Common',
      color: 'info',
      count: bannerCount?.all_common_platform_count,
    },
    {
      value: 'mobile',
      label: 'Mobile',
      color: 'success',
      count: bannerCount?.all_app_platform_count,
    },
    {
      value: 'web',
      label: 'Web',
      color: 'default',
      count: bannerCount?.all_web_platform_count,
    },
    {
      value: 'is_deleted',
      label: 'Archived',
      color: 'default',
      count: bannerCount?.all_deleted_banner_count,
    },
  ];

  const [tab, setTab] = useState('all');

  // const handleFilterStatus = (e, value) => {
  //   setTab(value);
  // };
  const handleFilterStatus = (e, value) => {
    setTab(value);
    switch (value) {
      case 'all':
        setFilterData({
          is_deleted: false,
        });
        break;

      case 'common':
        setFilterData({
          is_deleted: false,
          platform: 'COMMON',
        });
        break;

      case 'mobile':
        setFilterData({
          is_deleted: false,
          platform: 'APP',
        });
        break;

      case 'web':
        setFilterData({
          is_deleted: false,
          platform: 'WEB',
        });
        break;

      // case 'main_categories':
      //   setFilterData({
      //     is_deleted: false,
      //     is_sub: false,
      //   });
      //   break;

      // case 'sub_categories':
      //   setFilterData({
      //     is_deleted: false,
      //     is_sub: true,
      //   });
      //   break;
      case 'is_deleted':
        setFilterData({
          is_deleted: true,
        });
        break;
      default:
        setFilterData({
          is_deleted: true,
        });
        break;
    }
    table.setPage(0);
    navigate(
      {
        pathname: location.pathname,
        search: `?p=${0}&L=${table.rowsPerPage}`,
      },
      {
        state: {
          pageValue: 0,
          limit: table.rowsPerPage,
        },
      }
    );
  };
  // console.log(tableData);
  const onEditRow = (row) => {
    if (row) {
      // console.log(row);
      setOpenBannerId(row);
    }
    setOpenCreateBannerDialog(true);
  };
  // const onDeleteRow = (id) => () => {
  //   const credentials = {
  //     dispatch,
  //     bannerId: id,
  //   };
  //   dispatch(deleteBanner(credentials));
  // };
  return (
    <>
      {openCreateBannerDialog && (
        <CreateBannerDialog
          isUpdate={openBannerId}
          moduleName={moduleName}
          moduleId={moduleId}
          setOpenBannerId={setOpenBannerId}
          setOpen={setOpenCreateBannerDialog}
          open={openCreateBannerDialog}
          filterDataObj={innerFilterDataObj}
        />
      )}
      {/* <Card> */}
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Stack px={3} p={2}>
          <CustomBreadcrumbs
            isMinimal
            action={
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className="product-tap-container"
                >
                  <Tabs
                    value={tab}
                    onChange={handleFilterStatus}
                    sx={{
                      px: 3,
                      backgroundColor: theme?.palette?.mode === 'dark' ? '' : '#F4F6F8',
                      borderRadius: '6px',
                      // boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                    }}
                  >
                    {TABS.map((tabItem) => (
                      <Tab
                        key={tabItem.value}
                        value={tabItem.value}
                        label={tabItem.label}
                        iconPosition="end"
                        icon={
                          <>
                            <Label
                              variant={(tabItem.value === tab && 'filled') || 'soft'}
                              color={tabItem.color}
                              sx={{ ml: 1 }}
                            >
                              {tabItem.count || 0}
                            </Label>

                            {tabItem.value === tab && (
                              <Chip
                                sx={{
                                  backgroundColor:
                                    theme?.palette?.mode === 'dark' ? 'grey' : '#fff',
                                  position: 'absolute',
                                  zIndex: -1,
                                  width: '100%',
                                  top: '8px',
                                  '&:hover': {
                                    backgroundColor:
                                      theme?.palette?.mode === 'dark' ? '#232324' : '#e1eaf1',
                                  },
                                }}
                              />
                            )}
                          </>
                        }
                        sx={{
                          position: 'relative',
                          zIndex: 1,
                          overflow: 'inherit',
                          width: 'auto',
                          padding: '0 15px',
                        }}
                      />
                    ))}
                  </Tabs>

                  <Stack direction="row" alignItems="center" justifyContent="center" display="flex">
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      sx={{
                        mx: 1,
                      }}
                      // onClick={handleCreateClick}
                      onClick={() => setOpenCreateBannerDialog(true)}
                    >
                      <AddRoundedIcon fontSize="small" />
                      Banner
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            }
            sx={{
              mb: { xs: 1, md: 1 },
            }}
          />
        </Stack>
        <Card sx={{ mx: 3, mb: 3 }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
                onSort={table.onSort}
              />

              <TableBody>
                {loading ? (
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <CircularProgress size="20px" color="inherit" />
                    </Paper>
                  </TableCell>
                ) : (
                  <>
                    {(tableData || []).map((row, index) => (
                      <BannerTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={table.rowsPerPage || 5}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.uid)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                        onEditRow={onEditRow}
                        tab={tab}
                        // onDeleteRow={onDeleteRow}
                      />
                    ))}
                  </>
                )}
              </TableBody>

              <TableNoData notFound={notFound} />
            </Table>
          </Scrollbar>
          <TablePaginationCustom
            count={total || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </TableContainer>
      {/* </Card> */}
    </>
  );
}
BannerList.propTypes = {
  moduleId: PropTypes.any,
  moduleName: PropTypes.any,
};
