import { debounce } from 'lodash';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
// @mui
import {
  Box,
  Tab,
  Card,
  Grid,
  Chip,
  Tabs,
  Stack,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { clearBrandDetailsState } from 'src/server/store/brand';
import {
  getAllBrands,
  getBrandCounts,
  getAllMainBrand,
  changeBrandStatus,
} from 'src/server/api/brand';

import Iconify from 'src/components/iconify';
import Label from 'src/components/label/label';
// components
import Scrollbar from 'src/components/scrollbar';
import { LoadingScreen } from 'src/components/loading-screen';
import RHFAutocompleteBasic from 'src/components/hook-form/rhf-autocomplete-filter';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { InventoryWidgetSummary } from 'src/sections/inventory/inventory-widget-summary';

import BrandTableRow from './BrandTableRow';
import BrandFilterDrawer from './modals/BrandFilters';
// sections

export default function BrandList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useRouter();
  const theme = useTheme();
  const location = useLocation();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { brands, loading, brandCount, mainBrands, statusLoading } = useSelector((state) => ({
    brands: state.brand.brands,
    loading: state.brand.loading,
    brandCount: state.brand.brandCounts,
    mainBrands: state.brand.mainBrands,
    statusLoading: state.brand.statusLoading,
  }));

  // console.log(brands);
  const [searchValueMainBrand, setSearchValueMainBrand] = useState('');

  const [searchText, setSearchText] = useState();
  const [finalSearchText, setFinalSearchText] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterData, setFilterData] = useState({
    is_deleted: false,
  });

  // console.log(finalSearchText);

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const limit = 20;
  const total = brands?.total;

  const TABLE_HEAD = [
    { id: 'name', label: 'Name' },
    { id: 'image', label: 'Logo' },
    { id: 'category', label: 'Main Brand' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
  ];

  useEffect(() => {
    setTableData(brands?.brands);
  }, [brands]);

  useEffect(() => {
    const filterDataObj = {
      filter_data: filterData,
      sort_data: { priority: 1 },
    };
    const credentials = {
      page: table.page + 1,
      search: finalSearchText,
      dispatch,
      limit: table.rowsPerPage || limit,
      state: filterDataObj,
    };

    dispatch(getAllBrands(credentials));
  }, [dispatch, filterData, finalSearchText, table.page, table.rowsPerPage]);
  useEffect(() => {
    const filterDataObj = {
      filter_data: { is_deleted: false },
      sort_data: { priority: 1 },
    };
    const credentials = {
      search: searchValueMainBrand,
      dispatch,
      state: filterDataObj,
    };

    dispatch(getAllMainBrand(credentials));
  }, [dispatch, filterData, searchValueMainBrand]);
  const handleViewRow = (id) => {
    dispatch(clearBrandDetailsState());

    navigate(paths.dashboard.inventory.brand_update(id));
  };

  const debouncedSetDebouncedText = debounce((value) => setFinalSearchText(value), 1000);

  const handleChangeSearchText = (e) => {
    debouncedSetDebouncedText(e.target.value);
    setSearchText(e.target.value);
    table.setPage(0);
    navigate(
      {
        pathname: location.pathname,
        search: `?p=${0}&L=${table.rowsPerPage}`,
      },
      {
        state: {
          pageValue: 0,
          limit: table.rowsPerPage,
        },
      }
    );
  };

  const handleCreateClick = () => {
    dispatch(clearBrandDetailsState());
    router.push({
      pathname: paths.dashboard.inventory.brand_crud,
      query: {},
    });
  };
  useEffect(() => {
    const filterDataObj = {
      count_list: [
        {
          title: 'all_brand_count',
          filter_data: {
            is_deleted: false,
          },
        },
        {
          title: 'all_active_brand_count',
          filter_data: {
            is_deleted: false,
            is_active: true,
          },
        },
        {
          title: 'all_inactive_brand_count',
          filter_data: {
            is_deleted: false,
            is_active: false,
          },
        },
        {
          title: 'all_featured_brand_count',
          filter_data: {
            is_deleted: false,
            is_featured: true,
          },
        },
        // {
        //   title: 'all_out_of_stock_category_count',
        //   filter_data: {
        //     is_deleted: false,
        //     stock: {
        //       $lte: 0,
        //     },
        //   },
        // },
        {
          title: 'all_deleted_brand_count',
          filter_data: {
            is_deleted: true,
          },
        },
        {
          title: 'all_main_brand_count',
          filter_data: {
            is_deleted: false,
            is_sub: false,
          },
        },
        {
          title: 'all_sub_brand_count',
          filter_data: {
            is_deleted: false,
            is_sub: true,
          },
        },
      ],
    };
    const credentials = {
      dispatch,
      state: filterDataObj,
    };
    dispatch(getBrandCounts(credentials));
  }, [dispatch, brands]);
  const inventoryItems = [
    {
      title: 'Brands',
      // path: paths.dashboard.inventory.product_variation,

      value: brandCount?.all_brand_count || 0,
      icon: '/assets/icons/inventory/ic-courses-progress.svg',
      color: 'warning',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },

    {
      title: 'Main Brands',
      value: brandCount?.all_main_brand_count || 0,
      // path: paths.dashboard.inventory.brand,
      color: 'secondary',
      icon: '/assets/icons/inventory/ic-solar_settings-minimalistic-bold-duotone.svg',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },
    {
      title: 'Sub Brands',
      value: brandCount?.all_sub_brand_count || 0,
      // path: paths.dashboard.inventory.category,
      icon: '/assets/icons/inventory/ic-solar_file-corrupted-bold-duotone.svg',
      color: 'success',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },
    {
      title: 'Featured Brands',
      // path: paths.dashboard.inventory.product_variation,

      value: brandCount?.all_featured_brand_count || 0,
      icon: '/assets/icons/inventory/ic-solar_home-angle-bold-duotone.svg',
      color: 'warning',
      subItems: [
        {
          title: '',
          value: '',
        },
      ],
    },
  ];

  const TABS = [
    { value: 'all', label: 'All', color: 'default', count: brandCount?.all_brand_count },
    {
      value: 'active',
      label: 'Active',
      color: 'info',
      count: brandCount?.all_active_brand_count,
    },
    {
      value: 'inactive',
      label: 'Inactive',
      color: 'success',
      count: brandCount?.all_inactive_brand_count,
    },
    {
      value: 'featured',
      label: 'Featured',
      color: 'default',
      count: brandCount?.all_featured_brand_count,
    },
    {
      value: 'main_brands',
      label: 'Main Brands',
      color: 'warning',
      count: brandCount?.all_main_brand_count,
    },
    {
      value: 'sub_brands',
      label: 'Sub Brands',
      color: 'secondary',
      count: brandCount?.all_sub_brand_count || 0,
    },
    {
      value: 'is_deleted',
      label: 'Archived',
      color: 'default',
      count: brandCount?.all_deleted_brand_count,
    },
  ];

  const [tab, setTab] = useState(location?.state?.sort ? location?.state?.sort : 'all');

  const handleFilterStatus = (e, value) => {
    setTab(value);
    switch (value) {
      case 'all':
        setFilterData({
          is_deleted: false,
        });
        break;

      case 'active':
        setFilterData({
          is_deleted: false,
          is_active: true,
        });
        break;

      case 'inactive':
        setFilterData({
          is_deleted: false,
          is_active: false,
        });
        break;

      case 'featured':
        setFilterData({
          is_deleted: false,
          is_featured: true,
        });
        break;

      case 'main_brands':
        setFilterData({
          is_deleted: false,
          is_sub: false,
        });
        break;

      case 'sub_brands':
        setFilterData({
          is_deleted: false,
          is_sub: true,
        });
        break;
      case 'is_deleted':
        setFilterData({
          is_deleted: true,
        });
        break;
      default:
        setFilterData({
          is_deleted: true,
        });
        break;
    }
    table.setPage(0);
    navigate(
      {
        pathname: location.pathname,
        search: `?p=${0}&L=${table.rowsPerPage}&s=${value}`,
      },
      {
        state: {
          pageValue: 0,
          limit: table.rowsPerPage,
          sort: value,

        },
      }
    );
  };

  const handleChangeStatus = (uid, value) => {
    const credentials = {
      brandId: uid,
      state: { is_active: value },
      dispatch,
      tab,
    };
    dispatch(changeBrandStatus(credentials));
  };
  const optionBrand = [
    ...(mainBrands?.brands || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const handleChangeBrandSearch = (e) => {
    setSearchValueMainBrand(e.target.value);
  };
  const handleChangeBrand = (e, value) => {
    setFilterData({
      main_brand: value?.value,
      is_deleted: false,
    });
  };
  // console.log('tab', tab);
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12} item>
          <Box
            sx={{
              gap: 2,
              mb: 3,
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' },
            }}
          >
            {inventoryItems?.map((item, key) => (
              <Link to={item?.path} style={{ textDecoration: 'none' }}>
                <InventoryWidgetSummary
                  key={key}
                  title={item?.title}
                  total={item.value}
                  icon={item.icon}
                  color={item?.color}
                />
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={12} xl={10} item>
          <Card>
            <BrandFilterDrawer open={openDrawer} setOpen={setOpenDrawer} />
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Stack px={3} p={2}>
                <CustomBreadcrumbs
                  isMinimal
                  action={
                    <Stack spacing={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className="product-tap-container"
                        gap={5}
                      >
                        <Tabs
                          value={tab}
                          onChange={handleFilterStatus}
                          sx={{
                            px: 2,
                            // backgroundColor: '#F4F6F8',
                            backgroundColor: theme?.palette?.mode === 'dark' ? '' : '#F4F6F8',
                            borderRadius: '6px',
                            // boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                          }}
                        >
                          {TABS.map((tabItem) => (
                            <Tab
                              key={tabItem.value}
                              value={tabItem.value}
                              label={tabItem.label}
                              iconPosition="end"
                              icon={
                                <>
                                  <Label
                                    variant={(tabItem.value === tab && 'filled') || 'soft'}
                                    color={tabItem.color}
                                    sx={{ ml: 1 }}
                                  >
                                    {tabItem.count || 0}
                                  </Label>

                                  {tabItem.value === tab && (
                                    <Chip
                                      sx={{
                                        backgroundColor:
                                          theme?.palette?.mode === 'dark' ? 'grey' : '#fff',
                                        position: 'absolute',
                                        zIndex: -1,
                                        width: '100%',
                                        top: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            theme?.palette?.mode === 'dark' ? '#232324' : '#e1eaf1',
                                        },
                                      }}
                                    />
                                  )}
                                </>
                              }
                              sx={{
                                position: 'relative',
                                zIndex: 1,
                                overflow: 'inherit',
                                width: 'auto',
                                padding: '0 15px',
                              }}
                            />
                          ))}
                        </Tabs>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          display="flex"
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="success"
                            sx={{
                              mx: 1,
                              // width: '156px',
                            }}
                            onClick={handleCreateClick}
                          >
                            <AddRoundedIcon fontSize="small" />
                            Brand
                          </Button>
                        </Stack>
                      </Stack>

                      <Grid container spacing={1}>
                        <Grid item md={9}>
                          <TextField
                            fullWidth
                            value={searchText}
                            // className="custom-search-input-new"
                            onChange={handleChangeSearchText}
                            placeholder="Search Brand"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              mr: 1,
                            }}
                          />
                        </Grid>
                        <Grid item md={3} lg={3} xs={6}>
                          <RHFAutocompleteBasic
                            label="Main Brand"
                            options={optionBrand} // conform this is name to give must label
                            handleChangeValue={handleChangeBrand} // to select value
                            handleChangeSearch={handleChangeBrandSearch}
                            placeholder="Select a brand..."
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  }
                  sx={{
                    mb: { xs: 1, md: 1 },
                  }}
                />
              </Stack>

              <Scrollbar>
                {statusLoading && (
                  <Stack
                    sx={{ mb: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <LoadingScreen sx={{ width: '100%' }} maxWidth="100%" />
                  </Stack>
                )}
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={table.selected?.length}
                    onSort={table.onSort}
                  />

                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress size="20px" color="inherit" />
                        </Paper>
                      </TableCell>
                    ) : (
                      <>
                        {(tableData || []).map((row, index) => (
                          <BrandTableRow
                            key={row.id}
                            row={row}
                            selected={table?.selected?.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onViewRow={() => handleViewRow(row.uid)}
                            loading={loading}
                            colSpan={TABLE_HEAD?.length}
                            onChangeStatus={handleChangeStatus}
                            tab={tab}
                          />
                        ))}
                      </>
                    )}
                  </TableBody>

                  {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                  <TableNoData notFound={notFound} />
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={total || 0}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
