import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tab, Tabs, Stack, alpha } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { createGroup, getGroupDetails, updateGroupDetails } from 'src/server/api/group';

import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';

import BasicDetails from './sections/BasicDetails';
import ImagesDescription from './sections/images&description';

// ----------------------------------------------------------------------

export default function GroupCRUDForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const groupId = params.id;
  const router = useRouter();
  const [selectedItems, setSelectedItems] = useState([]);

  const { loading, groupDetails } = useSelector((state) => ({
    loading: state.group.loading,
    groupDetails: state.group.groupDetails,
    error: state.product.error,
  }));

  console.log(groupDetails);

  useEffect(() => {
    if (groupId) {
      const credentials = {
        groupId,
        dispatch,
      };
      dispatch(getGroupDetails(credentials));
    }
  }, [dispatch, groupId]);

  // state
  const [imagePreview, setImagePreview] = useState([]); // data : public_id:'', url:"", alt_text:{arabic:"",english:""}

  useEffect(() => {
    if (groupDetails?.images?.length >= 1) {
      setImagePreview(groupDetails?.images);
    }
  }, [groupDetails?.images]);

  const GroupSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),

    descriptionEnglish: Yup.string(),
    descriptionArabic: Yup.string(),

    type: Yup.string().required(),
    priority: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value)) // Convert empty string to undefined
      .nullable(), // Allow null values
    platform: Yup.string(),
    layout: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (groupId) {
      return {
        title: {
          english: groupDetails?.title?.english || '',
          arabic: groupDetails?.title?.arabic || '',
        },
        descriptionEnglish: groupDetails?.description?.english || '',
        descriptionArabic: groupDetails?.description?.arabic || '',
        platform: groupDetails?.platform || '',
        priority: groupDetails?.priority || '',
        layout: groupDetails?.layout || '',
        type: groupDetails?.type || '',
        is_live: groupDetails?.is_live || false,
      };
    }
    return {
      title: {
        english: '',
        arabic: '',
      },
      description: '',
      type: '',
      platform: '',
      priority: '',
      layout: '',
      categories: [],
      is_active: true,
      is_live: false,
    };
  }, [groupDetails, groupId]);

  console.log('defaultValues =>', defaultValues);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const methods = useForm({
    resolver: yupResolver(GroupSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const setType = watch('type');

  // useEffect(() => {
  //   setMySelectedValue(itemsList);
  // }, [itemsList]);

  // console.log(itemsList);

  // useEffect(() => {
  //   const typeToValueMap = {
  //     PRODUCTS: productWatch,
  //     SINGLE_PRODUCT: productWatch,
  //     CATEGORIES: categoryWatch,
  //     SINGLE_CATEGORY: categoryWatch,
  //     BRANDS: brandWatch,
  //     SINGLE_BRAND: brandWatch,
  //     COLLECTIONS: collectionWatch,
  //     SINGLE_COLLECTION: collectionWatch,
  //     SPECIAL_SALES: specialSaleWatch,
  //     SINGLE_SPECIAL_SALE: specialSaleWatch,
  //     BANNERS: bannerWatch,
  //     SINGLE_BANNER: bannerWatch,
  //   };

  //   const selectedValue = typeToValueMap[setType] || [];

  //   // Ensure the value is stored in an array if it's not already one
  //   const arrayValue = Array.isArray(selectedValue) ? selectedValue : [selectedValue];

  //   // Reset and set the itemsList with the new arrayValue
  //   removeItems(); // Clear the existing items
  //   arrayValue.forEach((item) => addItems(item)); // Append the new items
  // }, [
  //   bannerWatch,
  //   brandWatch,
  //   categoryWatch,
  //   collectionWatch,
  //   productWatch,
  //   setType,
  //   specialSaleWatch,
  //   removeItems,
  //   addItems,
  //   watch,
  // ]);

  console.log(selectedItems);

  const handleAddItemToList = (type, item) => {
    const singleTypes = [
      'SINGLE_PRODUCT',
      'SINGLE_CATEGORY',
      'SINGLE_BRAND',
      'SINGLE_COLLECTION',
      'SINGLE_SPECIAL_SALE',
      'SINGLE_BANNER',
    ];

    if (singleTypes.includes(setType)) {
      setSelectedItems((prevItems) => (prevItems?.[0]?.uid === item?.uid ? [] : [item]));
    } else {
      setSelectedItems((prevItems = []) => {
        const isAlreadySelected = prevItems?.some((i) => i.uid === item.uid);
        console.log(isAlreadySelected);

        if (isAlreadySelected) {
          return prevItems.filter((i) => i.uid !== item.uid);
        }

        return [...prevItems, item];
      });
    }
  };

  console.log('errors', errors);

  // default data
  useEffect(() => {
    if (groupDetails?.images?.length >= 1) {
      setImagePreview(groupDetails?.images);
    }
  }, [groupDetails?.images]);

  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    const typeMap = {
      PRODUCTS: 'products',
      SINGLE_PRODUCT: 'products',
      CATEGORIES: 'categories',
      SINGLE_CATEGORY: 'categories',
      BRANDS: 'brands',
      SINGLE_BRAND: 'brands',
      COLLECTIONS: 'collections',
      SINGLE_COLLECTION: 'collections',
      SPECIAL_SALES: 'special_sales',
      SINGLE_SPECIAL_SALE: 'special_sales',
      BANNERS: 'banners',
      SINGLE_BANNER: 'banners',
    };

    const selectedKey = typeMap[setType];
    if (selectedKey) {
      setSelectedItems(groupDetails?.[selectedKey]);
    }
  }, [groupDetails, setType]);

  console.log(selectedItems);

  // const onSubmit = (data) => {
  //   const { categories } = data;

  //   // Ensure `categories` is always an array
  //   const normalizedCategories = Array.isArray(categories) ? categories : [categories];

  //   // Pass the normalized value to the backend
  //   const updatedData = { ...data, categories: normalizedCategories };

  //   // Now submit `updatedData` instead of `data`
  //   handleSubmitForm(updatedData);
  // };

  // useEffect(() => {
  //   if (groupId) {
  //     setMySelectedValue({
  //       label: groupDetails?.categories?.map((i) => i?.name?.english),
  //       logo: groupDetails?.categories?.map((i) => i?.logo),
  //       value: groupDetails?.categories?.map((i) => i?.uid),
  //     });
  //   }
  // }, [groupDetails?.categories, groupId]);

  // console.log(mySelectedValue);
  console.log(selectedItems);

  const onSubmit = async (data) => {
    console.log(data);

    let key;
    switch (setType) {
      case 'CATEGORIES':
      case 'SINGLE_CATEGORY':
        key = 'categories';
        break;
      case 'PRODUCTS':
      case 'SINGLE_PRODUCT':
        key = 'products';
        break;
      case 'BRANDS':
      case 'SINGLE_BRAND':
        key = 'brands';
        break;
      case 'COLLECTIONS':
      case 'SINGLE_COLLECTION':
        key = 'collections';
        break;
      case 'SPECIAL_SALES':
      case 'SINGLE_SPECIAL_SALE':
        key = 'special_sales';
        break;
      case 'BANNER':
      case 'SINGLE_BANNER':
        key = 'banners';
        break;
      default:
        key = null;
    }

    const initialData = {
      ...data,
      images: imagePreview,
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };

    if (key) {
      initialData[key] = selectedItems?.map((i) => i?.uid);
    }

    ['descriptionArabic', 'descriptionEnglish'].forEach((descKey) => {
      delete initialData[descKey];
    });

    const updateInitialData = {
      ...data,
      images: imagePreview,
    };

    if (key) {
      updateInitialData[key] = selectedItems?.map((i) => i?.uid);
    }

    // Prepare data for dispatch
    const createData = {
      state: initialData,
      dispatch,
      router,
    };
    const updateData = {
      state: updateInitialData,
      dispatch,
      router,
      groupId,
    };

    if (groupId) {
      // Update case
      console.log(updateData);
      dispatch(updateGroupDetails(updateData));
    } else {
      // Create case
      console.log(createData);
      dispatch(createGroup(createData));
    }
  };

  const STATUS_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'img&description', label: 'Image & Description' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  return (
    <>
      <Typography variant="subtitle1">{groupId ? 'Update Group' : 'Create Group'}</Typography>
      <Typography variant="caption">Please complete the Group information.</Typography>
      <Grid container maxWidth="xl" spacing={2} mt={2}>
        <Grid xs={12} md={8}>
          {loading ? (
            <Stack sx={{ height: '50px' }}>
              <LoadingScreen />
            </Stack>
          ) : (
            <Card sx={{ pb: 2 }}>
              <Tabs
                value={tabChange.value}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab, key) => (
                  <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
                ))}
              </Tabs>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ width: '400px', top: '0', position: 'absolute', right: 0 }}>
                  <Grid
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                    }}
                  >
                    {errors?.name?.english?.message ? (
                      <Typography variant="caption" color="red">
                        Please fill the required fields!
                      </Typography>
                    ) : (
                      <Stack />
                    )}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="small"
                      loading={isSubmitting || loading}
                      sx={{
                        ml: 2,
                        width: '150px',
                        backgroundColor: '#106E58',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Stack>
                <>
                  {/* // Tab component */}

                  {tabChange.value === 'basicDetails' && (
                    <BasicDetails
                      isUpdate={groupDetails?.uid && true}
                      watch={watch}
                      setValue={setValue}
                      control={control}
                      handleAdd={handleAddItemToList}
                      selectedValue={selectedItems}

                    // handleRemoveProduct={handleRemoveItemToList}
                    />
                  )}
                  {tabChange.value === 'img&description' && (
                    <ImagesDescription
                      control={control}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                    />
                  )}
                </>
                <Grid
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                  }}
                >
                  {errors?.name?.english?.message ? (
                    <Typography variant="caption" color="red">
                      Please fill the required fields!
                    </Typography>
                  ) : (
                    <Stack />
                  )}
                </Grid>
              </FormProvider>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
