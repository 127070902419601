import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import { useTheme } from '@emotion/react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';

import OspImageUploader from 'src/pages/Common/upload';
import {
  createBrand,
  getAllBrands,
  getBrandDetails,
  updateBrandDetails,
  checkBrandNameValid,
} from 'src/server/api/brand';

import OspSelectAutocomplete from 'src/components/hook-form/osp-select';
import FormProvider, { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function BasicDetailsForm() {
  const router = useRouter();
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const params = useParams();
  // const isMobile = useResponsive('sm', 'xs');

  const { loading, brandDetails, brandList, isBrandValid, credLoading } = useSelector((state) => ({
    loading: state.brand.loading,
    brandDetails: state.brand.brandDetails,
    error: state.brand.error,
    brandList: state.brand.brands,
    isBrandValid: state.brand.isBrandValid,
    credLoading: state.brand.credLoading,
  }));
  const brandId = params.id;
  const theme = useTheme();

  // console.log(brandDetails);

  useEffect(() => {
    if (brandId) {
      const credentials = {
        brandId,
        dispatch,
      };
      dispatch(getBrandDetails(credentials));
    }
  }, [dispatch, brandId]);

  const [imagePreview, setImagePreview] = useState([]);

  // main Brand
  const [searchValueMainBrand, setSearchValueMainBrand] = useState('');

  const BrandSchema = Yup.object().shape({
    name: Yup.object().shape({
      english: Yup.string().required('Name in English is required'),
      arabic: Yup.string(),
    }),
    descriptionEnglish: Yup.string(),
    descriptionArabic: Yup.string(),

    // main_brand: Yup.object().shape({
    //   label: Yup.string(),
    //   value: Yup.string(),
    // }),
    // ..
    priority: Yup.number(),
    is_featured: Yup.boolean(),
    is_sub: Yup.boolean(),
  });

  const defaultValues = useMemo(() => {
    if (brandId) {
      return {
        name: {
          english: brandDetails?.name?.english || '',
          arabic: brandDetails?.name?.arabic || '',
        },
        descriptionEnglish: brandDetails?.description?.english || '',
        descriptionArabic: brandDetails?.description?.arabic || '',

        priority: brandDetails?.priority || 0,

        // main_brand: {
        //   label: brandDetails?.main_brand?.first_name || '',
        //   value: brandDetails?.main_brand?.uid || '',
        // },
        is_active: brandDetails?.is_active || false,
        is_featured: brandDetails?.is_featured || false,
        is_sub: brandDetails?.is_sub || false,
      };
    }
    return {
      name: {
        english: '',
        arabic: '',
      },
      main_brand: {},
      descriptionEnglish: '',
      descriptionArabic: '',
      priority: 0,
      is_featured: false,
      is_sub: false,
    };
  }, [
    brandDetails?.description?.arabic,
    brandDetails?.description?.english,
    brandDetails?.is_active,
    brandDetails?.is_featured,
    brandDetails?.is_sub,
    brandDetails?.name?.arabic,
    brandDetails?.name?.english,
    brandDetails?.priority,
    brandId,
  ]);

  const methods = useForm({
    resolver: yupResolver(BrandSchema),
    defaultValues,
  });

  useEffect(() => {
    // todo
    if (brandId) {
      // setImagePreview(brandDetails?.logo);
    }
  }, [brandId, brandDetails?.logo]);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isSub = watch('is_sub');
  const brandName = watch('name.english');
  useEffect(() => {
    if (brandName) {
      const createCredentials = {
        state: {
          english_name: brandName,
        },
        dispatch,
      };
      dispatch(checkBrandNameValid(createCredentials));
    }
  }, [dispatch, brandName]);
  const onSubmit = handleSubmit(async (data) => {
    const createState = {
      ...data,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };

    if (data?.is_sub) {
      createState.main_brand = data?.main_brand?.value;
    } else {
      createState.main_brand = '';
    }

    ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
      delete createState[key];
    });
    if (!data.priority) {
      delete createState.priority;
    }
    const createCredentials = {
      state: createState,
      dispatch,
      router,
    };

    // update functions
    const updateState = {
      ...data,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };

    if (data?.is_sub) {
      updateState.main_brand = data?.main_brand?.value;
    } else {
      updateState.main_brand = '';
    }

    ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
      delete updateState[key];
    });

    const updateCredentials = {
      state: updateState,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},

      brandId: brandDetails?.uid,
      dispatch,
    };

    if (brandId) {
      dispatch(updateBrandDetails(updateCredentials));
    } else {
      dispatch(createBrand(createCredentials));
    }
  });

  // useEffect(() => {
  //   reset({});
  // }, [reset]);

  // main category function
  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueMainBrand,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch, searchValueMainBrand]);
  const filteredBrands = () => {
    const filteredItems = brandList?.brands?.filter((item) => item?.uid !== params?.id);
    return filteredItems || [];
  };
  // const mainBrandsOptions = [
  //   ...(brandList?.brands || []).map((results) => ({
  //     label: results?.name?.english,
  //     value: results?.uid,
  //   })),
  // ];
  const mainBrandsOptions = [
    ...filteredBrands().map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];
  const defaultMainBrand = useMemo(
    () => ({
      label: brandDetails?.main_brand?.name?.english || '',
      value: brandDetails?.main_brand?.uid || '',
    }),
    [brandDetails]
  );

  // update current data to from state
  useEffect(() => {
    if (defaultMainBrand.value) {
      setValue('main_brand', defaultMainBrand);
    }
  }, [defaultMainBrand, setValue]);

  const handleTextFieldChangeMainBrand = (value) => {
    setSearchValueMainBrand(value);
  };
  // console.log('image preview', imagePreview);

  useEffect(() => {
    if (brandDetails?.logo?.public_id) {
      setImagePreview([brandDetails?.logo]);
    }
  }, [brandDetails?.logo]);
  return (
    <Stack>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} md={12}>
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              px={2}
              my={2}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                {brandId && <RHFSwitch name="is_active" color="success" label="Active" />}
                <RHFSwitch name="is_featured" color="primary" label="Featured" />
              </Stack>
              {brandId && (
                <Stack alignItems="end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    loading={isSubmitting || credLoading}
                    sx={{
                      // width: '140px',
                      backgroundColor: '#106E58',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#0b4e3e',
                      },
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              )}
            </Stack>

            <Stack sx={{ p: 2, pt: 0 }}>
              <Grid container maxWidth="xl" spacing={2}>
                <Grid xs={12} md={6} item>
                  <RHFTextField
                    name="name.english"
                    label="English Name"
                    type="string"
                    required
                    size="small"
                    placeholder="English Name"
                    // isValidationRequired={!!brandName}
                    isValidationRequired={
                      brandName && brandName !== brandDetails?.name?.english ? !!brandName : false
                    }
                    isValid={isBrandValid?.english_name}
                    // validationMsg="Brand is not available"
                    validationMsg="Duplicate english name!"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Stack
                    className={
                      theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'
                    }
                    width="100%"
                  >
                    <RHFTextField
                      name="name.arabic"
                      label="Arabic Name"
                      type="string"
                      size="small"
                      placeholder="Arabic Name"
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} md={3} item>
                  <RHFTextField
                    name="priority"
                    label="Priority"
                    type="number"
                    size="small"
                    placeholder="Priority"
                  />
                </Grid>
                <Grid xs={12} md={3} item>
                  <RHFSwitch name="is_sub" color="secondary" label="Sub Brand" />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Tooltip
                    title={watch('is_sub') ? '' : 'Switch sub-brand toggle to select main brand'}
                  >
                    <Stack>
                      <OspSelectAutocomplete
                        name="main_brand"
                        disabled={!isSub}
                        control={control}
                        options={mainBrandsOptions}
                        label="Main Brand"
                        placeholder="Main Brand"
                        handleTextFieldChange={handleTextFieldChangeMainBrand}
                        required={isSub}
                      />
                    </Stack>
                  </Tooltip>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Description
            <Divider sx={{ mt: 1 }} />
          </Typography>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5}>
                <Typography variant="caption">In English</Typography>
                <RHFEditor placeholder="Write description" name="descriptionEnglish" />
              </Stack>
            </Grid>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5} className="editor-arabic">
                <Typography variant="caption">In Arabic</Typography>
                <RHFEditor name="descriptionArabic" placeholder="اكتب الوصف" />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* {!brandId && ( */}
        <Stack
          sx={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            alignItems: 'end',
            p: 2,
            mr: 2,
            mb: 1,
            width: '100%',
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="small"
            loading={isSubmitting || loading}
            sx={{
              ml: 2,
              // width: '180px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            {brandId ? 'Save Changes' : 'Create Brand'}
          </LoadingButton>
        </Stack>
        {/* )} */}
      </FormProvider>
      <Stack sx={{ p: 2, px: 3, pt: 0, mb: 5 }}>
        <Typography variant="subtitle2" sx={{ mb: 0, pb: 2 }}>
          Image <Divider sx={{ mt: 1 }} />
        </Typography>

        <OspImageUploader
          folder="brands"
          single
          setImagesPreview={setImagePreview}
          imagesPreview={imagePreview}
        />
      </Stack>
    </Stack>
  );
}

BasicDetailsForm.propTypes = {};
