import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCategory,
  getAllCategory,
  deleteCategory,
  archiveCategory,
  restoreCategory,
  getCategoryCounts,
  getCategoryDetails,
  getAllMainCategory,
  changeCategoryStatus,
  updateCategoryDetails,
  checkCategoryNameValid,
} from '../api/category';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    loading: false,
    error: {},
    // staff
    categories: {},
    credLoading: false,
    staffDropList: [],
    categoryDetails: {},
    categoryCounts: {},
    isCategoryValid: {},
    categoryNameLoading: false,
    delLoading: false,
    statusLoading: false,
    mainCategories: {},
    mainCategoryLoading: false,
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearCategoryDetailsState: (state) => {
      state.categoryDetails = {};
    },
  },
  extraReducers: {
    // create category details
    [createCategory.pending]: (state) => {
      state.credLoading = true;
    },
    [createCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const newDistrictsList = jsonState?.categories;

      const modifiedCategoryList = {
        ...jsonState,
        categories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };

      state.credLoading = false;
      state.categoryDetails = action.payload;
      state.categories = modifiedCategoryList;
      state.error = {};
    },
    [createCategory.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    // get all categories
    [getAllCategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = {};
    },
    [getAllCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all main categories
    [getAllMainCategory.pending]: (state) => {
      state.mainCategoryLoading = true;
    },
    [getAllMainCategory.fulfilled]: (state, action) => {
      state.mainCategoryLoading = false;
      state.mainCategories = action.payload;
      state.error = {};
    },
    [getAllMainCategory.rejected]: (state, action) => {
      state.mainCategoryLoading = false;
      state.error = action.error;
    },

    [getCategoryCounts.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryCounts = action.payload;
      state.error = {};
    },
    [getCategoryCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single staff details
    [getCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryDetails = action.payload;
      state.error = {};
    },
    [getCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateCategoryDetails.pending]: (state) => {
      state.credLoading = true;
    },
    // [updateCategoryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const modifiedCategoryList = {
        ...jsonState,
        categories: jsonState.categories?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.credLoading = false;
      // state.categories = modifiedCategoryList;
      state.categoryDetails = action.payload;
      state.categories = modifiedCategoryList;
      state.error = {};
    },
    // [updateCategoryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.categories;
    //   console.log(jsonState)
    //   const modifiedCategoryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCategoryDetails.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    //  category status change
    [changeCategoryStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [changeCategoryStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const modifiedCategoryList = {
        ...jsonState,
        categories: jsonState?.categories?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedCategoryList2 = {
        ...jsonState,
        categories: jsonState?.categories?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.categories =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedCategoryList2
          : modifiedCategoryList;
      state.error = {};
    },

    [changeCategoryStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
    // check category name
    [checkCategoryNameValid.pending]: (state) => {
      state.categoryNameLoading = true;
    },
    [checkCategoryNameValid.fulfilled]: (state, action) => {
      state.categoryNameLoading = false;
      state.isCategoryValid = action.payload;
      state.error = {};
    },
    [checkCategoryNameValid.rejected]: (state, action) => {
      state.categoryNameLoading = false;
      state.error = action.error;
    },
    [deleteCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const modifiedCategoryList = {
        ...jsonState,
        categories: jsonState.categories?.filter((categories) => categories.uid !== action.payload),
      };
      state.delLoading = false;
      state.categories = modifiedCategoryList;
      state.error = {};
    },
    [deleteCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive category
    [archiveCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const modifiedCategoryList = {
        ...jsonState,
        categories: jsonState.categories?.filter((category) => category.uid !== action.payload),
      };

      state.delLoading = false;
      state.categories = modifiedCategoryList;
      state.error = {};
    },
    [archiveCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore category
    [restoreCategory.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.categories;
      const modifiedCategoryList = {
        ...jsonState,
        categories: jsonState.categories?.filter((category) => category.uid !== action.payload),
      };

      state.delLoading = false;
      state.categories = modifiedCategoryList;
      state.error = {};
    },
    [restoreCategory.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearCategoryDetailsState } = categorySlice.actions;

export default categorySlice.reducer;
