import {  useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
// Import the user icon
import {Card, Grid, CardMedia, Typography} from '@mui/material';

import NoImg from 'src/assets/other/ImgNull.jpg';
import { getUserDetails } from 'src/server/api/user';
import Img1 from 'src/assets/images/common/product.png';

import TextMaxLine from 'src/components/text-max-line';


// ----------------------------------------------------------------------

export default function Favorites() {
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const params = useParams();
  // const isMobile = useResponsive('sm', 'xs');

  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }));
  
  const userId = params.id;

  console.log('user Details', userDetails);

  useEffect(() => {
    if (userId) {
      const credentials = {
        userId,
        dispatch,
      };
      dispatch(getUserDetails(credentials));
    }
  }, [dispatch, userId]);
  const productData = [
    {
      name: 'Karcher VC 1.77 Multi-Purpose Vacuum Cleaner',
      code: '9012018',
      stock: '2',
      value: '16.9',
      images: Img1

    },
    {
      name: 'blaa VC 1.8 Multi-Purpose Vacuum processor',
      code: '9012018',
      stock: '2',
      value: '16.9',
      images: Img1

    },{
      name: 'shushu VC 1.45 Multi-Purpose Vacuum viewer',
      code: '9012018',
      stock: '2',
      value: '16.9',
      images: Img1

    },{
      name: 'kya VC 1.6 Multi-Purpose Vacuum Null',
      code: '9012018',
      stock: '2',
      value: '16.9',

    }
  
  ];

  return (
    <Grid container spacing={2} p={1}>
    {productData.map((item, index) => (
      <Grid item xs={12} sm={6} md={6} key={index} sx={{ display: 'flex' }}>
        <Card
          sx={{ p: 1.5, borderRadius: '10px', width:'100%',display:'flex',direction:'row', backgroundColor:'rgba(145, 158, 171, 0.08)' }}
        >

          <CardMedia
            component="img"
            src={item?.images|| NoImg} 
            alt="products"
            sx={{ width:'182px',height:'182px'}}
          />
          <Stack direction="column" gap={1} px={2}>
            <Typography variant="body1" >
              {item?.name}
            </Typography>
            <TextMaxLine line={2} variant="body2" color="#637381"> 
            {item?.code}
          </TextMaxLine>
            <Typography variant="caption" >
              In Stock: {item?.stock}
            </Typography>
        
          <Typography display="flex" alignItems="center" pt={1}  fontWeight='bold'variant="body1">
            OMR{' '}
            <Typography pl={1} variant="body1" fontWeight='bold'>
              {item?.value > 0 ? item?.value : item?.price}
            </Typography>
          </Typography>
          </Stack>

        </Card>
      </Grid>
    ))}
  </Grid>
  )
}

Favorites.propTypes = {};
