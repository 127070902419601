import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createGroup,
  deleteGroup,
  getAllGroups,
  archiveGroup,
  restoreGroup,
  getGroupCounts,
  getGroupDetails,
  updateGroupStatus,
  updateGroupDetails,
} from '../api/group';

export const groupSlice = createSlice({
  name: 'group',
  initialState: {
    loading: false,
    error: {},
    groups: {},
    groupCounts: {},
    groupDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearProductGroupError: (state) => {
      state.error = true;
    },
    clearGroupDetailsState: (state) => {
      state.groupDetails = {};
    },
  },
  extraReducers: {
    // create
    [createGroup.pending]: (state) => {
      state.loading = true;
    },
    [createGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.groups = modifiedDistrictList;
      state.error = {};
    },
    [createGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllGroups.pending]: (state) => {
      state.loading = true;
    },
    [getAllGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.groups = action.payload;
      state.error = {};
    },
    [getAllGroups.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get group count
    [getGroupCounts.pending]: (state) => {
      state.loading = true;
    },
    [getGroupCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.groupCounts = action.payload;
      state.error = {};
    },
    [getGroupCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    [getGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.groupDetails = action.payload;
      state.error = {};
    },
    [getGroupDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updateGroupDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductGroupDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updateGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.groupDetails = action.payload;
      state.groups = action.payload;
      state.error = {};
    },

    [updateGroupDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateGroupStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updateGroupStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.groups;
      const modifiedProductGroupList = {
        ...jsonState,
        groups: jsonState?.groups?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.groups = modifiedProductGroupList;
      state.error = {};
    },

    [updateGroupStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archiveGroup.pending]: (state) => {
      state.delLoading = true;
    },
    // archive
    [archiveGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.groups;
      const modifiedProductList = {
        ...jsonState,
        groups: jsonState.groups?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.groups = modifiedProductList;
      state.error = {};
    },
    [archiveGroup.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restoreGroup.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.groups;
      const modifiedGroupList = {
        ...jsonState,
        groups: jsonState.groups?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.groups = modifiedGroupList;
      state.error = {};
    },
    [restoreGroup.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deleteGroup.pending]: (state) => {
      state.loading = true;
    },
    [deleteGroup.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.groups;
      const modifiedDistrictList = {
        ...jsonState,
        groups: jsonState.groups?.filter((groups) => groups.uid !== action.payload),
      };
      state.loading = false;
      state.groups = modifiedDistrictList;
      state.error = {};
    },
    [deleteGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductGroupError, clearGroupDetailsState } = groupSlice.actions;

export default groupSlice.reducer;
