import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Typography } from '@mui/material';

import NoImg from 'src/assets/other/ImgNull.jpg';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import { useGetEncryptLocalData } from 'src/hooks/useHandleSessions';

// ----------------------------------------------------------------------

export default function UserOrderTableRow({ row, selected, onChangeStatus, tab }) {
  const { name, sap_id, stock, offer_price, price, categories, images } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Stack sx={{ flexDirection: 'row', gap: '17px', alignItems: 'center' }}>
          <Stack>
            {images?.length > 0 ? (
              <img src={images[0].url} alt="First" className="product-all-list-img" />
            ) : (
              <img src={NoImg} alt="No" className="product-all-list-img" />
            )}
          </Stack>
          <Stack>
            <Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{name?.english}</Typography>
            </Stack>
            <Stack px={1}>
              <Typography sx={{ color: '#919EAB', fontSize: '14px', fontWeight: '400' }}>
                {sap_id}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack spacing={1} flexDirection="row" alignItems="center" flexWrap="wrap">
          {categories?.map((item, key) => (
            // <Button variant='text' key={key} size='small'>
            //   {item?.name?.english}
            // </Button>
            <Typography variant="caption" key={key} sx={{ fontWeight: '500' }}>
              {item?.name?.english}{' '}
              {categories?.length > 1 && categories?.length !== key + 1 ? ',' : ''}
            </Typography>
            // <Chip label={item?.name?.english} key={key} variant="filled" color="default" />
          ))}
        </Stack>
      </TableCell>

      <TableCell>
        {stock <= 0 ? (
          <Typography color="red" variant="caption" sx={{}}>
            Out of stock
          </Typography>
        ) : (
          stock
        )}
      </TableCell>

      <TableCell>
        {/* OMR {offer_price || price} */}
        {offer_price ? (
          <>
            <Typography variant="caption" sx={{ textDecoration: 'line-through' }}>
              {price}
            </Typography>
            &nbsp;{' '}
            <Typography variant="caption" fontWeight="bold">
              OMR {offer_price}
            </Typography>
          </>
        ) : (
          <Typography variant="caption" fontWeight="bold">
            OMR {price}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

UserOrderTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onChangeStatus: PropTypes.any,
  tab: PropTypes.any,
};
