import PropTypes from 'prop-types';

// import Avatar from '@mui/material/Avatar';
import { ListItemText } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { fDate } from 'src/utils/format-time';


// ----------------------------------------------------------------------

export default function RedeemedUserTableRow({ row, selected }) {
  const { order, createdAt, user, } = row;


  return (
    <TableRow hover selected={selected}>
      <TableCell
      >
        {fDate(createdAt)}
      </TableCell>
      <TableCell

      >
        <ListItemText
          primary={
            <span>{(user?.country_code && user?.phone) ? `${user.country_code}-${user.phone}` : '---'}</span>
          }
          secondary={user?.name}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell

      >
        {order?.order_id || '---'}
      </TableCell>


    </TableRow>
  );
}

RedeemedUserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
