import PropTypes from 'prop-types';

import { Grid, Stack, Divider, Typography } from '@mui/material';

import OspImageUploader from 'src/pages/Common/upload';

import { RHFEditor } from 'src/components/hook-form';

function ImagesDescription({ control, setImagePreview, imagePreview }) {
  return (
    <Stack spacing={3} sx={{ p: 1.5 }}>
      <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
        Image
      </Typography>
      <Divider sx={{ pt: 0, mt: -2, mb: -2 }} />
      <Grid container>
        <Grid item>
          <Stack sx={{ p: 1 }} spacing={0} width="100%" direction="column">
            <OspImageUploader setImagesPreview={setImagePreview} imagesPreview={imagePreview} />
          </Stack>
        </Grid>
      </Grid>

      <Stack spacing={1.5}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Description
          <Divider sx={{ mt: 1 }} />
        </Typography>
        <Grid container maxWidth="xl" spacing={2}>
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5}>
              <Typography variant="caption">In English</Typography>
              <RHFEditor simple placeholder="Write description" name="descriptionEnglish" />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5} className="editor-arabic">
              <Typography variant="caption">In Arabic</Typography>
              <RHFEditor simple name="descriptionArabic" placeholder="Write description" />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ImagesDescription;

ImagesDescription.propTypes = {
  control: PropTypes.any,
  imagePreview: PropTypes.array,
  setImagePreview: PropTypes.func,
};
