import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createReview,
  deleteReview,
  archiveReview,
  getAllReviews,
  restoreReview,
  getReviewCounts,
  getReviewDetails,
  updateReviewStatus,
  updateReviewDetails,
} from '../api/reviews';

export const reviewSlice = createSlice({
  name: 'reviews',
  initialState: {
    loading: false,
    error: {},
    reviews: {},
    reviewCounts: {},
    reviewDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearProductGroupError: (state) => {
      state.error = true;
    },
    clearReviewState: (state) => {
      state.reviewDetails = {};
    },
  },
  extraReducers: {
    // create
    [createReview.pending]: (state) => {
      state.loading = true;
    },
    [createReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.reviews = action.payload;
      state.reviews = modifiedList;
      state.error = {};
    },
    [createReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllReviews.pending]: (state) => {
      state.loading = true;
    },
    [getAllReviews.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviews = action.payload;
      state.error = {};
    },
    [getAllReviews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get group count
    [getReviewCounts.pending]: (state) => {
      state.loading = true;
    },
    [getReviewCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewCounts = action.payload;
      state.error = {};
    },
    [getReviewCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getReviewDetails.pending]: (state) => {
      state.loading = true;
    },
    [getReviewDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewDetails = action.payload;
      state.error = {};
    },
    [getReviewDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updateReviewDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductGroupDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updateReviewDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewDetails = action.payload;
      state.reviews = action.payload;
      state.error = {};
    },

    [updateReviewDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateReviewStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updateReviewStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviews;
      const modifiedReviewList = {
        ...jsonState,
        reviews: jsonState?.reviews?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.reviews = modifiedReviewList;
      state.error = {};
    },

    [updateReviewStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archiveReview.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviews;
      const modifiedReviewList = {
        ...jsonState,
        reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
      };

      state.delLoading = false;
      state.reviews = modifiedReviewList;
      state.error = {};
    },
    [archiveReview.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restoreReview.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviews;
      const modifiedReviewList = {
        ...jsonState,
        reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
      };

      state.delLoading = false;
      state.reviews = modifiedReviewList;
      state.error = {};
    },
    [restoreReview.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deleteReview.pending]: (state) => {
      state.loading = true;
    },
    [deleteReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviews;
      const modifiedList = {
        ...jsonState,
        reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
      };
      state.loading = false;
      state.reviews = modifiedList;
      state.error = {};
    },
    [deleteReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductGroupError, clearReviewState } = reviewSlice.actions;

export default reviewSlice.reducer;
