import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import { useTheme } from '@emotion/react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';

import OspImageUploader from 'src/pages/Common/upload';
import {
  createCategory,
  getAllCategory,
  getCategoryDetails,
  updateCategoryDetails,
  checkCategoryNameValid,
} from 'src/server/api/category';

import OspSelectAutocomplete from 'src/components/hook-form/osp-select';
import FormProvider, { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function BasicDetailsForm() {
  const router = useRouter();
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const params = useParams();
  // const isMobile = useResponsive('sm', 'xs');
  const theme = useTheme();

  const { loading, categoryDetails, categoryList, isCategoryValid, credLoading } = useSelector(
    (state) => ({
      loading: state.category.loading,
      categoryDetails: state.category.categoryDetails,
      error: state.category.error,
      categoryList: state.category.categories,
      isCategoryValid: state.category.isCategoryValid,
      credLoading: state.category.credLoading,
    })
  );
  const categoryId = params.id;

  // console.log(categoryDetails);

  useEffect(() => {
    if (categoryId) {
      const credentials = {
        categoryId,
        dispatch,
      };
      dispatch(getCategoryDetails(credentials));
    }
  }, [dispatch, categoryId]);

  const [imagePreview, setImagePreview] = useState([]);

  // main Category
  const [searchValueMainCategory, setSearchValueMainCategory] = useState('');

  const CategorySchema = Yup.object().shape({
    name: Yup.object().shape({
      english: Yup.string().required('Name in English is required'),
      arabic: Yup.string(),
    }),
    descriptionEnglish: Yup.string(),
    descriptionArabic: Yup.string(),

    // main_category: Yup.object().shape({
    //   label: Yup.string(),
    //   value: Yup.string(),
    // }),
    // ..
    priority: Yup.number(),
    is_featured: Yup.boolean(),
    is_sub: Yup.boolean(),
  });

  // console.log(categoryDetails?.main_category);
  const defaultValues = useMemo(() => {
    if (categoryId) {
      return {
        name: {
          english: categoryDetails?.name?.english || '',
          arabic: categoryDetails?.name?.arabic || '',
        },
        descriptionEnglish: categoryDetails?.description?.english || '',
        descriptionArabic: categoryDetails?.description?.arabic || '',

        priority: categoryDetails?.priority,

        // main_category: mainCategory,

        is_active: categoryDetails?.is_active || false,
        is_featured: categoryDetails?.is_featured || false,
        is_sub: categoryDetails?.is_sub || false,
      };
    }
    return {
      name: {
        english: '',
        arabic: '',
      },
      main_category: {},
      descriptionEnglish: '',
      descriptionArabic: '',
      priority: 0,
      is_featured: false,
      is_sub: false,
    };
  }, [
    categoryDetails?.description?.arabic,
    categoryDetails?.description?.english,
    categoryDetails?.is_active,
    categoryDetails?.is_featured,
    categoryDetails?.is_sub,
    categoryDetails?.name?.arabic,
    categoryDetails?.name?.english,
    categoryDetails?.priority,
    categoryId,
  ]);

  const methods = useForm({
    resolver: yupResolver(CategorySchema),
    defaultValues,
  });

  useEffect(() => {
    // todo
    if (categoryId) {
      // setImagePreview(categoryDetails?.logo);
    }
  }, [categoryId, categoryDetails?.logo]);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isSub = watch('is_sub');
  const categoryName = watch('name.english');

  // useEffect(() => {
  //   if (categoryName) {
  //     const createCredentials = {
  //       state: {
  //         english_name: categoryName,
  //       },
  //       dispatch,
  //     };
  //     dispatch(checkCategoryNameValid(createCredentials));
  //   }
  // }, [dispatch, categoryName]);
  useEffect(() => {
    if (categoryName && categoryName !== categoryDetails?.name?.english) {
      const createCredentials = {
        state: {
          english_name: categoryName,
        },
        dispatch,
      };
      dispatch(checkCategoryNameValid(createCredentials));
    }
  }, [dispatch, categoryDetails?.name?.english, categoryDetails.uid, categoryName]);

  const onSubmit = handleSubmit(async (data) => {
    const createState = {
      ...data, // add current data from the form @mufeeda
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };

    if (data?.is_sub) {
      createState.main_category = data?.main_category?.value;
    } else {
      createState.main_category = '';
    }

    ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
      delete createState[key];
    });
    if (!data.priority) {
      delete createState.priority;
    }
    const createCredentials = {
      state: createState,
      dispatch,
      router,
    };

    // update functions
    const updateState = {
      ...data,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };

    if (data?.is_sub) {
      updateState.main_category = data?.main_category?.value;
    } else {
      updateState.main_category = '';
    }

    ['descriptionArabic', 'descriptionEnglish']?.forEach((key) => {
      delete updateState[key];
    });

    const updateCredentials = {
      state: updateState,
      categoryId: categoryDetails?.uid,
      dispatch,
    };

    if (categoryId) {
      dispatch(updateCategoryDetails(updateCredentials));
    } else {
      dispatch(createCategory(createCredentials));
    }
  });

  // useEffect(() => {
  //   reset({});
  // }, [reset]);

  // main category function
  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueMainCategory,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };
    dispatch(getAllCategory(credentials));
  }, [dispatch, searchValueMainCategory]);
  const filteredCategories = () => {
    const filteredItems = categoryList?.categories?.filter((item) => item?.uid !== params?.id);
    return filteredItems || [];
  };
  // const mainCategoryOptions = [
  //   ...filteredCategories.map((results) => ({
  //     label: results?.name?.english,
  //     value: results?.uid,
  //   })),
  // ];
  const mainCategoryOptions = [
    ...filteredCategories().map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];
  const defaultMainCategory = useMemo(
    () => ({
      label: categoryDetails?.main_category?.name?.english || '',
      value: categoryDetails?.main_category?.uid || '',
    }),
    [categoryDetails]
  );

  // update current data to from state
  useEffect(() => {
    if (defaultMainCategory.value) {
      setValue('main_category', defaultMainCategory);
    }
  }, [defaultMainCategory, setValue]);

  const handleTextFieldChangeMainCategory = (value) => {
    setSearchValueMainCategory(value);
  };
  // useEffect(() => {
  //   if (categoryDetails?.uid) {
  //     setImagePreview(
  //       [
  //         {
  //           alt_text: {
  //             english: categoryDetails.logo?.alt_text?.english,
  //             arabic: categoryDetails.logo?.alt_text?.arabic,
  //           },
  //           public_id: categoryDetails.logo?.public_id,
  //           url: categoryDetails.logo?.url,
  //         },
  //       ] || []
  //     );
  //   }
  // }, [
  //   categoryDetails.logo?.alt_text?.arabic,
  //   categoryDetails.logo?.alt_text?.english,
  //   categoryDetails.logo?.public_id,
  //   categoryDetails.logo?.url,
  //   categoryDetails?.uid,
  // ]);
  // console.log(categoryDetails);
  // console.log('image preview', imagePreview);
  useEffect(() => {
    if (categoryDetails?.logo?.public_id) {
      setImagePreview([categoryDetails?.logo]);
    }
  }, [categoryDetails?.logo]);
  // console.log(isCategoryValid);
  return (
    <Stack sx={{ position: 'relative' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} md={12}>
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              px={2}
              my={2}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                {categoryId && <RHFSwitch name="is_active" color="success" label="Active" />}
                <RHFSwitch name="is_featured" color="primary" label="Featured" />
              </Stack>
              {categoryId && (
                <Stack alignItems="end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    loading={isSubmitting || credLoading}
                    sx={{
                      // width: '140px',
                      backgroundColor: '#106E58',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#0b4e3e',
                      },
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              )}
            </Stack>

            <Stack sx={{ p: 2, pt: 0 }}>
              <Grid container maxWidth="xl" spacing={2}>
                <Grid xs={12} md={6} item>
                  <RHFTextField
                    name="name.english"
                    label="English Name"
                    type="string"
                    required
                    size="small"
                    placeholder="English Name"
                    // isValidationRequired={!!categoryName}
                    isValidationRequired={
                      categoryName && categoryName !== categoryDetails?.name?.english
                        ? !!categoryName
                        : false
                    }
                    isValid={isCategoryValid?.english_name}
                    // validationMsg="Category is not available"
                    validationMsg="Duplicate english name!"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Stack
                    className={
                      theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'
                    }
                    width="100%"
                  >
                    <RHFTextField
                      name="name.arabic"
                      label="Arabic Name"
                      type="string"
                      size="small"
                      placeholder="Arabic Name"
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} md={3} item>
                  <RHFTextField
                    name="priority"
                    label="Priority"
                    type="number"
                    size="small"
                    placeholder="Priority"
                  />
                </Grid>
                <Grid xs={12} md={3} item>
                  <RHFSwitch name="is_sub" color="secondary" label="Subcategory" />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Tooltip
                    title={
                      watch('is_sub') ? '' : 'Switch subcategory toggle to select main category'
                    }
                  >
                    <Stack>
                      <OspSelectAutocomplete
                        name="main_category"
                        disabled={!isSub}
                        control={control}
                        options={mainCategoryOptions}
                        label="Main Category"
                        placeholder="Main Category"
                        handleTextFieldChange={handleTextFieldChangeMainCategory}
                        required={isSub}
                      />
                    </Stack>
                  </Tooltip>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Description
            <Divider sx={{ mt: 1 }} />
          </Typography>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5}>
                <Typography variant="caption" sx={{ pl: 1 }}>
                  In English
                </Typography>
                <RHFEditor placeholder="Write description" name="descriptionEnglish" />
              </Stack>
            </Grid>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5} className="editor-arabic">
                <Typography variant="caption" sx={{ pl: 1 }}>
                  In Arabic
                </Typography>
                <RHFEditor name="descriptionArabic" placeholder="اكتب الوصف" />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* {!categoryId && ( */}
        <Stack
          sx={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            alignItems: 'end',
            p: 2,
            width: '100%',
            mr: 2,
            mb: 1,
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="small"
            loading={isSubmitting || loading}
            sx={{
              ml: 2,
              // width: '180px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            {categoryId ? 'Save Changes' : 'Create Category'}
          </LoadingButton>
        </Stack>
        {/* )} */}
      </FormProvider>
      <Stack sx={{ p: 2, px: 3, pt: 0, mb: 5 }}>
        <Typography variant="subtitle2" sx={{ mb: 0, pb: 2 }}>
          Image <Divider sx={{ mt: 1 }} />
        </Typography>

        <OspImageUploader
          folder="categories"
          single
          setImagesPreview={setImagePreview}
          imagesPreview={imagePreview}
        />
      </Stack>
    </Stack>
  );
}

BasicDetailsForm.propTypes = {};
