import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
// Import the user icon
import {
  Box,
  Card,
  Grid,
  Typography,
  ListItemText,
} from '@mui/material';

import { fDateTime } from 'src/utils/format-time';

import { getUserDetails } from 'src/server/api/user';

import Iconify from 'src/components/iconify';

import UserAddressDialog from './userAddressModal';

// ----------------------------------------------------------------------

export default function Profile() {
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const params = useParams();
  // const isMobile = useResponsive('sm', 'xs');

  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }));
  const [openUserAddressPopup, setOpenUserAddressPopup] = useState(false);
  const [openUserAddressId, setOpenUserAddressId] = useState({});

  const userId = params.id;

  console.log('user Details', userDetails);

  useEffect(() => {
    if (userId) {
      const credentials = {
        userId,
        dispatch,
      };
      dispatch(getUserDetails(credentials));
    }
  }, [dispatch, userId]);
  const dummyData = [
    {
      address_line_1: '123 Main Step skk null',
      address_line_2: 'Apt 4B',
      pincode: '10001',
      district: 'Manhattan',
      state: 'New York',
      country: 'USA',
      uid: 1,
      default:true,
    },
    {
      address_line_1: '456 Elm St',
      address_line_2: '',
      pincode: '20002',
      district: 'Washington',
      state: 'D.C.',
      country: 'USA',
      uid: 2,
    },
    {
      address_line_1: '789 Maple Ave',
      address_line_2: 'Suite 101',
      pincode: '94103',
      district: 'San Francisco',
      state: 'California',
      country: 'USA',
      uid: 3,
    },
    {
      address_line_1: '101 Oak Dr',
      address_line_2: 'Unit 5A',
      pincode: '60616',
      district: 'Chicago',
      state: 'Illinois',
      country: 'USA',
      uid: 4,
    },
    {
      address_line_1: '202 Pine St',
      address_line_2: '',
      pincode: '73301',
      district: 'Austin',
      state: 'Texas',
      country: 'USA',
      uid: 5,
    },
    {
      address_line_1: '303 Cedar St',
      address_line_2: '',
      pincode: '98101',
      district: 'Seattle',
      state: 'Washington',
      country: 'USA',
      uid: 6,
    },
    {
      address_line_1: '404 Birch St',
      address_line_2: 'Apt 2C',
      pincode: '02101',
      district: 'Boston',
      state: 'Massachusetts',
      country: 'USA',
      uid: 7,
    },
    {
      address_line_1: '505 Spruce Ave',
      address_line_2: '',
      pincode: '30301',
      district: 'Atlanta',
      state: 'Georgia',
      country: 'USA',
      uid: 8,
    },
  ];
  
  return (
    <>
      {openUserAddressPopup && (
        <UserAddressDialog
          isUpdate={openUserAddressId}
          setOpenUserAddressId={setOpenUserAddressId}
          setOpen={setOpenUserAddressPopup}
          open={openUserAddressPopup}
        />
      )}
      <Stack p={3} pt={0} gap={3}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 1, pb: 2 }}>
            About
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Iconify icon="iconoir:phone-solid" width={20} sx={{ mr: 2 }} />{' '}
            <Typography>{userDetails?.phone}</Typography>
          </Box>{' '}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Iconify icon="solar:hand-money-bold" width={20} sx={{ mr: 2 }} />

            <ListItemText
              sx={{
                textAlign: { xs: 'center', md: 'unset' },
              }}
              primary="Joined On"
              secondary={fDateTime(userDetails?.createdAt)}
              primaryTypographyProps={{
                typography: 'body2',
              }}
              secondaryTypographyProps={{
                mt: 0.5,
                typography: 'body2',
                color: 'black',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Iconify icon="gravity-ui:persons" width={20} sx={{ mr: 2 }} />
            <ListItemText
              sx={{
                textAlign: { xs: 'center', md: 'unset' },
              }}
              primary="Last Login"
              secondary={fDateTime(userDetails?.last_login_at)}
              primaryTypographyProps={{
                typography: 'body2',
              }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'body2',
                color: 'black',
              }}
            />{' '}
          </Box>{' '}
        </Card>
        <Stack>
          <Card sx={{p:3}}> 

          <Typography variant="h6" >
            Address 
          </Typography>
       
          <Grid container spacing={2}>
            {dummyData.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Stack
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    pl:0
              
                  }}
                >
                {data?.default ?(
                  <Typography color='#00A76F' variant='body2'>
                    (Default)
                    </Typography>
                ):(<Box sx={{height:25}}/>)}
                  <Typography variant="body1">{data.address_line_1}</Typography>
                  <Typography variant="body2">{data.address_line_2}</Typography>
                  <Typography variant="body2">{data.pincode}</Typography>
                  <Typography variant="body2">{data.district}</Typography>
                  <Typography variant="body2">{data.state}</Typography>
                  <Typography variant="body2">{data.country}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          </Card>

        </Stack>
      </Stack>
    </>
  );
}

Profile.propTypes = {};
