import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  restoreSale,
  hardDeleteSale,
  createSpecialSale,
  getAllSpecialSale,
  deleteSpecialSale,
  getSpecialSaleCounts,
  getSpecialSaleDetails,
  changeSpecialSaleStatus,
  updateSpecialSaleDetails,
  checkSpecialSaleTitleValid,
} from '../api/specialSale';

export const specialSaleSlice = createSlice({
  name: 'specialSale',
  initialState: {
    loading: false,
    error: {},
    specialSales: {},
    staffDropList: [],
    specialSaleDetails: {},
    deleteLoading: false,
    specialSaleCounts: {},
    statusLoading: false,
    isSpecialSaleTitleValid: {},
    specialSaleTitleLoading: false,
  },
  reducers: {
    clearSpecialSaleError: (state) => {
      state.error = true;
    },
    clearSpecialSaleData: (state) => {
      state.specialSaleDetails = {};
    },
  },
  extraReducers: {
    [createSpecialSale.pending]: (state) => {
      state.loading = true;
    },
    [createSpecialSale.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const newSpecialSaleList = jsonState?.specialSales;

      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: Array.isArray(newSpecialSaleList)
          ? [...newSpecialSaleList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.specialSales = modifiedSpecialSaleList;
      state.error = {};
    },
    [createSpecialSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllSpecialSale.pending]: (state) => {
      state.loading = true;
    },
    [getAllSpecialSale.fulfilled]: (state, action) => {
      state.loading = false;
      state.specialSales = action.payload;
      state.error = {};
    },
    [getAllSpecialSale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get special-sale count
    [getSpecialSaleCounts.pending]: (state) => {
      state.loading = true;
    },
    [getSpecialSaleCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.specialSaleCounts = action.payload;
      state.error = {};
    },
    [getSpecialSaleCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get special-sale status
    [changeSpecialSaleStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    // [changeSpecialSaleStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.specialSales;
    //   const modifiedSpecialSaleList = {
    //     ...jsonState,
    //     specialSales: jsonState.specialSales?.map((i) =>
    //       i.uid === action.payload.uid ? action?.payload : i
    //     ),
    //   };
    //   state.statusLoading = false;
    //   state.specialSales = modifiedSpecialSaleList;
    //   state.error = {};
    // },
    [changeSpecialSaleStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: jsonState.specialSales?.map((i) =>
          i.uid === action?.payload?.response?.uid ? action?.payload?.response : i
        ),
      };
      const modifiedSpecialSaleList2 = {
        ...jsonState,
        specialSales: jsonState?.specialSales?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.specialSales =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedSpecialSaleList2
          : modifiedSpecialSaleList;
      state.error = {};
    },
    [changeSpecialSaleStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // check special-sale title
    [checkSpecialSaleTitleValid.pending]: (state) => {
      state.specialSaleTitleLoading = true;
    },
    [checkSpecialSaleTitleValid.fulfilled]: (state, action) => {
      state.specialSaleTitleLoading = false;
      state.isSpecialSaleTitleValid = action.payload;
      state.error = {};
    },
    [checkSpecialSaleTitleValid.rejected]: (state, action) => {
      state.specialSaleTitleLoading = false;
      state.error = action.error;
    },

    [getSpecialSaleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSpecialSaleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.specialSaleDetails = action.payload;
      state.error = {};
    },
    [getSpecialSaleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateSpecialSaleDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSpecialSaleDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: jsonState.specialSales?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };
      state.loading = false;
      state.specialSales = modifiedSpecialSaleList;
      state.error = {};
    },

    [updateSpecialSaleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSpecialSale.pending]: (state) => {
      state.statusLoading = true;
    },
    [deleteSpecialSale.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: jsonState.specialSales?.filter(
          (specialSale) => specialSale.uid !== action.payload
        ),
      };
      state.statusLoading = false;
      state.specialSales = modifiedSpecialSaleList;
      state.error = {};
    },
    [deleteSpecialSale.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // hard delete special sale
    [hardDeleteSale.pending]: (state) => {
      state.deleteLoading = true;
    },
    [hardDeleteSale.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: jsonState.specialSales?.filter(
          (specialSale) => specialSale.uid !== action.payload
        ),
      };

      state.deleteLoading = false;
      state.specialSales = modifiedSpecialSaleList;
      state.error = {};
    },
    [hardDeleteSale.rejected]: (state, action) => {
      state.deleteLoading = false;
      state.error = action.error;
    },

    // restore special sale
    [restoreSale.pending]: (state) => {
      state.statusLoading = true;
    },
    [restoreSale.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.specialSales;
      const modifiedSpecialSaleList = {
        ...jsonState,
        specialSales: jsonState.specialSales?.filter(
          (specialSale) => specialSale.uid !== action.payload
        ),
      };

      state.statusLoading = false;
      state.specialSales = modifiedSpecialSaleList;
      state.error = {};
    },
    [restoreSale.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearSpecialSaleError, clearSpecialSaleData } = specialSaleSlice.actions;

export default specialSaleSlice.reducer;
