
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { getAllCategory } from 'src/server/api/category';

// components
import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import UserOrderTableRow from './UserOrderTableRow';

// sections

export default function UserOrderList() {
  const dispatch = useDispatch();
  
  // const isMobile = useResponsive('sm', 'xs');
  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { userOrderItems, categories,loading } = useSelector(
    (state) => ({
      userOrderItems: state.user.userOrderItems,
      loading: state.user.loading,
      categories: state.category.categories,

    })
  );

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const limit = 20;
  const total = userOrderItems?.total;

  const TABLE_HEAD = [
    { id: 'order-id', label: 'Order ID' },
    { id: 'date', label: 'Date' },
    { id: 'status', label: 'Status' },
    { id: 'items', label: 'Items' },
    { id: 'payment-type', label: 'Payment Type' },
    { id: 'sub-total', label: 'Sub Total' },
    { id: 'shipping', label: 'Shipping' },
    { id: 'coupon', label: 'Coupon' },
    { id: 'total', label: 'Total' },

  ];

  useEffect(() => {
    setTableData(categories?.categories);
  }, [categories]);

  useEffect(() => {
    const filterSortObj = {
      filter_data: {    is_deleted: false,
      },
      sort_data: { priority: 1 },
    };
    const credentials = {
      page: table.page + 1,
      search: '',
      dispatch,
      limit: table.rowsPerPage || limit,
      state: filterSortObj,
    };
    dispatch(getAllCategory(credentials));
  }, [dispatch, table.page, table.rowsPerPage]);


  return (
    <Card sx={{borderRadius:'0px'}}> 
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
     
        <Scrollbar>
      
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={table.selected?.length}
              onSort={table.onSort}
            />

            <TableBody>
              {loading ? (
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress size="20px" color="inherit" />
                  </Paper>
                </TableCell>
              ) : (
                <>
                  {(tableData || []).map((row, index) => (
                    <UserOrderTableRow
                      index={index}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      loading={loading}
                     
                    />
                  ))}
                </>
              )}
            </TableBody>

            {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={total || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
