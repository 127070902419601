import * as Yup from 'yup';
import moment from 'moment';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import GradeIcon from '@mui/icons-material/Grade';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Divider, MenuItem } from '@mui/material';
// Import the user icon
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDate } from 'src/utils/format-time';

import { getAllUsers } from 'src/server/api/user';
import { getAllBrands } from 'src/server/api/brand';
import { getAllProduct } from 'src/server/api/product';
import { getAllCategory } from 'src/server/api/category';
import { getAllPickupLocations } from 'src/server/api/pickupLocation';
import { createCoupon, getCouponDetails, updateCouponDetails } from 'src/server/api/coupon';

import OspSelectAutocomplete from 'src/components/hook-form/osp-select';
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFRadioGroup,
} from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function BasicDetailsForm() {
  const router = useRouter();
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const params = useParams();
  const isMobile = useResponsive('sm', 'xs');
  const theme = useTheme();

  const {
    loading,
    couponDetails,
    brandList,
    productList,
    categoryList,
    userList,
    PickupLocationList,
    credLoading,
  } = useSelector((state) => ({
    loading: state.coupon.loading,
    couponDetails: state.coupon.couponDetails,
    error: state.coupon.error,
    categoryList: state.category.categories,
    credLoading: state.coupon.credLoading,
    brandList: state.brand.brands,
    productList: state.product.products,
    userList: state.user.users,
    PickupLocationList: state.pickupLocation.pickupLocations,
  }));
  const couponId = params.id;

  useEffect(() => {
    if (couponId) {
      const credentials = {
        couponId,
        dispatch,
      };
      dispatch(getCouponDetails(credentials));
    }
  }, [dispatch, couponId]);

  const CouponSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string().required('Name in English is required'),
      arabic: Yup.string(),
    }),
    description: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    code: Yup.string().required('Coupon Code is required'),
    per_user_limit: Yup.number(),
    total_user_limit: Yup.number(),
    payment_type: Yup.string(),
    duration: Yup.string(),
    type: Yup.string(),
    min_order_value: Yup.number(),
    max_value: Yup.number(),
    is_default: Yup.boolean(),
    default_option: Yup.string(),
    category: Yup.string(),
    categories: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    brands: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    products: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    pickup_locations: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    whitelist_users: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    start_date: Yup.string(),
    end_date: Yup.string(),
    percentage: Yup.number(),
    value: Yup.number(),
  });

  // console.log(couponDetails?.main_category);
  const defaultValues = useMemo(() => {
    if (couponId) {
      return {
        title: {
          english: couponDetails?.title?.english || '',
          arabic: couponDetails?.title?.arabic || '',
        },

        description: {
          english: couponDetails?.description?.english || '',
          arabic: couponDetails?.description?.arabic || '',
        },
        code: couponDetails?.code || '',
        per_user_limit: couponDetails?.per_user_limit || 0,
        total_user_limit: couponDetails?.total_user_limit || 0,
        payment_type: couponDetails?.payment_type || 'BOTH',
        duration: couponDetails?.duration || 'FULL_TIME',
        type: couponDetails?.type || 'PERCENTAGE',
        min_order_value: couponDetails?.min_order_value || 0,
        max_value: couponDetails?.max_value || 0,
        is_default: couponDetails?.is_default,
        default_option: couponDetails?.default_option,
        category: couponDetails?.category || 'COMMON',
        start_date: couponDetails?.start_date
          ? moment(couponDetails.start_date).format('YYYY-MM-DD')
          : '',
        end_date: couponDetails?.end_date
          ? moment(couponDetails.end_date).format('YYYY-MM-DD')
          : '',
        percentage: couponDetails?.percentage || 0,
        value: couponDetails?.value || 0,
      };
    }
    return {
      title: {
        english: '',
        arabic: '',
      },
      description: {
        english: '',
        arabic: '',
      },
      code: '',
      per_user_limit: 0,
      total_user_limit: 0,
      payment_type: 'BOTH',
      duration: 'FULL_TIME',
      type: 'PERCENTAGE',
      min_order_value: 0,
      max_value: 0,
      is_default: false,
      default_option: '',
      category: 'COMMON',
      categories: [],
      brands: [],
      products: [],
      pickup_locations: [],
      whitelist_users: [],
      start_date: '',
      end_date: '',
      percentage: 0,
      value: 0,
    };
  }, [
    couponId,
    couponDetails?.title?.english,
    couponDetails?.title?.arabic,
    couponDetails?.description?.english,
    couponDetails?.description?.arabic,
    couponDetails?.code,
    couponDetails?.per_user_limit,
    couponDetails?.total_user_limit,
    couponDetails?.payment_type,
    couponDetails?.duration,
    couponDetails?.type,
    couponDetails?.min_order_value,
    couponDetails?.max_value,
    couponDetails?.is_default,
    couponDetails?.default_option,
    couponDetails?.category,
    couponDetails.start_date,
    couponDetails.end_date,
    couponDetails?.percentage,
    couponDetails?.value,
  ]);

  const methods = useForm({
    resolver: yupResolver(CouponSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  console.log('errors', errors);
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const createState = {
      ...data,
      categories: data?.categories?.map((i) => i.value),
      products: data?.products?.map((i) => i.value),
      brands: data?.brands?.map((i) => i.value),
      pickup_locations: data?.pickup_locations?.map((i) => i.value),
      whitelist_users: data?.whitelist_users?.map((i) => i.value),
    };

    if (!data.total_user_limit) {
      delete createState.total_user_limit;
    }
    if (!data.per_user_limit) {
      delete createState.per_user_limit;
    }
    if (!data.start_date) {
      delete createState.start_date;
    }
    if (!data.end_date) {
      delete createState.end_date;
    }
    if (!data.default_option) {
      delete createState.default_option;
    }
    const createCredentials = {
      state: createState,
      dispatch,
      router,
    };

    // update functions
    const updateState = {
      ...data,
      categories: data?.categories?.map((i) => i.value),
      products: data?.products?.map((i) => i.value),
      brands: data?.brands?.map((i) => i.value),
      pickup_locations: data?.pickup_locations?.map((i) => i.value),
      whitelist_users: data?.whitelist_users?.map((i) => i.value),
    };

    const updateCredentials = {
      state: updateState,
      couponId: couponDetails?.uid,

      dispatch,
    };

    if (couponId) {
      dispatch(updateCouponDetails(updateCredentials));
    } else {
      dispatch(createCoupon(createCredentials));
    }
  });

  const PaymentTypeOptions = [
    { label: 'Both', value: 'BOTH' },
    { label: 'Online', value: 'ONLINE' },
    { label: 'COD', value: 'COD' },
  ];
  const DurationOptions = [
    { label: 'Full time', value: 'FULL_TIME' },
    { label: 'Duration', value: 'DURATION' },
    { label: 'Start time', value: 'START_TIME' },
  ];
  const TYPE_OPTIONS = [
    { value: 'PERCENTAGE', label: 'Percentage' },
    { value: 'VALUE', label: 'Value' },
  ];
  const CouponCategoryOptions = [
    { label: 'Common', value: 'COMMON' },
    { label: 'Category', value: 'CATEGORY' },
    { label: 'Product', value: 'PRODUCT' },
    { label: 'Brand', value: 'BRAND' },
  ];

  const DefaultOptions = [{ label: 'First Order', value: 'FIRST_ORDER' }];
  const Duration = watch('duration');

  const IsDefault = watch('is_default');
  const Type = watch('type');
  const Category = watch('category');

  const [searchValueCategory, setSearchValueCategory] = useState('');

  const handleTextFieldChangeCategory = (value) => {
    setSearchValueCategory(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };

    const credentials = {
      page: 1,
      search: searchValueCategory,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };
    dispatch(getAllCategory(credentials));
  }, [dispatch, searchValueCategory]);

  const categoryOptions = [
    ...(categoryList?.categories || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const defaultCategory = useMemo(
    () =>
      couponDetails?.categories?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [couponDetails]
  );
  // ////// brand  select start ////////

  const [searchValueBrand, setSearchValueBrand] = useState('');

  const handleTextFieldChangeBrand = (value) => {
    setSearchValueBrand(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueBrand,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };

    dispatch(getAllBrands(credentials));
  }, [dispatch, searchValueBrand]);

  const brandOptions = [
    ...(brandList?.brands || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const defaultBrand = useMemo(
    () =>
      couponDetails?.brands?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [couponDetails]
  );
  // ////// product  select start ////////

  const [searchValueProduct, setSearchValueProduct] = useState('');

  const handleTextFieldChangeProduct = (value) => {
    setSearchValueProduct(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueProduct,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };

    dispatch(getAllProduct(credentials));
  }, [dispatch, searchValueProduct]);

  const productOptions = [
    ...(productList?.products || []).map((results) => ({
      label: results?.name?.english,
      value: results?.uid,
    })),
  ];

  const defaultProduct = useMemo(
    () =>
      couponDetails?.products?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [couponDetails]
  );
  // ////// Users  select start ////////

  const [searchValueUser, setSearchValueUser] = useState('');

  const handleTextFieldChangeUser = (value) => {
    setSearchValueUser(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValueUser,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };

    dispatch(getAllUsers(credentials));
  }, [dispatch, searchValueUser]);
  const userOptions = [
    ...(userList?.users || []).map((results) => ({
      label: results?.name,
      value: results?.uid,
    })),
  ];

  const defaultUser = useMemo(
    () =>
      couponDetails?.whitelist_users?.length
        ? couponDetails.whitelist_users.map((item) => ({
            label: item?.name || '',
            value: item?.uid || '',
          }))
        : [],
    [couponDetails]
  );

  // ////// PickupLOcations  select start ////////

  const [searchValuePickupLocation, setSearchValuePickupLocation] = useState('');

  const handleTextFieldChangePickupLocation = (value) => {
    setSearchValuePickupLocation(value);
  };

  useEffect(() => {
    const filterDataObj = {
      filter_data: {
        is_deleted: false,
      },
    };
    const credentials = {
      page: 1,
      search: searchValuePickupLocation,
      dispatch,
      limit: 20,
      state: filterDataObj,
    };

    dispatch(getAllPickupLocations(credentials));
  }, [dispatch, searchValuePickupLocation]);
  const PickupLocationOptions = [
    ...(PickupLocationList?.pickupLocations || []).map((results) => ({
      label: results?.title?.english,
      value: results?.uid,
    })),
  ];

  const defaultPickupLocation = useMemo(
    () =>
      couponDetails?.pickup_locations?.map((item) => ({
        label: item?.title?.english || '',
        value: item?.uid || '',
      })) || [],
    [couponDetails]
  );

  return (
    <Stack sx={{ position: 'relative' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} md={12}>
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="flex-end"
              px={2}
              my={2}
            >
              {couponId && (
                <Stack alignItems="end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    loading={isSubmitting || credLoading}
                    sx={{
                      // width: '140px',
                      backgroundColor: '#106E58',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#0b4e3e',
                      },
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
            {couponId && (
              <Stack my={2} mx={isMobile ? 5 : 20}>
                <Stack direction={isMobile ? 'column' : 'row'} justifyContent="center">
                  <Stack
                    flex={2}
                    gap={1}
                    py={0.5}
                    px={3}
                    backgroundColor="#00A76F"
                    position="relative"
                    color="white"
                    justifyContent="center"
                    // alignItems="center"
                    textAlign="center"
                    sx={{
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: '-.5px',
                        height: '94%',
                        width: '1px',
                        borderRight: '1px dashed #00A76F',
                        my: 0.5,
                      },
                    }}
                  >
                    <Typography fontWeight="bold">{couponDetails?.code}</Typography>
                    {couponDetails?.type === 'PERCENTAGE' && (
                      <Stack alignItems="center">
                        <Typography variant="body1" fontWeight="bold">
                          {couponDetails?.percentage} % off
                        </Typography>
                        <Typography fontSize="9px"> Upto {couponDetails?.max_value} OMR</Typography>
                      </Stack>
                    )}
                    <Stack direction="row" justifyContent="center">
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                      <GradeIcon sx={{ fontSize: '7px' }} />
                    </Stack>
                  </Stack>
                  <Stack
                    flex={8}
                    gap={2.1}
                    border="1px solid #00A76F"
                    sx={{
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      borderLeft: isMobile ? '' : 'none',
                    }}
                    my={1}
                    pl={4}
                    // pr={20}
                    py={0.5}
                  >
                    <Typography color="#00A76F" variant="h4">
                      {couponDetails?.title?.english}{' '}
                    </Typography>
                    <Stack gap={0.8}>
                      <Typography fontSize="12px">
                        Total User Limit :{couponDetails?.per_user_limit}
                      </Typography>
                      <Typography fontSize="12px">
                        Per User Limit :{couponDetails?.total_user_limit}
                      </Typography>
                      {couponDetails?.duration === 'DURATION' && (
                        <Typography fontSize="12px">
                          From:{' '}
                          <Box component="span" sx={{ marginX: 1 }}>
                            {fDate(couponDetails?.start_date)}
                          </Box>
                          To:{' '}
                          <Box component="span" sx={{ marginLeft: 1 }}>
                            {fDate(couponDetails?.end_date)}
                          </Box>
                        </Typography>
                      )}
                      {couponDetails?.duration === 'START_TIME' && (
                        <Typography fontSize="12px">
                          From:{' '}
                          <Box component="span" sx={{ marginX: 1 }}>
                            {fDate(couponDetails?.start_date)}
                          </Box>
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack sx={{ p: 2, pt: 0 }}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                Title
                <Divider sx={{ mt: 1 }} />
              </Typography>
              <Grid container maxWidth="xl" spacing={2}>
                <Grid xs={12} md={6} item>
                  <RHFTextField
                    name="title.english"
                    label="English "
                    type="string"
                    required
                    size="small"
                    placeholder="English "
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Stack
                    className={
                      theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'
                    }
                    width="100%"
                  >
                    <RHFTextField
                      name="title.arabic"
                      label="Arabic "
                      type="string"
                      size="small"
                      placeholder="Arabic "
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Description
            <Divider sx={{ mt: 1 }} />
          </Typography>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5}>
                <RHFTextField
                  name="description.english"
                  label="English"
                  type="string"
                  size="small"
                  placeholder="English Description"
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
            <Grid xs={12} md={6} item>
              <Stack
                className={theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}
                width="100%"
              >
                {' '}
                <RHFTextField
                  name="description.arabic"
                  label="Arabic"
                  type="string"
                  size="small"
                  placeholder="Arabic Description"
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="code"
                label="Coupon Code"
                required
                type="string"
                size="small"
                placeholder="Enter Code"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="total_user_limit"
                label="Total User Limit"
                type="number"
                size="small"
                placeholder="Enter Limit"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="per_user_limit"
                label="Per User Limit"
                type="number"
                size="small"
                placeholder="Enter Limit"
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={4} item>
              <RHFSelect name="duration" label="Duration" size="small">
                {DurationOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {Duration === 'DURATION' && (
              <>
                <Grid xs={12} md={4} item>
                  <RHFTextField
                    name="start_date"
                    label="Start Date"
                    type="date"
                    placeholder="Start Date"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid xs={12} md={4} item>
                  <RHFTextField
                    name="end_date"
                    label="End Date"
                    type="date"
                    placeholder="End Date"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </>
            )}
            {Duration === 'START_TIME' && (
              <Grid xs={12} md={4} item>
                <RHFTextField
                  name="start_date"
                  label="Start Date"
                  type="date"
                  placeholder="Start Date"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={4} item>
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="subtitle2" mr={2}>
                  Type
                </Typography>
                <RHFRadioGroup row spacing={1} name="type" color="success" options={TYPE_OPTIONS} />
              </Stack>
            </Grid>
            {Type === 'PERCENTAGE' && (
              <Grid xs={12} md={4} item>
                <RHFTextField
                  name="percentage"
                  label="Percentage"
                  type="number"
                  size="small"
                  placeholder="Percentage"
                  required={Type === 'PERCENTAGE'}
                />
              </Grid>
            )}
            {Type === 'VALUE' && (
              <Grid xs={12} md={4} item>
                <RHFTextField
                  name="value"
                  label="Value"
                  type="number"
                  size="small"
                  placeholder="Value"
                  required={Type === 'VALUE'}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={4} item>
              <RHFSelect name="payment_type" label="Payment Type" size="small">
                {PaymentTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid xs={12} md={4} item>
              <RHFTextField
                name="min_order_value"
                label="Minimum Order Value"
                type="number"
                size="small"
                placeholder="Minimum Order Value"
              />
            </Grid>
            {Type === 'PERCENTAGE' && (
              <Grid xs={12} md={4} item>
                <RHFTextField
                  name="max_value"
                  label="Maximum Value"
                  type="number"
                  size="small"
                  placeholder="Maximum Value"
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={3} item>
              <RHFSwitch name="is_default" color="secondary" label="is Default" />
            </Grid>
            {IsDefault && (
              <Grid xs={12} md={4} item>
                <RHFSelect name="default_option" label="Choose Option" size="small">
                  {DefaultOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            )}
          </Grid>
        </Stack>
        <Stack
          alignItems="left"
          flexDirection="row"
          justifyContent="left"
          flex={6}
          sx={{ p: 3, pt: 0, width: '100%' }}
        >
          <ErrorOutlineIcon sx={{ marginRight: '5px', color: '#697077', fontSize: '25px' }} />
          <Typography
            variant="caption"
            display="flex"
            color="#697077"
            justifyContent="center"
            alignItems="center"
          >
            If this coupon is default, it gets automatically applied according to the set default
            option
          </Typography>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            <Divider sx={{ mt: 1 }} />
          </Typography>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={3} item>
              <RHFSelect name="category" label="Coupon Category" size="small">
                {CouponCategoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {Category === 'CATEGORY' && (
              <Grid xs={12} md={9} item className="coupon-category">
                <OspSelectAutocomplete
                  name="categories"
                  control={control}
                  defaultValue={defaultCategory || []}
                  options={categoryOptions}
                  multiple
                  label="Categories"
                  handleTextFieldChange={handleTextFieldChangeCategory}
                />
              </Grid>
            )}
            {Category === 'BRAND' && (
              <Grid xs={12} md={9} item className="coupon-category">
                <OspSelectAutocomplete
                  name="brands"
                  control={control}
                  options={brandOptions}
                  defaultValue={defaultBrand || []}
                  multiple
                  label="Brands"
                  handleTextFieldChange={handleTextFieldChangeBrand}
                />
              </Grid>
            )}
            {Category === 'PRODUCT' && (
              <Grid xs={12} md={9} item className="coupon-category">
                <OspSelectAutocomplete
                  name="products"
                  control={control}
                  options={productOptions}
                  defaultValue={defaultProduct || []}
                  multiple
                  label="Products"
                  handleTextFieldChange={handleTextFieldChangeProduct}
                />
              </Grid>
            )}
            {!loading && (
              <Grid xs={12} md={12} item>
                <OspSelectAutocomplete
                  name="pickup_locations"
                  control={control}
                  options={PickupLocationOptions}
                  defaultValue={defaultPickupLocation || []}
                  multiple
                  label="Pickup Locations"
                  handleTextFieldChange={handleTextFieldChangePickupLocation}
                />
              </Grid>
            )}

            {!loading && (
              <Grid xs={12} md={12} item>
                <OspSelectAutocomplete
                  name="whitelist_users"
                  control={control}
                  options={userOptions}
                  defaultValue={defaultUser || []}
                  // defaultValue={defaultUser}
                  multiple
                  label="Whitelist Users"
                  handleTextFieldChange={handleTextFieldChangeUser}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        {/* {!couponId && ( */}
        <Stack
          sx={{
            // position: 'absolute',
            // bottom: 0,
            display: 'flex',
            alignItems: 'end',
            p: 2,
            width: '100%',
            mr: 2,
            mb: 1,
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="small"
            loading={isSubmitting || loading}
            sx={{
              ml: 2,
              // width: '180px',
              backgroundColor: '#106E58',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0b4e3e',
              },
            }}
          >
            {couponId ? 'Save Changes' : 'Create Coupon'}
          </LoadingButton>
        </Stack>
        {/* )} */}
      </FormProvider>
    </Stack>
  );
}

BasicDetailsForm.propTypes = {};
