import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BrandList from './sections/BrandList';

export default function Brand() {
  return (
    <>
      <MetaHelmet title="Brand" />
      <BrandList />
    </>
  );
}
