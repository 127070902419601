import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, Grid, Stack, Divider, Typography } from '@mui/material';

import { checkSapIdValid, checkProductNameValid } from 'src/server/api/product';

import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import RHFAutocompleteNew from 'src/components/hook-form/rhf-autocomplete-new';

export const formatUrl = (name) => {
  if (!name) return '';
  return name
    .trim()
    .replace(/[@,#,!,+,$,%,&,*,(,),+,=,?,>,<,",:,;,[\]{}|\\/]/g, '')
    .replace(/\s+/g, '-')
    .replace(/\s+/g, '')
    .toLowerCase();
};

export const unFormatUrl = (url) => {
  if (!url) return '';

  return url
    .replace(/-/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0) + word.slice(1))
    .join(' ');
};

function BasicDetails({ watch, setValue, handleUrlManuallyChange }) {
  const dispatch = useDispatch();

  // const _tags = [
  //   'Technology',
  //   'Marketing',
  //   'Design',
  //   'Photography',
  //   'Art',
  //   'Fashion',
  //   'Food',
  //   'Travel',
  //   'Fitness',
  //   'Nature',
  //   'Business',
  //   'Music',
  //   'Health',
  //   'Books',
  //   'Sports',
  //   'Film',
  //   'Education',
  //   'Motivation',
  //   'Gaming',
  //   'Pets',
  //   'Cooking',
  //   'Finance',
  //   'Selfcare',
  //   'Writing',
  // ];

  const { isSapIdValid, isProductValid, productDetails } = useSelector((state) => ({
    isSapIdValid: state.product.isSapIdValid,
    isProductValid: state.product.isProductValid,
    sapLoading: state.product.sapLoading,
    prodNameLoading: state.product.prodNameLoading,
    loading: state.product.loading,
    productDetails: state.product.productDetails,
  }));

  // check sap id
  const sap_id = watch('sap_id');

  useEffect(() => {
    if (sap_id && sap_id !== productDetails?.sap_id) {
      const createCredentials = {
        state: {
          sap_id,
        },
        dispatch,
      };
      dispatch(checkSapIdValid(createCredentials));
    }
  }, [dispatch, productDetails?.sap_id, sap_id]);

  // check product name
  const productName = watch('name.english');

  useEffect(() => {
    if (productName && productName !== productDetails?.name?.english) {
      const createCredentials = {
        state: {
          english_name: productName,
        },
        dispatch,
      };
      dispatch(checkProductNameValid(createCredentials));
    }
  }, [dispatch, productDetails?.name?.english, productName]);

  useEffect(() => {
    const formattedUrl = formatUrl(productName);
    const productUrl = watch('url');
    const finalUrl = formattedUrl;

    if (productUrl) {
      setValue('url', productUrl);
    } else if (formattedUrl) {
      setValue('url', finalUrl);
    }
  }, [productName, setValue, watch]);

  useEffect(() => {
    if (productName) {
      const formattedUrl = formatUrl(productName);
      if (formattedUrl) {
        setValue('url', formattedUrl);
      }
    }
  }, [productName, setValue]);

  const productUrl = watch('url');
  // console.log(productUrl);

  useEffect(() => {
    const formattedUrl = formatUrl(productUrl);
    setValue('url', formattedUrl);
  }, [productUrl, setValue]);

  return (
    <Stack spacing={3} sx={{ p: 2, mt: 1 }}>
      <Stack direction="row" alignItems="center">
        <Stack sx={{ width: '400px' }}>
          <RHFTextField
            name="sap_id"
            label="SAP ID"
            type="string"
            placeholder="SAP ID"
            size="small"
            required
            isValidationRequired={sap_id && sap_id !== productDetails?.sap_id ? !!sap_id : false}
            isValid={isSapIdValid?.sap_id}
            validationMsg="SAP ID already used"
          />
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%',
            mx: 3,
          }}
        >
          <RHFSwitch name="is_active" label="Active" />
          <RHFSwitch name="type" label="Special Product" sx={{ mx: 2, mr: 4 }} />
          <RHFSwitch name="has_variants" label="Has Variants" />
        </Stack>
      </Stack>

      <Typography variant="subtitle2" sx={{ mb: 0 }}>
        Product Name
        <Divider sx={{ mt: 1 }} />
      </Typography>
      <Grid container maxWidth="xl" spacing={2}>
        <Grid xs={12} md={6} item>
          <RHFTextField
            name="name.english"
            label="English Name"
            type="string"
            placeholder="English Name"
            size="small"
            required
            isValidationRequired={
              productName && productName !== productDetails?.name?.english ? !!productName : false
            }
            isValid={isProductValid?.english_name}
            validationMsg="Duplicate english name!"
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <Stack className="input-arabic">
            <RHFTextField
              name="name.arabic"
              label="Arabic Name"
              type="string"
              size="small"
              placeholder="Arabic Name"
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack>
        <RHFTextField
          name="url"
          label="Product URL"
          type="string"
          placeholder="Product URL"
          size="small"
          // onChangeInput={handleUrlManuallyChange}
        />
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Description
          <Divider sx={{ mt: 1 }} />
        </Typography>
        <Grid container maxWidth="xl" spacing={2}>
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5}>
              <Typography variant="caption">In English</Typography>
              <RHFEditor placeholder="Write description" name="descriptionEnglish" />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack spacing={1.5} className="editor-arabic">
              <Typography variant="caption">In Arabic</Typography>
              <RHFEditor name="descriptionArabic" placeholder="Write description" />
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <RHFAutocompleteNew
        name="search_keywords"
        label="Search Keywords"
        placeholder="+ Type keywords and enter"
        multiple
        freeSolo
        size="small"
        // options={_tags.map((option) => option)}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={option}
              size="small"
              color="info"
              variant="soft"
            />
          ))
        }
      />
    </Stack>
  );
}

export default BasicDetails;

BasicDetails.propTypes = {
  watch: PropTypes.any,
  setValue: PropTypes.any,
  handleUrlManuallyChange: PropTypes.any,
};
