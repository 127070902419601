import { debounce } from 'lodash';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
import {
  Tab,
  Card,
  Grid,
  Chip,
  Tabs,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { clearUserDetailsState } from 'src/server/store/user';
import { getAllUsers, getUserCounts, changeUserStatus } from 'src/server/api/user';

import Iconify from 'src/components/iconify';
import Label from 'src/components/label/label';
// components
import Scrollbar from 'src/components/scrollbar';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import UserTableRow from './UserTableRow';
// sections

export default function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const router = useRouter();
  const location = useLocation();
  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { users, loading, userCounts, statusLoading } = useSelector((state) => ({
    users: state.user.users,
    loading: state.user.loading,
    userCounts: state.user.userCounts,
    statusLoading: state.user.statusLoading,
  }));
  const theme = useTheme();

  const [searchText, setSearchText] = useState();
  const [finalSearchText, setFinalSearchText] = useState();
  const [filterData, setFilterData] = useState({
    is_deleted: false,
    is_guest: false,
    role: 'USER',
  });

  console.log(finalSearchText);

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const limit = 20;
  const total = users?.total;

  const TABLE_HEAD = [
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'loyalty_points', label: 'Loyalty Points' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
  ];

  useEffect(() => {
    setTableData(users?.users);
  }, [users]);

  useEffect(() => {
    const filterDataObj = {
      filter_data: filterData,
    };
    const credentials = {
      page: table.page + 1,
      search: finalSearchText,
      dispatch,
      limit: table.rowsPerPage || limit,
      state: filterDataObj,
    };

    dispatch(getAllUsers(credentials));
  }, [dispatch, filterData, finalSearchText, table.page, table.rowsPerPage]);

  const handleViewRow = (id) => {
    dispatch(clearUserDetailsState());

    navigate(paths.dashboard.inventory.user_view(id));
  };

  const debouncedSetDebouncedText = debounce((value) => setFinalSearchText(value), 1000);

  const handleChangeSearchText = (e) => {
    debouncedSetDebouncedText(e.target.value);
    setSearchText(e.target.value);
    table.setPage(0);
    navigate(
      {
        pathname: location.pathname,
        search: `?p=${0}&L=${table.rowsPerPage}`,
      },
      {
        state: {
          pageValue: 0,
          limit: table.rowsPerPage,
        },
      }
    );
  };

  useEffect(() => {
    const filterDataObj = {
      count_list: [
        {
          title: 'all_user_count',
          filter_data: {
            is_deleted: false,
            is_guest: false,
            role: 'USER',
          },
        },
        {
          title: 'all_guest_user_count',
          filter_data: {
            is_deleted: false,
            is_guest: true,
            role: 'USER',
          },
        },
        {
          title: 'all_admin_user_count',
          filter_data: {
            is_deleted: false,
            role: 'ADMIN',
          },
        },
        {
          title: 'all_inactive_user_count',
          filter_data: {
            is_deleted: false,
            is_active: false,
            role: 'USER',
          },
        },

        // {
        //   title: 'all_out_of_stock_category_count',
        //   filter_data: {
        //     is_deleted: false,
        //     stock: {
        //       $lte: 0,
        //     },
        //   },
        // },
      ],
    };
    const credentials = {
      dispatch,
      state: filterDataObj,
    };
    dispatch(getUserCounts(credentials));
  }, [dispatch, users]);

  const TABS = [
    { value: 'users', label: 'Users', color: 'default', count: userCounts?.all_user_count },

    {
      value: 'guest',
      label: 'Guest',
      color: 'default',
      count: userCounts?.all_guest_user_count,
    },
    {
      value: 'admin',
      label: 'Admin',
      color: 'default',
      count: userCounts?.all_admin_user_count,
    },
    {
      value: 'inactive',
      label: 'Inactive',
      color: 'info',
      count: userCounts?.all_inactive_user_count,
    },
  ];

  const [tab, setTab] = useState('users');

  const handleFilterStatus = (e, value) => {
    setTab(value);
    switch (value) {
      case 'users':
        setFilterData({
          is_deleted: false,
          is_guest: false,
          role: 'USER',
        });
        break;

      case 'inactive':
        setFilterData({
          is_deleted: false,
          is_active: false,
          role: 'USER',
        });
        break;

      case 'guest':
        setFilterData({
          is_deleted: false,
          is_guest: true,
          role: 'USER',
        });
        break;

      case 'admin':
        setFilterData({
          is_deleted: false,
          role: 'ADMIN',
        });
        break;
      default:
        setFilterData({
          is_deleted: true,
        });
        break;
    }
    table.setPage(0);
    navigate(
      {
        pathname: location.pathname,
        search: `?p=${0}&L=${table.rowsPerPage}`,
      },
      {
        state: {
          pageValue: 0,
          limit: table.rowsPerPage,
        },
      }
    );
  };
  const handleChangeStatus = (uid, value) => {
    const credentials = {
      userId: uid,
      state: { is_active: value },
      tab,
      dispatch,
    };
    dispatch(changeUserStatus(credentials));
  };
  return (
    <Card>
      {/* <CategoryFilterDrawer open={openDrawer} setOpen={setOpenDrawer} /> */}
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Stack px={3} p={2}>
          <CustomBreadcrumbs
            isMinimal
            action={
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className="product-tap-container"
                  gap={5}
                >
                  <Tabs
                    value={tab}
                    onChange={handleFilterStatus}
                    sx={{
                      px: 2,
                      backgroundColor: theme?.palette?.mode === 'dark' ? '' : '#F4F6F8',
                      borderRadius: '6px',
                      // boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                    }}
                  >
                    {TABS.map((tabItem) => (
                      <Tab
                        key={tabItem.value}
                        value={tabItem.value}
                        label={tabItem.label}
                        iconPosition="end"
                        icon={
                          <>
                            <Label
                              variant={(tabItem.value === tab && 'filled') || 'soft'}
                              color={tabItem.color}
                              sx={{ ml: 1 }}
                            >
                              {tabItem.count || 0}
                            </Label>

                            {tabItem.value === tab && (
                              <Chip
                                sx={{
                                  backgroundColor:
                                    theme?.palette?.mode === 'dark' ? 'grey' : '#fff',
                                  position: 'absolute',
                                  zIndex: -1,
                                  width: '100%',
                                  top: '8px',
                                  '&:hover': {
                                    backgroundColor:
                                      theme?.palette?.mode === 'dark' ? '#232324' : '#e1eaf1',
                                  },
                                }}
                              />
                            )}
                          </>
                        }
                        sx={{
                          position: 'relative',
                          zIndex: 1,
                          overflow: 'inherit',
                          width: 'auto',
                          padding: '0 15px',
                        }}
                      />
                    ))}
                  </Tabs>
                </Stack>

                <Grid container spacing={1}>
                  <Grid item md={9}>
                    <TextField
                      fullWidth
                      value={searchText}
                      // className="custom-search-input-new"
                      onChange={handleChangeSearchText}
                      placeholder="Search User"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        mr: 1,
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            }
            sx={{
              mb: { xs: 1, md: 1 },
            }}
          />
        </Stack>

        <Scrollbar>
          {statusLoading && (
            <Stack sx={{ mb: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <LoadingScreen maxWidth="100%" />
            </Stack>
          )}
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={table.selected?.length}
              onSort={table.onSort}
            />

            <TableBody>
              {loading ? (
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress size="20px" color="inherit" />
                  </Paper>
                </TableCell>
              ) : (
                <>
                  {(tableData || []).map((row, index) => (
                    <UserTableRow
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.uid)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      onChangeStatus={handleChangeStatus}
                      tab={tab}
                    />
                  ))}
                </>
              )}
            </TableBody>

            {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={total || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
