import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import CollectionList from './collectionList';

export default function Collection() {
  return (
    <>
      <MetaHelmet title="Collection" />

      <CollectionList />
    </>
  );
}
