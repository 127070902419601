import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken } from 'src/hooks/useHandleSessions';

import { get, post, patch } from '../http';
import { activeSnack } from '../store/common';

export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  async ({ state, enqueueSnackbar, handleRoles }) => {
    try {
      const response = await post(`/forgot`, state);
      if (response) {
        enqueueSnackbar('Email Sent Successfully!');
        if (handleRoles) {
          localStorage.setItem('userRole', handleRoles());
        }
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const adminLoginApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('auth/admin/login', state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
        useSetToken(response?.token);
        useSetRole('admin');
        navigate(paths.dashboard.inventory.root, { replace: true });
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      // navigate(paths.public.landing, { replace: true });
      navigate(paths.auth.login, { replace: true });

      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    // navigate(paths.public.landing, { replace: true });
    navigate(paths.auth.login, { replace: true });

    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getUserProfile = createAsyncThunk('user/profile', async ({ dispatch }) => {
  try {
    const response = await get(`/user/admin/me`);
    if (response?.isSuccess) {
      return response;
    }
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await patch(`/user/update`, state);
      if (response?.uid) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
