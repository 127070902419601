import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
// import Avatar from '@mui/material/Avatar';
import { Stack, Tooltip, IconButton, Typography, CircularProgress } from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import { useBoolean } from 'src/hooks/use-boolean';

import NoImg from 'src/assets/other/ImgNull.jpg';
import { deleteBanner, archiveBanner, restoreBanner } from 'src/server/api/banner';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function BannerTableRow({ row, selected, onEditRow, tab, onDeleteRow }) {
  const { banner_type, uid, image, position, platform, video_id } = row;

  const confirm = useBoolean();

  const popover = usePopover();
  const dispatch = useDispatch();

  const handleClose = () => {
    confirm.onFalse();
  };
  const { delLoading } = useSelector((state) => ({
    delLoading: state.bannerData.delLoading,
  }));
  const handleDeleteBrandConfirmClick = () => {
    const credentials = {
      dispatch,
      bannerId: uid,
      handleClose,
    };
    dispatch(deleteBanner(credentials));
  };

  const handleArchiveBannerClick = () => {
    const credentials = {
      dispatch,
      bannerId: uid,
      handleClose,
    };
    dispatch(archiveBanner(credentials));
  };
  const handleUnarchiveBanner = () => {
    const credentials = {
      dispatch,
      bannerId: uid,
      handleClose,
    };
    dispatch(restoreBanner(credentials));
  };
  const handleDeleteBanner = () => {
    confirm.onTrue();
    popover.onClose();
  };
  const hasImage = image?.url;
  const hasVideo = !hasImage && video_id;
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          sx={{
            // cursor: 'pointer',
            maxWidth: '200px',
            minWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            // whiteSpace: 'nowrap',
            pr: 2,
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="caption">
              {(() => {
                if (banner_type === 'IMAGE') return 'Image';
                if (banner_type === 'VIDEO') return 'Video';
                return '--';
              })()}
            </Typography>
          </TextMaxLine>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {hasImage && (
            // <img
            //   src={image.url}
            //   alt={image?.alt_text?.english || 'Image'}
            //   className="product-all-list-img-large"
            // />
            <img
              src={
                image?.url
                  ? // eslint-disable-next-line react-hooks/rules-of-hooks
                    useResource(image?.url, 'w_500')
                  : NoImg
              }
              alt="First"
              className="banner-all-list-img-large"
            />
          )}
          {!hasImage && hasVideo && (
            <Stack
              sx={{
                // borderRadius: '15px',
                overflow: 'hidden',
                width: '330px',
                height: '161px',
                pointerEvents: 'none',
              }}
            >
              <Vimeo
                video={video_id}
                autoplay={false}
                style={{ width: '100%', height: '100%', borderRadius: '16px' }}
              />
            </Stack>
          )}
          {!hasImage && !hasVideo && (
            <img src={NoImg} alt="No" className="banner-all-list-img-large" />
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
            {(() => {
              if (position === 'MAIN') return 'Main';
              if (position === 'SUB') return 'Sub';
              return '--';
            })()}
            {/* {position || '---'} */}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="caption">
            {(() => {
              if (platform === 'COMMON') return 'Common';
              if (platform === 'APP') return 'App';
              if (platform === 'WEB') return 'Web';

              return '--';
            })()}
          </Typography>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          {/* <Stack
            columnGap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                color="info"
                sx={{ borderRadius: '6px' }}
                onClick={() => {
                  popover.onClose();
                  onEditRow(row);
                }}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
              <IconButton
                color="error"
                sx={{ borderRadius: '6px' }}
                onClick={() => {
                  confirm.onTrue();
                  popover.onClose();
                }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Stack>
          </Stack> */}
          <Stack
            columnGap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" alignItems="center">
              <Tooltip title="Edit Banner">
                <IconButton
                  color="info"
                  onClick={() => onEditRow(row)}
                  // onClick={() => handleViewClick(uid)}

                  sx={{ borderRadius: '6px' }}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems="center">
              {tab === 'is_deleted' ? (
                <>
                  <Tooltip title="Restore Banner">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="default"
                      onClick={handleUnarchiveBanner}
                    >
                      <UnarchiveRoundedIcon color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Banner">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="error"
                      onClick={handleDeleteBanner}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Archive Banner">
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleArchiveBannerClick}
                  >
                    <ArchiveRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            // onClick={() => {
            //   onDeleteRow(uid)();
            //   handleDeleteRow();
            // }}
            onClick={handleDeleteBrandConfirmClick}
          >
            Delete {delLoading && <CircularProgress size={14} sx={{ mx: 1 }} />}
            {/* // change loading component */}
          </Button>
        }
      />
    </>
  );
}

BannerTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  tab: PropTypes.any,
};
