import React from 'react';

import { Container } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';

import SettingsForm from './settingsPage';

function SettingsPage() {
  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <SettingsForm />
    </Container>
  );
}

export default SettingsPage;
