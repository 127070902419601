import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  deletePickupLocation,
  createPickupLocation,
  archivePickupLocation,
  getAllPickupLocations,
  restorePickupLocation,
  getPickupLocationCounts,
  getPickupLocationDetails,
  updatePickupLocationStatus,
  updatePickupLocationDetails,
} from '../api/pickupLocation';

export const pickupLocationslice = createSlice({
  name: 'pickupLocation',
  initialState: {
    loading: false,
    error: {},
    pickupLocations: {},
    pickupLocationCount: {},
    pickupLocationDetails: {},
    statusLoading: false,
  },
  reducers: {
    clearPickupLocationDetailsState: (state) => {
      state.pickupLocationDetails = {};
    },
  },
  extraReducers: {
    [createPickupLocation.pending]: (state) => {
      state.loading = true;
    },
    [createPickupLocation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.pickupLocations = action.payload;
      state.pickupLocations = modifiedList;
      state.error = {};
    },
    [createPickupLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // single view
    [getPickupLocationDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPickupLocationDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.pickupLocationDetails = action.payload;
      state.error = {};
    },
    [getPickupLocationDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all pickupLocations
    [getAllPickupLocations.pending]: (state) => {
      state.loading = true;
    },
    [getAllPickupLocations.fulfilled]: (state, action) => {
      state.loading = false;
      state.pickupLocations = action.payload;
      state.error = {};
    },
    [getAllPickupLocations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get location count
    [getPickupLocationCounts.pending]: (state) => {
      state.loading = true;
    },
    [getPickupLocationCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.pickupLocationCount = action.payload;
      state.error = {};
    },
    [getPickupLocationCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update
    [updatePickupLocationDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductGroupDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updatePickupLocationDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.pickupLocationDetails = action.payload;
      state.pickupLocations = action.payload;
      state.error = {};
    },

    [updatePickupLocationDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updatePickupLocationStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updatePickupLocationStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pickupLocations;
      const modifiedReviewList = {
        ...jsonState,
        pickupLocations: jsonState?.pickupLocations?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.pickupLocations = modifiedReviewList;
      state.error = {};
    },

    [updatePickupLocationStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archivePickupLocation.pending]: (state) => {
      state.delLoading = true;
    },
    [archivePickupLocation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pickupLocations;
      const modifiedPickupLocationList = {
        ...jsonState,
        pickupLocations: jsonState.pickupLocations?.filter(
          (review) => review.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.pickupLocations = modifiedPickupLocationList;
      state.error = {};
    },
    [archivePickupLocation.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restorePickupLocation.pending]: (state) => {
      state.delLoading = true;
    },
    [restorePickupLocation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pickupLocations;
      const modifiedPickupLocationList = {
        ...jsonState,
        pickupLocations: jsonState.pickupLocations?.filter(
          (review) => review.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.pickupLocations = modifiedPickupLocationList;
      state.error = {};
    },
    [restorePickupLocation.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deletePickupLocation.pending]: (state) => {
      state.loading = true;
    },
    [deletePickupLocation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pickupLocations;
      const modifiedList = {
        ...jsonState,
        pickupLocations: jsonState.pickupLocations?.filter(
          (review) => review.uid !== action.payload
        ),
      };
      state.loading = false;
      state.pickupLocations = modifiedList;
      state.error = {};
    },
    [deletePickupLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearPickupLocationDetailsState } = pickupLocationslice.actions;

export default pickupLocationslice.reducer;
