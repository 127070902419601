import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
// import Avatar from '@mui/material/Avatar';
import { Box, Stack, Switch, Tooltip, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  deletePickupLocation,
  archivePickupLocation,
  restorePickupLocation,
} from 'src/server/api/pickupLocation';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// ----------------------------------------------------------------------

export default function PickupLocationTableRow({
  row,
  selected,
  onEditRow,
  tab,
  handleChangeStatus,
  dispatch,
}) {
  const { title, address, phone, is_active, uid } = row;
  console.log(row);

  const confirm = useBoolean();
  const popover = usePopover();

  const handleClose = () => {
    confirm.onFalse();
  };

  const handleArchivePickupLocationClick = () => {
    const credentials = {
      dispatch,
      locationId: uid,
      handleClose,
    };
    dispatch(archivePickupLocation(credentials));
  };

  const handleDeletePickupLocationConfirmClick = () => {
    const credentials = {
      dispatch,
      locationId: uid,
      handleClose,
    };
    dispatch(deletePickupLocation(credentials));
  };

  const handleDeletePickupLocation = () => {
    confirm.onTrue();
    popover.onClose();
  };

  const handleUnarchivePickupLocation = () => {
    const credentials = {
      dispatch,
      locationId: uid,
      handleClose,
    };
    dispatch(restorePickupLocation(credentials));
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
            {title?.english || '---'}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{phone || '---'}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <TextMaxLine line={1} sx={{ maxWidth: 150, textAlign: 'start' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{address || '---'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Switch
              color="success"
              checked={is_active}
              onChange={(e) => handleChangeStatus(uid, e.target.checked)}
            />{' '}
            <Typography variant="caption">{is_active === true ? 'Published' : 'Draft'}</Typography>
          </Stack>
        </TableCell>{' '}
        <TableCell align="center" sx={{ px: 1 }}>
          <Box
            columnGap={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Tooltip title="Edit group">
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="info"
                  onClick={() => onEditRow(uid)}
                  sx={{ borderRadius: '6px' }}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Stack>
            </Tooltip>

            <Stack direction="row" alignItems="center">
              {tab === 'is_deleted' ? (
                <>
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleUnarchivePickupLocation}
                  >
                    {/* <Tooltip title="Restore product"> */}
                    <UnarchiveRoundedIcon color="info" fontSize="small" />
                    {/* </Tooltip> */}
                  </IconButton>
                  <Tooltip title="Delete product">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="error"
                      onClick={handleDeletePickupLocation}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Archive Group">
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleArchivePickupLocationClick}
                  >
                    <ArchiveRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </TableCell>
      </TableRow>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDeleteRow();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover> */}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePickupLocationConfirmClick}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

PickupLocationTableRow.propTypes = {
  onEditRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  tab: PropTypes.string,
  dispatch: PropTypes.func,
};
