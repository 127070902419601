import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createProduct = createAsyncThunk(
  'product/create',
  async ({ state, dispatch, handleClose, handleClear, router }) => {
    try {
      const URL = `/product/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product created successfully' }));
        handleClose();
        handleClear();
        router.push(paths.dashboard.product_update(response?.uid));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'An error occurred',
        })
      );
      throw error;
    }
  }
);

export const getAllProduct = createAsyncThunk(
  'product/list',
  async ({ page, search, dispatch, limit, state }) => {
    console.log(search);

    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductBySearch = createAsyncThunk(
  'product/search/list',
  async ({ page, search, dispatch, limit, state }) => {
    console.log(search);

    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductExcel = createAsyncThunk(
  'product/excel/list',
  async ({ page, search, dispatch, limit, state }) => {
    console.log(search);

    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit}`,
        state
      );

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const checkSapIdValid = createAsyncThunk(
  'product/check/sap-id',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product/admin/sap-id/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkProductNameValid = createAsyncThunk(
  'product/check/product-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product/admin/english-name/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductCounts = createAsyncThunk('product/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/product/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// todo
export const getProductDetails = createAsyncThunk(
  'product/single',
  async ({ productId, dispatch }) => {
    try {
      const response = await get(`/product/admin/single/${productId}`);
      if (response?.isSuccess) {
        return response;
      }

      dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductDetails = createAsyncThunk(
  'product/update',
  async ({ state, productId, dispatch }) => {
    try {
      const URL = `/product/admin/${productId}`;
      const response = await put(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product updated Successfully' }));
        const credentials = {
          productId,
          dispatch,
        };
        dispatch(getProductDetails(credentials));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeProductStatus = createAsyncThunk(
  'product/status/change',
  async ({ state, productId, dispatch,tab }) => {
    try {
      const URL = `/product/admin/status/active/${productId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveProduct = createAsyncThunk(
  'product/archive',
  async ({ productId, dispatch, handleClose }) => {
    try {
      const response = await post(`/product/admin/delete/${productId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Product archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreProduct = createAsyncThunk(
  'product/unarchive',
  async ({ productId, dispatch, handleClose }) => {
    try {
      const response = await post(`/product/admin/restore/${productId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Product archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'product/delete',
  async ({ productId, dispatch, handleClose }) => {
    try {
      const response = await del(`/product/admin/hard-delete/${productId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Product deleted successfully' }));
        return response?.uid;
      }

      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

