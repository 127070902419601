import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createProductLabel = createAsyncThunk(
  'product-label/create',
  async ({ state, dispatch, handleClose, handleClear, filterData }) => {
    try {
      const URL = `/product-label/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        handleClose();
        handleClear();
        const filterDataObj = {
          filter_data: filterData,
        };

        dispatch(
          getAllProductLabel({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Product Label created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductLabel = createAsyncThunk(
  'product-label/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product-label/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }`,
        state
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductLabelDetails = createAsyncThunk(
  'product-label/single',
  async ({ productLabelId, dispatch }) => {
    try {
      const response = await get(`/product-label/admin/${productLabelId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductLabelDetails = createAsyncThunk(
  'product-label/update',
  async ({
    state,
    productLabelId,
    dispatch,
    filterData,
    handleClose,
    handleClear,
    handleMenuClose,
  }) => {
    try {
      const URL = `/product-label/admin/${productLabelId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleMenuClose) {
          handleMenuClose();
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        const filterDataObj = {
          filter_data: filterData,
        };
        dispatch(
          getAllProductLabel({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_product_variation'),
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Product Label updated Successfully' }));
        return response?.message;
      }
      return '';
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductLabelStatus = createAsyncThunk(
  'product-label/status-update',
  async ({ state, productLabelId, dispatch ,tab}) => {
    try {
      const URL = `/product-label/admin/status/active/${productLabelId}`;
      const response = await post(URL, state);
      console.log(response);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductLabelCounts = createAsyncThunk(
  'product-label/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product-label/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveProductLabel = createAsyncThunk(
  'product-label/archive',
  async ({ productLabelId, dispatch }) => {
    try {
      const response = await post(`/product-label/admin/delete/${productLabelId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreProductLabel = createAsyncThunk(
  'product-label/unarchive',
  async ({ productLabelId, dispatch }) => {
    try {
      const response = await post(`/product-label/admin/restore/${productLabelId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProductLabel = createAsyncThunk(
  'product-label/delete',
  async ({ productLabelId, dispatch, handleClose }) => {
    try {
      const response = await del(`/product-label/admin/hard-delete/${productLabelId}/`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Product Label deleted successfully' }));
        return response?.uid;
      }

      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
