import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useTheme } from '@emotion/react';

import Stack from '@mui/material/Stack';
import { Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function BasicDetailsForm({ handleNext, title, data, isValid }) {
  const params = useParams();
  const theme = useTheme();
  const collectionId = params?.id;

  return (
    <>
      <Stack direction="row" justifyContent="end" mx={2} mt={2}>
        {!collectionId && (
          <Button variant="outlined" color="inherit" onClick={handleNext}>
            Next
          </Button>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid xs={12} md={12}>
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            px={2}
            my={2}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              {collectionId && <RHFSwitch name="is_active" color="success" label="Active" />}
            </Stack>
          </Stack>
          <Stack sx={{ p: 2, pt: 0 }}>
            {/* <Typography variant="subtitle2" sx={{ mb: 1, pb: 2 }}>
              Collection Name <Divider sx={{ mt: 1 }} />
            </Typography> */}

            <Grid container maxWidth="xl" spacing={2}>
              <Grid xs={12} md={6} item>
                <RHFTextField
                  name="title.english"
                  label="English Name"
                  type="string"
                  required
                  size="small"
                  placeholder="English Name*"
                  isValidationRequired={title && title !== data?.title?.english ? !!title : false}
                  isValid={isValid?.english_title}
                  // validationMsg="Title is already used"
                  validationMsg="Duplicate english title!"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Stack
                  className={theme?.palette?.mode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}
                  width="100%"
                >
                  <RHFTextField
                    name="title.arabic"
                    label="Arabic Name"
                    type="string"
                    size="small"
                    placeholder="Arabic Name"
                  />
                </Stack>
              </Grid>
              <Grid xs={12} md={4} item>
                <RHFTextField
                  name="priority"
                  label="Priority"
                  type="number"
                  size="small"
                  placeholder="Priority"
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Stack spacing={1.5} sx={{ p: 3, pt: 0, width: '100%' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Description
            <Divider sx={{ mt: 1 }} />
          </Typography>
          <Grid container maxWidth="xl" spacing={2}>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5}>
                <Typography variant="caption">In English</Typography>
                <RHFEditor  placeholder="Write description" name="descriptionEnglish" />
              </Stack>
            </Grid>
            <Grid xs={12} md={6} item>
              <Stack spacing={1.5} className="editor-arabic">
                <Typography variant="caption">In Arabic</Typography>
                <RHFEditor  name="descriptionArabic" placeholder="اكتب الوصف" />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}

BasicDetailsForm.propTypes = {
  handleNext: PropTypes.any,
  title: PropTypes.any,
  data: PropTypes.any,
  isValid: PropTypes.any,
};
