import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { Marker, GoogleMap, useLoadScript } from '@react-google-maps/api';

import mapStyle from './mapStyle';
// import { getUserLocation } from '../../../server/api/location';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '0 0 15px 15px',
};
const center = {
  lat: 11.0409,
  lng: 76.0827,
};

const options = {
  styles: mapStyle,
  disableDefaltUI: true,
  zoomConrol: true,
  maxZoom: 20,
};
export default function MyGoogleMap({ MAP_PUBLIC_KEY, markers, setMarkers }) {
  // const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_PUBLIC_KEY,
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return 'Error loading map';
  if (!isLoaded)
    return <div className="d-flex align-items-center justify-center">map is loading</div>;

  const handleMapMarker = (e) => {
    // e.preventDefault();
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);

    // dispatch(getUserLocation([e.latLng.lat(), e.latLng.lng()]));
    localStorage.setItem('pre_location', [e.latLng.lat(), e.latLng.lng()]);
  };

  return (
    <div className="custom-border" style={{ position: 'relative' }}>
      {/* <MapSearch zoomTo={zoomTo} setMarkers={setMarkers} /> */}

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={4}
        center={center}
        options={options}
        onClick={handleMapMarker}
        onLoad={onMapLoad}
      >
        {markers?.map((marker) => (
          <Marker position={{ lat: marker.lat, lng: marker.lng }} />
        ))}
      </GoogleMap>
    </div>
  );
}

MyGoogleMap.propTypes = {
  MAP_PUBLIC_KEY: PropTypes.string,
  markers: PropTypes.string,
  setMarkers: PropTypes.string,
};
