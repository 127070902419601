import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, post } from '../http';
import { activeSnack } from '../store/common';

export const getAllUsers = createAsyncThunk(
  'user/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/user/admin/all?page=${page && page}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getUserCounts = createAsyncThunk('user/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/user/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const createUser = createAsyncThunk('user/create', async ({ state, dispatch, router }) => {
  try {
    const URL = `/user/admin/new`;
    const response = await post(URL, state);
    if (response) {
      router.push({
        pathname: paths.dashboard.users,
        query: {},
      });

      dispatch(activeSnack({ type: 'success', message: 'User created successfully' }));
    } else {
      dispatch(activeSnack({ type: 'error', message: 'Something went wrong' }));
    }
    return response;
  } catch (error) {
    dispatch(
      activeSnack({
        type: 'error',
        message: error?.response?.data?.message || 'An error occurred',
      })
    );
    throw error;
  }
});
export const getUserDetails = createAsyncThunk('user/single', async ({ userId, dispatch }) => {
  try {
    const response = await get(`/user/admin/single/${userId}`);
    if (response?.isSuccess) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const updateUserDetails = createAsyncThunk(
  'user/update',
  async ({ state, userId, dispatch }) => {
    try {
      const URL = `/user/admin/${userId}`;
      // cc
      const response = await put(URL, state);
      if (response) {
        // router.push({
        //   pathname: paths.dashboard.products,
        //   query: {},
        // });

        dispatch(activeSnack({ type: 'success', message: 'User updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  'user/status',
  async ({ state, userId, tab,dispatch }) => {
    try {
      const URL = `/user/admin/status/active/${userId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return {response,tab};
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// .............USER ITEMS....................
export const getAllUserCartItems = createAsyncThunk(
  'user-cart-items/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllUserFavoriteItems = createAsyncThunk(
  'user-favorite-items/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllUserRecentlyViewedItems = createAsyncThunk(
  'user-recently-items/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllUserOrderItems = createAsyncThunk(
  'user-order-items/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 20}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
