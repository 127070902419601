import { createAsyncThunk } from '@reduxjs/toolkit';

import { activeSnack } from '../store/common';
import { get, put, del, post } from '../http';

export const createCurrency = createAsyncThunk(
  'currency/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/currency/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(activeSnack({ type: 'success', message: 'Currency created successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCurrency = createAsyncThunk(
  'currency/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `currency/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeCurrencyStatus = createAsyncThunk(
  'currency/status',
  async ({ state, currencyId, dispatch,tab }) => {
    try {
      const URL = `/currency/admin/status/active/${currencyId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkCurrencyTitleValid = createAsyncThunk(
  'currency/check/currency-title',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/currency/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCurrencyDetails = createAsyncThunk(
  'currency/single',
  async ({ currencyId, dispatch }) => {
    try {
      const response = await get(`/currency/admin/${currencyId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCurrencyDetails = createAsyncThunk(
  'currency/update',
  async ({ state, currencyId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/currency/admin/${currencyId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(activeSnack({ type: 'success', message: 'Currency updated Successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCurrencyCounts = createAsyncThunk('currency/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/currency/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const archiveCurrency = createAsyncThunk(
  'currency/archive',
  async ({ currencyId, dispatch }) => {
    try {
      const response = await post(`/currency/admin/delete/${currencyId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Currency archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const restoreCurrency = createAsyncThunk(
  'currency/unarchive',
  async ({ currencyId, dispatch }) => {
    try {
      const response = await post(`/currency/admin/restore/${currencyId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Currency archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteCurrency = createAsyncThunk(
  'currency/delete',
  async ({ currencyId, dispatch, handleClose }) => {
    try {
      const response = await del(`/currency/admin/hard-delete/${currencyId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Currency deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
