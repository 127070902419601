import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import Users from 'src/pages/Admin/Users';
import Brands from 'src/pages/Admin/Brand';
import SettingsPage from 'src/pages/Admin/settings';
import SpecialSale from 'src/pages/Admin/SpecialSale';
import Collection from 'src/pages/Admin/Collection/index';
import BannerList from 'src/pages/Admin/Banners/MainList';
import PickupLocation from 'src/pages/Admin/pickup-location';
import GroupCRUDForm from 'src/pages/Admin/Groups/groupCrudPage';
import ReviewCRUDForm from 'src/pages/Admin/Reviews/reviewCrudPage';
import UserViewPage from 'src/pages/Admin/Users/sections/UserViewPage';
import UpdateProductForm from 'src/pages/Admin/Products/ProductUpdatePage';
import CollectionCreatePage from 'src/pages/Admin/Collection/CollectionCreatePage';
import BrandCreateForm from 'src/pages/Admin/Brand/sections/modals/BrandCreatePage';
import CouponCreatePage from 'src/pages/Admin/Coupon/sections/modals/CouponCreatePage';
import PickupLocationCRUDForm from 'src/pages/Admin/pickup-location/pickupLocationCrudPage';
import CategoryCreatePage from 'src/pages/Admin/Category/sections/modals/CategoryCreatePage';

import { SplashScreen } from 'src/components/loading-screen';

const ProductVariationCreateForm = lazy(
  () => import('src/pages/Admin/ProductAttributes/variation/product-variation-update-Page')
);
const ProductAttributes = lazy(() => import('src/pages/Admin/ProductAttributes/index'));
const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const ProfileView = lazy(() => import('src/pages/Common/Auth/profile/index'));

const Orders = lazy(() => import('src/pages/Admin/Orders'));
const Products = lazy(() => import('src/pages/Admin/Products'));
const Reviews = lazy(() => import('src/pages/Admin/Reviews'));
const StockAndOffers = lazy(() => import('src/pages/Admin/Stock&offer'));
const Groups = lazy(() => import('src/pages/Admin/Groups'));
const Pages = lazy(() => import('src/pages/Admin/Pages'));
const Coupon = lazy(() => import('src/pages/Admin/Coupon'));
const Revenues = lazy(() => import('src/pages/Admin/Revenue'));
const Category = lazy(() => import('src/pages/Admin/Category'));
const Finance = lazy(() => import('src/pages/Admin/Finance'));
const SpecialSaleCreatePage = lazy(
  () => import('src/pages/Admin/SpecialSale/SpecialSaleCreatePage')
);

const ProductLabelCrudPage = lazy(
  () => import('src/pages/Admin/ProductAttributes/label/product-label-crud-Page')
);

const ProductGroup = lazy(() => import('src/pages/Admin/ProductAttributes/group/index'));
const ProductGroupCrudPage = lazy(
  () => import('src/pages/Admin/ProductAttributes/group/product-group-crud-Page')
);

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'admin',
            element: (
              <AuthLayout title="Authenticate as an administrator" userRole="admin">
                <LoginAdminPage role={AuthRoles.admin} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },

      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'inventory',
    element: <Products />,
  },
  {
    path: 'product/update/:id',
    element: <UpdateProductForm />,
  },

  {
    path: 'orders',
    element: <Orders />,
  },

  {
    path: 'inventory/category',
    element: <Category />,
  },

  {
    path: 'inventory/category/create',
    element: <CategoryCreatePage />,
  },

  {
    path: 'inventory/category/update/:id',
    element: <CategoryCreatePage />,
  },
  {
    path: 'inventory/coupon',
    element: <Coupon />,
  },

  {
    path: 'inventory/coupon/create',
    element: <CouponCreatePage />,
  },

  {
    path: 'inventory/coupon/update/:id',
    element: <CouponCreatePage />,
  },
  {
    path: 'inventory/brand',
    element: <Brands />,
  },

  {
    path: 'inventory/brand/create',
    element: <BrandCreateForm />,
  },

  {
    path: 'inventory/brand/update/:id',
    element: <BrandCreateForm />,
  },

  {
    path: 'inventory/product-attributes',
    element: <ProductAttributes />,
  },
  {
    path: 'inventory/product-variation/create',
    element: <ProductVariationCreateForm />,
  },
  {
    path: 'inventory/product-variation/update/:id',
    element: <ProductVariationCreateForm />,
  },

  {
    path: 'inventory/product-label/create',
    element: <ProductLabelCrudPage />,
  },
  {
    path: 'inventory/product-label/update/:id',
    element: <ProductLabelCrudPage />,
  },
  {
    path: 'inventory/product-group',
    element: <ProductGroup />,
  },
  {
    path: 'inventory/product-group/create',
    element: <ProductGroupCrudPage />,
  },
  {
    path: 'inventory/product-group/update/:id',
    element: <ProductGroupCrudPage />,
  },
  {
    path: 'inventory/collection',
    element: <Collection />,
  },
  {
    path: 'inventory/collection/create',
    element: <CollectionCreatePage />,
  },
  {
    path: 'inventory/collection/update/:id',
    element: <CollectionCreatePage />,
  },

  // groups
  {
    path: 'site-settings/groups',
    element: <Groups />,
  },
  {
    path: 'site-settings/group/create',
    element: <GroupCRUDForm />,
  },

  {
    path: 'site-settings/group/update/:id',
    element: <GroupCRUDForm />,
  },

  // pickup location
  {
    path: 'site-settings/pickup-location',
    element: <PickupLocation />,
  },
  {
    path: 'site-settings/pickup-location/create',
    element: <PickupLocationCRUDForm />,
  },
  {
    path: 'site-settings/pickup-location/update/:id',
    element: <PickupLocationCRUDForm />,
  },

  {
    path: 'site-settings/finance',
    element: <Finance />,
  },

  {
    path: 'inventory/special-sale',
    element: <SpecialSale />,
  },

  {
    path: 'inventory/special-sale/create',
    element: <SpecialSaleCreatePage />,
  },
  {
    path: 'inventory/special-sale/update/:id',
    element: <SpecialSaleCreatePage />,
  },
  // {
  //   path: 'inventory/banner',
  //   element: <BannerList />,
  // },

  {
    path: 'site-settings/banners',
    element: <BannerList />,
  },

  // review
  {
    path: 'reviews',
    element: <Reviews />,
  },
  {
    path: 'review/create',
    element: <ReviewCRUDForm />,
  },
  {
    path: 'review/update/:id',
    element: <ReviewCRUDForm />,
  },

  // old routes

  {
    path: 'stock-offer',
    element: <StockAndOffers />,
  },

  {
    path: 'pages',
    element: <Pages />,
  },
  // {
  //   path: 'coupon',
  //   element: <Coupon />,
  // },

  {
    path: 'revenue',
    element: <Revenues />,
  },

  {
    path: 'site-settings',
    element: <SettingsPage />,
  },
  {
    path: 'profile',
    element: <ProfileView />,
  },
  {
    path: 'inventory/user',
    element: <Users />,
  },

  {
    path: 'inventory/user/view/:id',
    element: <UserViewPage />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };
