import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProductLabel,
  getAllProductLabel,
  deleteProductLabel,
  archiveProductLabel,
  restoreProductLabel,
  getProductLabelCounts,
  getProductLabelDetails,
  updateProductLabelStatus,
  updateProductLabelDetails,
} from '../api/productLabel';

export const productLabelSlice = createSlice({
  name: 'product-label',
  initialState: {
    loading: false,
    error: {},
    productLabels: {},
    subcategories: {},
    staffDropList: [],
    ProductLabelDetails: {},
    statusLoading: false,
    delLoading: false,
    productLabelCounts: {},
  },
  reducers: {
    clearProductLabelError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProductLabel.pending]: (state) => {
      state.loading = true;
    },
    [createProductLabel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    [createProductLabel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProductLabel.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductLabel.fulfilled]: (state, action) => {
      state.loading = false;
      state.productLabels = action.payload;
      state.error = {};
    },
    [getAllProductLabel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getProductLabelDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductLabelDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.error = {};
    },
    [getProductLabelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProductLabelDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateProductLabelDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productLabels;
      const modifiedProductLabelList = {
        ...jsonState,
        productLabels: jsonState.productLabels?.map((i) =>
          i?.uid === action?.payload?.uid ? action?.payload : i
        ),
      };

      state.loading = false;
      state.ProductLabelDetails = action.payload;
      state.productLabels = modifiedProductLabelList;
      state.error = {};
    },

    [updateProductLabelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateProductLabelStatus.pending]: (state, action) => {
      state.statusLoading = true;
      state.error = action.error;
    },
    // [updateProductLabelStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.productLabels;

    //   const modifiedProductLabelList = {
    //     ...jsonState,
    //     productLabels: jsonState?.productLabels?.map((item) =>
    //       item?.uid === action.payload?.uid
    //         ? { ...item, is_active: action.payload?.is_active }
    //         : item
    //     ),
    //   };
    //   state.statusLoading = false;
    //   state.productLabels = modifiedProductLabelList;
    //   state.error = {};
    // },
    [updateProductLabelStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productLabels;
      const modifiedProductLabelList = {
        ...jsonState,
        productLabels: jsonState?.productLabels?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedProductLabelList2 = {
        ...jsonState,
        productLabels: jsonState?.productLabels?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.productLabels =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedProductLabelList2
          : modifiedProductLabelList;
      state.error = {};
    },
    [updateProductLabelStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // get getProductLabelCounts count
    [getProductLabelCounts.pending]: (state) => {
      // state.loading = true;
    },
    [getProductLabelCounts.fulfilled]: (state, action) => {
      // state.loading = false;
      state.productLabelCounts = action.payload;
      state.error = {};
    },
    [getProductLabelCounts.rejected]: (state, action) => {
      // state.loading = false;
      state.error = action.error;
    },

    // archive product
    [archiveProductLabel.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveProductLabel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productLabels;
      const modifiedProductLabelList = {
        ...jsonState,
        productLabels: jsonState.productLabels?.filter(
          (productLabels) => productLabels.uid !== action.payload
        ),
      };
      state.delLoading = false;
      state.productLabels = modifiedProductLabelList;
      state.error = {};
    },
    [archiveProductLabel.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // delete product
    [deleteProductLabel.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteProductLabel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productLabels;
      const modifiedProductLabelList = {
        ...jsonState,
        productLabels: jsonState.productLabels?.filter(
          (productLabels) => productLabels.uid !== action.payload
        ),
      };
      state.delLoading = false;
      state.productLabels = modifiedProductLabelList;
      state.error = {};
    },
    [deleteProductLabel.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // restore product
    [restoreProductLabel.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreProductLabel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productLabels;
      const modifiedProductLabelList = {
        ...jsonState,
        productLabels: jsonState.productLabels?.filter(
          (productLabels) => productLabels.uid !== action.payload
        ),
      };
      state.delLoading = false;
      state.productLabels = modifiedProductLabelList;
      state.error = {};
    },
    [restoreProductLabel.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductLabelError } = productLabelSlice.actions;

export default productLabelSlice.reducer;
