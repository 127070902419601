import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  helperText,
  multiline,
  rows,
  showDollarSymbol,
  label,
  type,
  inputShrink,
  sx,
  isValidationRequired,
  isValid,
  validationMsg,
  onChangeInput,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let finalHelperText = helperText;

        if (error) {
          finalHelperText = error.message;
        } else if (isValidationRequired && !isValid) {
          finalHelperText = validationMsg;
        }

        return (
          <TextField
            {...field}
            fullWidth
            type={type}
            variant="outlined"
            label={label}
            multiline={multiline}
            rows={rows}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (onChangeInput) {
                onChangeInput(event?.target.value)
              }
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error || (isValidationRequired && !isValid)}
            helperText={finalHelperText}
            {...other}
            InputProps={
              showDollarSymbol
                ? { startAdornment: <InputAdornment position="start">₹</InputAdornment> }
                : null
            } // Conditionally add InputAdornment
            InputLabelProps={
              inputShrink ? { shrink: true } : {} // Added this for shrink functionality
            }
            {...other}
          />
        )
      }}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.any,
  multiline: PropTypes.bool, // Add prop types for multiline and rows
  rows: PropTypes.number,
  showDollarSymbol: PropTypes.bool,
  inputShrink: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidationRequired: PropTypes.bool,
  validationMsg: PropTypes.string,
  onChangeInput: PropTypes.any,

};
