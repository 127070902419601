import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createTaxCategory = createAsyncThunk(
  'tax-category/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/tax-category/admin/new`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(activeSnack({ type: 'success', message: 'Tax category created successfully' }));
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTaxCategory = createAsyncThunk(
  'tax-category/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `tax-category/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeTaxCategoryStatus = createAsyncThunk(
  'taxCategory/status',
  async ({ state, taxCategoryId, dispatch, tab }) => {
    try {
      const URL = `/tax-category/admin/status/active/${taxCategoryId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkTaxCategoryTitleValid = createAsyncThunk(
  'tax-category/check/tax-category-title',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/tax-category/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getTaxCategoryDetails = createAsyncThunk(
  'tax-category/single',
  async ({ taxCategoryId, dispatch }) => {
    try {
      const response = await get(`/tax-category/admin/${taxCategoryId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateTaxCategoryDetails = createAsyncThunk(
  'tax-category/update',
  async ({ state, taxCategoryId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/tax-category/admin/${taxCategoryId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getTaxCategoryCounts = createAsyncThunk(
  'tax-category/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/tax-category/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveTaxCategory = createAsyncThunk(
  'tax-category/archive',
  async ({ TaxCategoryId, dispatch }) => {
    try {
      const response = await post(`/tax-category/admin/delete/${TaxCategoryId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Tax Category archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const restoreTaxCategory = createAsyncThunk(
  'tax-category/unarchive',
  async ({ TaxCategoryId, dispatch }) => {
    try {
      const response = await post(`/tax-category/admin/restore/${TaxCategoryId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Tax Category archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteTaxCategory = createAsyncThunk(
  'tax-category/delete',
  async ({ TaxCategoryId, dispatch, handleClose }) => {
    try {
      const response = await del(`/tax-category/admin/hard-delete/${TaxCategoryId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Tax Category deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
