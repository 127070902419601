import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import Card from '@mui/material/Card';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';

import {
  createPickupLocation,
  getPickupLocationDetails,
  updatePickupLocationDetails,
} from 'src/server/api/pickupLocation';

import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';

import BasicDetails from './sections/BasicDetails';

// import BasicDetails from './sections/BasicDetails';
// import ImagesDescription from './sections/images&description';

// ----------------------------------------------------------------------

export default function PickupLocationCRUDForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const pickupLocationId = params.id;
  const router = useRouter();
  const [rating, setRating] = useState(null);
  const [countryCode, setCountryCode] = useState();
  const [markers, setMarkers] = useState();

  const { loading, pickupLocationDetails } = useSelector((state) => ({
    loading: state.pickupLocation.loading,
    pickupLocationDetails: state?.pickupLocation?.pickupLocationDetails,
    error: state.pickupLocation.error,
  }));

  console.log(pickupLocationDetails);

  useEffect(() => {
    if (pickupLocationId) {
      const credentials = {
        locationId: pickupLocationId,
        dispatch,
      };
      dispatch(getPickupLocationDetails(credentials));
    }
  }, [dispatch, pickupLocationId]);

  // state
  const [imagePreview, setImagePreview] = useState([]); // data : public_id:'', url:"", alt_text:{arabic:"",english:""}

  useEffect(() => {
    if (pickupLocationDetails?.images?.length >= 1) {
      setImagePreview(pickupLocationDetails?.images);
    }
  }, [pickupLocationDetails?.images]);

  const reviewSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    phone: Yup.string()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .nullable(),
    priority: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .nullable(),
    address: Yup.string(),
  });

  console.log(pickupLocationDetails);

  const defaultValues = useMemo(() => {
    if (pickupLocationId) {
      return {
        title: {
          english: pickupLocationDetails?.title?.english || '',
          arabic: pickupLocationDetails?.title?.arabic || '',
        },
        address: pickupLocationDetails?.address || '',
        phone: pickupLocationDetails?.phone || '',
        priority: pickupLocationDetails?.priority || '',
        is_active: pickupLocationDetails?.is_active || false,
      };
    }
    return {
      title: {
        english: '',
        arabic: '',
      },
      priority: '',
      address: '',
      phone: '',
    };
  }, [pickupLocationDetails, pickupLocationId]);

  console.log('defaultValues =>', defaultValues);
  useEffect(() => {
    setCountryCode(pickupLocationDetails?.country_code); // Set a default value if rating is undefined
  }, [pickupLocationDetails?.country_code]);

  const methods = useForm({
    resolver: yupResolver(reviewSchema),
    defaultValues,
  });
  console.log(countryCode);

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  console.log('errors', errors);

  // default data
  useEffect(() => {
    if (pickupLocationDetails?.images?.length >= 1) {
      setImagePreview(pickupLocationDetails?.images);
    }
  }, [pickupLocationDetails?.images]);

  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const defaultProduct = useMemo(
    () => ({
      label: pickupLocationDetails?.product?.name?.english || '',
      value: pickupLocationDetails?.product?.uid || '',
      images: pickupLocationDetails?.images || '',
    }),
    [pickupLocationDetails]
  );

  useEffect(() => {
    if (defaultProduct.value) {
      setValue('product', defaultProduct);
    }
  }, [defaultProduct, setValue]);
  console.log(markers);

  useEffect(() => {
    setMarkers([
      {
        lng: pickupLocationDetails?.location?.coordinates[0],
        lat: pickupLocationDetails?.location?.coordinates[1],
      },
    ]);
  }, [pickupLocationDetails?.location?.coordinates]);

  const onSubmit = async (data) => {
    console.log(data);

    const initialData = {
      ...data,
      images: imagePreview,
      country_code: countryCode,
      location: {
        coordinates: [markers[0]?.lng, markers[0]?.lat],
      },
    };

    const updateInitialData = {
      ...data,
      images: imagePreview,
      country_code: countryCode,
      location: {
        coordinates: [markers[0]?.lng, markers[0]?.lat],
      },
    };

    // Prepare data for dispatch
    const createData = {
      state: initialData,
      dispatch,
      router,
    };
    const updateData = {
      state: updateInitialData,
      dispatch,
      router,
      locationId: pickupLocationId,
    };

    if (pickupLocationId) {
      // Update case
      console.log(updateData);
      dispatch(updatePickupLocationDetails(updateData));
    } else {
      // Create case
      console.log(createData);
      dispatch(createPickupLocation(createData));
    }
  };

  return (
    <>
      <Typography variant="subtitle1">
        {pickupLocationId ? 'Update Pickup Location' : 'Create Pickup Location'}
      </Typography>
      <Typography variant="caption">Please complete the Pickup Location information.</Typography>
      <Grid container maxWidth="xl" spacing={2} mt={2}>
        <Grid xs={12} md={8}>
          {loading ? (
            <Stack sx={{ height: '50px' }}>
              <LoadingScreen />
            </Stack>
          ) : (
            <Card sx={{ pb: 2 }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ width: '400px', top: '0', position: 'absolute', right: 0 }}>
                  <Grid
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                    }}
                  >
                    {errors?.name?.english?.message ? (
                      <Typography variant="caption" color="red">
                        Please fill the required fields!
                      </Typography>
                    ) : (
                      <Stack />
                    )}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="small"
                      loading={isSubmitting || loading}
                      sx={{
                        ml: 2,
                        width: '150px',
                        backgroundColor: '#106E58',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Stack>
                <>
                  {/* // Tab component */}

                  <BasicDetails
                    isUpdate={pickupLocationDetails?.uid && true}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    setRating={setRating}
                    rating={rating}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    setCountryCode={setCountryCode}
                    countryCode={countryCode}
                    markers={markers}
                    setMarkers={setMarkers}
                  />
                </>
                <Grid
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                  }}
                >
                  {errors?.name?.english?.message ? (
                    <Typography variant="caption" color="red">
                      Please fill the required fields!
                    </Typography>
                  ) : (
                    <Stack />
                  )}
                </Grid>
              </FormProvider>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
