import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Divider, Typography } from '@mui/material';

import { RHFEditor } from 'src/components/hook-form';

function WarrantyExchange({ control }) {
  return (
    <Stack spacing={3} sx={{ p: 2, mt: 2 }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Warranty Policy
        <Divider sx={{ mt: 1 }} />
      </Typography>

      <Grid container maxWidth="xl" spacing={3}>
        <Grid xs={12} md={6} item>
          <Typography variant="caption">In English</Typography>
          <RHFEditor
            sx={{ mt: 1 }}
            placeholder="Warranty details for the product"
            name="warrantyNoteEnglish"
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <Typography variant="caption">In Arabic</Typography>
          <Stack className="editor-arabic">
            <RHFEditor
              sx={{ mt: 1 }}
              placeholder="Warranty details for the product"
              name="warrantyNoteArabic"
            />
          </Stack>
        </Grid>
      </Grid>

      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Exchange Policy
        <Divider sx={{ mt: 1 }} />
      </Typography>
      <Grid container maxWidth="xl" spacing={3}>
        <Grid xs={12} md={6} item>
          <Typography variant="caption">In English</Typography>
          <RHFEditor
            sx={{ mt: 1 }}
            placeholder="Exchange policy details for the product"
            name="exchangePolicyEnglish"
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <Typography variant="caption">In Arabic</Typography>
          <RHFEditor
            sx={{ mt: 1 }}
            placeholder="Exchange policy details for the product"
            name="exchangePolicyArabic"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default WarrantyExchange;

WarrantyExchange.propTypes = {
  control: PropTypes.any,
};
