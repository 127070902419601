import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProductVariation,
  getAllProductVariation,
  deleteProductVariation,
  checkVariationNameValid,
  archiveProductVariation,
  restoreProductVariation,
  getProductVariationCounts,
  getProductVariationDetails,
  updateProductVariationStatus,
  updateProductVariationDetails,
} from '../api/productVariation';

export const productVariationSlice = createSlice({
  name: 'product-variation',
  initialState: {
    loading: false,
    error: {},
    productVariations: {},
    subcategories: {},
    staffDropList: [],
    ProductVariationDetails: {},
    statusLoading: false,
    delLoading: false,
    isProductVariationValid: false,
  },
  reducers: {
    clearProductVariationError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProductVariation.pending]: (state) => {
      state.loading = true;
    },
    [createProductVariation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.ProductVariationDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },
    [createProductVariation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProductVariation.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductVariation.fulfilled]: (state, action) => {
      state.loading = false;
      state.productVariations = action.payload;
      state.error = {};
    },
    [getAllProductVariation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getProductVariationDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductVariationDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ProductVariationDetails = action.payload;
      state.error = {};
    },
    [getProductVariationDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProductVariationDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProductVariationDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const modifiedDistrictList = {
        ...jsonState,
        subcategories: jsonState.subcategories?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.loading = false;

      state.ProductVariationDetails = action.payload;
      state.subcategories = modifiedDistrictList;
      state.error = {};
    },

    // update status
    [updateProductVariationStatus.pending]: (state, action) => {
      state.statusLoading = true;
      state.error = action.error;
    },

    // [updateProductVariationStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.productVariations;
    //   const modifiedProductVariationList = {
    //     ...jsonState,
    //     productVariations: jsonState.productVariations?.map((i) =>
    //       i?.uid === action.payload?.uid ? { ...i, is_active: action.payload?.is_active } : i
    //     ),
    //   };

    //   state.statusLoading = false;
    //   state.ProductVariationDetails = action.payload;
    //   state.productVariations = modifiedProductVariationList;
    //   state.error = {};
    // },
    [updateProductVariationStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariations;
      const modifiedProductVariationList = {
        ...jsonState,
        productVariations: jsonState?.productVariations?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedProductVariationList2 = {
        ...jsonState,
        productVariations: jsonState?.productVariations?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.productVariations =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedProductVariationList2
          : modifiedProductVariationList;
      state.error = {};
    },

    [updateProductVariationStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // check product name
    [checkVariationNameValid.pending]: (state) => {
      state.prodVariationLoading = true;
    },
    [checkVariationNameValid.fulfilled]: (state, action) => {
      state.prodVariationLoading = false;
      state.isProductVariationValid = action.payload;
      state.error = {};
    },
    [checkVariationNameValid.rejected]: (state, action) => {
      state.prodVariationLoading = false;
      state.error = action.error;
    },

    // get product count
    [getProductVariationCounts.pending]: (state) => {
      // state.loading = true;
    },
    [getProductVariationCounts.fulfilled]: (state, action) => {
      // state.loading = false;
      state.productVariationCounts = action.payload;
      state.error = {};
    },
    [getProductVariationCounts.rejected]: (state, action) => {
      // state.loading = false;
      state.error = action.error;
    },

    // archive product
    [archiveProductVariation.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveProductVariation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariations;
      const modifiedProductVariantsList = {
        ...jsonState,
        productVariations: jsonState.productVariations?.filter(
          (productVariations) => productVariations.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.productVariations = modifiedProductVariantsList;
      state.error = {};
    },
    [archiveProductVariation.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // delete product
    [deleteProductVariation.pending]: (state) => {
      state.delLoading = true;
    },

    [deleteProductVariation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariations;
      const modifiedProductVariantsList = {
        ...jsonState,
        productVariations: jsonState.productVariations?.filter(
          (productVariations) => productVariations.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.productVariations = modifiedProductVariantsList;
      state.error = {};
    },

    [deleteProductVariation.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // restore product
    [restoreProductVariation.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreProductVariation.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariations;
      const modifiedProductVariantsList = {
        ...jsonState,
        productVariations: jsonState.productVariations?.filter(
          (productVariations) => productVariations.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.productVariations = modifiedProductVariantsList;
      state.error = {};
    },
    [restoreProductVariation.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductVariationError } = productVariationSlice.actions;

export default productVariationSlice.reducer;
