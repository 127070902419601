import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createBanner,
  getAllBanner,
  deleteBanner,
  archiveBanner,
  restoreBanner,
  getBannerModule,
  getBannerCounts,
  getBannerDetails,
  updateBannerDetails,
} from '../api/banner';

export const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    loading: false,
    delLoading: false,

    error: {},
    banners: {},
    bannerCounts: {},
    BannerDetails: {},
  },
  reducers: {
    clearBannerError: (state) => {
      state.error = true;
    },
    clearBannerDetailsState: (state) => {
      state.BannerDetails = {};
    },
  },
  extraReducers: {
    [createBanner.pending]: (state) => {
      state.loading = true;
    },
    [createBanner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banners;
      const newBannerList = jsonState?.banners;

      const modifiedBannerList = {
        banners: Array.isArray(newBannerList) ? [action.payload, ...newBannerList] : newBannerList,
      };
      // console.log(action.payload);

      state.loading = false;
      // state.banners = modifiedBannerList;
      state.banners = action.payload.uid ? modifiedBannerList : jsonState;

      state.error = {};
      // console.log(state.BannerDetails)
    },
    [createBanner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllBanner.pending]: (state) => {
      state.loading = true;
    },
    [getAllBanner.fulfilled]: (state, action) => {
      state.loading = false;
      state.banners = action.payload;
      state.error = {};
    },
    [getAllBanner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBannerCounts.pending]: (state) => {
      state.loading = true;
    },
    [getBannerCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.bannerCounts = action.payload;
      state.error = {};
    },
    [getBannerCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getBannerDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBannerDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.BannerDetails = action.payload;
      state.error = {};
    },
    [getBannerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateBannerDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateBannerDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banners;

      const modifiedBannerList = {
        ...jsonState,
        banners: jsonState.banners?.map((i) =>
          i.uid === action.payload?.uid ? action?.payload : i
        ),
      };
      state.loading = false;
      state.BannerDetails = action.payload;
      state.banners = modifiedBannerList;
      state.error = {};
    },

    [updateBannerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBannerModule.pending]: (state) => {
      state.loading = true;
    },
    [getBannerModule.fulfilled]: (state, action) => {
      state.loading = false;
      state.banners = action.payload;
      state.error = {};
    },
    [getBannerModule.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteBanner.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteBanner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banners;
      const modifiedBannerList = {
        ...jsonState,
        banners: jsonState.banners?.filter((banners) => banners.uid !== action.payload),
      };
      state.delLoading = false;
      state.banners = modifiedBannerList;
      state.error = {};
    },
    [deleteBanner.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive banner
    [archiveBanner.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveBanner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banners;
      const modifiedBannerList = {
        ...jsonState,
        banners: jsonState.banners?.filter((banner) => banner.uid !== action.payload),
      };

      state.delLoading = false;
      state.banners = modifiedBannerList;
      state.error = {};
    },
    [archiveBanner.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore banner
    [restoreBanner.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreBanner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banners;
      const modifiedBannerList = {
        ...jsonState,
        banners: jsonState.banners?.filter((banner) => banner.uid !== action.payload),
      };

      state.delLoading = false;
      state.banners = modifiedBannerList;
      state.error = {};
    },
    [restoreBanner.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearBannerDetailsState, clearBannerError } = bannerSlice.actions;

export default bannerSlice.reducer;
