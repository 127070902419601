
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,

  Paper,
  Table,
  Stack,
  Divider,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';

// import { getAllProduct } from 'src/server/api/product';
// import { getAllUserCartItems } from 'src/server/api/user';

import { getAllProduct } from 'src/server/api/product';

// components
import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import UserCartTableRow from './userCartTableRow';

// sections

export default function UserCartList() {
  const dispatch = useDispatch();
  
  // const isMobile = useResponsive('sm', 'xs');
  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { userCartItems, loading, products } = useSelector((state) => ({
    userCartItems: state.user.userCartItems,
    productsCount: state.user.productCounts,
    loading: state.user.loading,
    products: state.product.products,

  }));
  console.log(userCartItems)




  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const limit = 20;
  const total = userCartItems?.total;

  const TABLE_HEAD = [
    { id: 'product', label: 'Products' },
    { id: 'price', label: 'Price' },
    { id: 'quantity', label: 'Qty' },
    { id: 'total', label: 'Total' },
  ];
console.log(products)
  useEffect(() => {
    setTableData(products?.products);
    // setTableData(userCartItems?.products);
  }, [products]);

  useEffect(() => {
    const filterSortObj = {
      filter_data: '',
    };
    const credentials = {
      page: table.page + 1,
      search: '',
      dispatch,
      limit: table.rowsPerPage || limit,
      state: filterSortObj,
    };
    console.log(credentials)
    dispatch(getAllProduct(credentials));
    // dispatch(getAllUserCartItems(credentials));

  }, [dispatch, table.page, table.rowsPerPage]);



  return (
    <Card>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
     
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={table.selected?.length}
              onSort={table.onSort}
            />

            <TableBody>
              {loading ? (
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress size="20px" color="inherit" />
                  </Paper>
                </TableCell>
              ) : (
                <>
                  {(tableData || []).map((row, index) => (
                    <UserCartTableRow
                      index={index}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      loading={loading}
                     
                    />
                  ))}
                </>
              )}
            </TableBody>

            {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
<Stack justifyContent='end' alignItems='flex-end' p={2}>
  <Stack sx={{width:'fit-content'}}>
    <Stack gap={2}> 

    <Typography variant='body2' sx={{fontWeight:'bold'}}>
    Price Details
    </Typography>
<Typography sx={{display:'flex',flexDirection:'row',gap:4,justifyContent:'space-between'}}>
Price (2 items) <Typography>
  OMR 35.98
  </Typography>
  
</Typography>
<Typography sx={{display:'flex',flexDirection:'row',gap:4,justifyContent:'space-between'}}>
Discount<Typography>
− OMR 2.00  </Typography>
</Typography>
<Typography sx={{display:'flex',flexDirection:'row',gap:4,justifyContent:'space-between'}}>
Tax <Typography>
OMR 0.00  </Typography>
  
</Typography>
<Typography sx={{display:'flex',flexDirection:'row',gap:4,justifyContent:'space-between'}}>
Shipping Fee<Typography>
Free  </Typography>
  
</Typography>
</Stack>
<Divider sx={{ my: 2}} />

<Typography sx={{display:'flex',flexDirection:'row',gap:4,fontWeight:'bold',justifyContent:'space-between'}}>
Total<Typography sx={{fontWeight:'bold'}}>
OMR 33.98  </Typography>
</Typography>
  </Stack>
</Stack>
      <TablePaginationCustom
        count={total || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
