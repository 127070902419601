import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCollection,
  getAllCollection,
  deleteCollection,
  restoreCollection,
  getCollectionCounts,
  getCollectionDetails,
  hardDeleteCollection,
  changeCollectionStatus,
  updateCollectionDetails,
  checkCollectionTitleValid,
} from '../api/collections';

export const collectionSlice = createSlice({
  name: 'collection',
  initialState: {
    loading: false,
    error: {},
    collectionSlice: {},
    collections: {},
    staffDropList: [],
    collectionDetails: {},
    collectionCounts: {},
    collectionTitleLoading: false,
    isCollectionTitleValid: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearCollectionError: (state) => {
      state.error = true;
    },
    clearCollectionData: (state) => {
      state.collectionDetails = {};
    },
  },
  extraReducers: {
    [createCollection.pending]: (state) => {
      state.loading = true;
    },
    [createCollection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const newDistrictsList = jsonState?.collections;

      const modifiedDistrictList = {
        ...jsonState,
        collections: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.BrandDetails = action.payload;
      state.collections = modifiedDistrictList;
      state.error = {};
    },
    [createCollection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCollection.pending]: (state) => {
      state.loading = true;
    },
    [getAllCollection.fulfilled]: (state, action) => {
      state.loading = false;
      state.collections = action.payload;
      state.error = {};
    },
    [getAllCollection.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get collection count
    [getCollectionCounts.pending]: (state) => {
      state.loading = true;
    },
    [getCollectionCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.collectionCounts = action.payload;
      state.error = {};
    },
    [getCollectionCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get collection status
    [changeCollectionStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [changeCollectionStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const modifiedCollectionList = {
        ...jsonState,
        collections: jsonState.collections?.map((i) =>
          i.uid === action?.payload?.response?.uid ? action?.payload?.response : i
        ),
      };
      const modifiedCollectionList2 = {
        ...jsonState,
        collections: jsonState?.collections?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };
      state.statusLoading = false;
      state.collections =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedCollectionList2
          : modifiedCollectionList;
      state.error = {};
    },

    [changeCollectionStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // check collection title
    [checkCollectionTitleValid.pending]: (state) => {
      state.collectionTitleLoading = true;
    },
    [checkCollectionTitleValid.fulfilled]: (state, action) => {
      state.collectionTitleLoading = false;
      state.isCollectionTitleValid = action.payload;
      state.error = {};
    },
    [checkCollectionTitleValid.rejected]: (state, action) => {
      state.collectionTitleLoading = false;
      state.error = action.error;
    },

    [getCollectionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCollectionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.collectionDetails = action.payload;
      state.error = {};
    },
    [getCollectionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCollectionDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateCollectionDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.BrandDetails = action.payload;
    //   state.error = {};
    // },
    [updateCollectionDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const modifiedDistrictList = {
        ...jsonState,
        collections: jsonState.collections?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.collections = modifiedDistrictList;
      state.BrandDetails = action.payload;
      state.collections = modifiedDistrictList;
      state.error = {};
    },
    // [updateCollectionDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.collections;
    //   console.log(jsonState)
    //   const modifiedDistrictList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.BrandDetails = action.payload;
    //   state.error = {};
    // },
    [updateCollectionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCollection.pending]: (state) => {
      state.statusLoading = true;
    },
    [deleteCollection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const modifiedCollectionList = {
        ...jsonState,
        collections: jsonState.collections?.filter(
          (collections) => collections.uid !== action.payload
        ),
      };
      state.statusLoading = false;
      state.collections = modifiedCollectionList;
      state.error = {};
    },
    [deleteCollection.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // hard delete collection
    [hardDeleteCollection.pending]: (state) => {
      state.delLoading = true;
    },
    [hardDeleteCollection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const modifiedCollectionList = {
        ...jsonState,
        collections: jsonState.collections?.filter(
          (collection) => collection.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.collections = modifiedCollectionList;
      state.error = {};
    },
    [hardDeleteCollection.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // restore collection
    [restoreCollection.pending]: (state) => {
      state.statusLoading = true;
    },
    [restoreCollection.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.collections;
      const modifiedCollectionList = {
        ...jsonState,
        collections: jsonState.collections?.filter(
          (collection) => collection.uid !== action.payload
        ),
      };
      state.statusLoading = false;
      state.collections = modifiedCollectionList;
      state.error = {};
    },
    [restoreCollection.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearCollectionError, clearCollectionData } = collectionSlice.actions;

export default collectionSlice.reducer;
