import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import Card from '@mui/material/Card';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';

import { createReview, getReviewDetails, updateReviewDetails } from 'src/server/api/reviews';

import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';

import BasicDetails from './sections/BasicDetails';

// import BasicDetails from './sections/BasicDetails';
// import ImagesDescription from './sections/images&description';

// ----------------------------------------------------------------------

export default function ReviewCRUDForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const reviewId = params.id;
  const router = useRouter();
  const [rating, setRating] = useState(null);

  const { loading, reviewDetails } = useSelector((state) => ({
    loading: state.review.loading,
    reviewDetails: state.review.reviewDetails,
    error: state.product.error,
  }));

  console.log(reviewDetails);

  useEffect(() => {
    if (reviewId) {
      const credentials = {
        reviewId,
        dispatch,
      };
      dispatch(getReviewDetails(credentials));
    }
  }, [dispatch, reviewId]);

  // state
  const [imagePreview, setImagePreview] = useState([]); // data : public_id:'', url:"", alt_text:{arabic:"",english:""}

  useEffect(() => {
    if (reviewDetails?.images?.length >= 1) {
      setImagePreview(reviewDetails?.images);
    }
  }, [reviewDetails?.images]);

  const reviewSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .nullable(),
    message: Yup.string(),
    product: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
  });

  console.log(rating);

  const defaultValues = useMemo(() => {
    if (reviewId) {
      return {
        name: reviewDetails?.name || '',
        message: reviewDetails?.message || '',
        phone: reviewDetails?.phone || '',
        is_active: reviewDetails?.is_active || false,
      };
    }
    return {
      name: '',
      message: '',
      phone: '',
      product: {},
    };
  }, [reviewDetails, reviewId]);

  console.log('defaultValues =>', defaultValues);
  useEffect(() => {
    setRating(reviewDetails?.rating || 0); // Set a default value if rating is undefined
  }, [reviewDetails?.rating]);

  const methods = useForm({
    resolver: yupResolver(reviewSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  console.log('errors', errors);

  // default data
  useEffect(() => {
    if (reviewDetails?.images?.length >= 1) {
      setImagePreview(reviewDetails?.images);
    }
  }, [reviewDetails?.images]);

  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const defaultProduct = useMemo(
    () => ({
      label: reviewDetails?.product?.name?.english || '',
      value: reviewDetails?.product?.uid || '',
      images: reviewDetails?.images || '',
    }),
    [reviewDetails]
  );

  useEffect(() => {
    if (defaultProduct.value) {
      setValue('product', defaultProduct);
    }
  }, [defaultProduct, setValue]);

  const onSubmit = async (data) => {
    console.log(data);

    const initialData = {
      ...data,
      images: imagePreview,
      rating: parseInt(rating, 10),
      product: data?.product?.value,
    };

    const updateInitialData = {
      ...data,
      images: imagePreview,
      product: data?.product?.value,
      rating: parseInt(rating, 10),
    };

    // Prepare data for dispatch
    const createData = {
      state: initialData,
      dispatch,
      router,
    };
    const updateData = {
      state: updateInitialData,
      dispatch,
      router,
      reviewId,
    };

    if (reviewId) {
      // Update case
      console.log(updateData);
      dispatch(updateReviewDetails(updateData));
    } else {
      // Create case
      console.log(createData);
      dispatch(createReview(createData));
    }
  };

  return (
    <>
      <Typography variant="subtitle1">{reviewId ? 'Update Review' : 'Create Review'}</Typography>
      <Typography variant="caption">Please complete the Group information.</Typography>
      <Grid container maxWidth="xl" spacing={2} mt={2}>
        <Grid xs={12} md={8}>
          {loading ? (
            <Stack sx={{ height: '50px' }}>
              <LoadingScreen />
            </Stack>
          ) : (
            <Card sx={{ pb: 2 }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ width: '400px', top: '0', position: 'absolute', right: 0 }}>
                  <Grid
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                    }}
                  >
                    {errors?.name?.english?.message ? (
                      <Typography variant="caption" color="red">
                        Please fill the required fields!
                      </Typography>
                    ) : (
                      <Stack />
                    )}
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={rating === 0}
                      loading={isSubmitting || loading}
                      sx={{
                        ml: 2,
                        width: '150px',
                        backgroundColor: '#106E58',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Stack>
                <>
                  {/* // Tab component */}

                  <BasicDetails
                    isUpdate={reviewDetails?.uid && true}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    setRating={setRating}
                    rating={rating}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    // handleRemoveProduct={handleRemoveItemToList}
                  />
                  {/* {tabChange.value === 'img&description' && (
                    <ImagesDescription
                      control={control}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                    />
                  )} */}
                </>
                <Grid
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                  }}
                >
                  {errors?.name?.english?.message ? (
                    <Typography variant="caption" color="red">
                      Please fill the required fields!
                    </Typography>
                  ) : (
                    <Stack />
                  )}
                </Grid>
              </FormProvider>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
