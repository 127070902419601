import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createSpecialSale = createAsyncThunk(
  'special-sale/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/special-sale/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router?.push(paths.dashboard.inventory.special_sale);
        }
        dispatch(activeSnack({ type: 'success', message: 'Special Sale created successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSpecialSale = createAsyncThunk(
  'special-sale/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/special-sale/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSpecialSaleCounts = createAsyncThunk(
  'special-sale/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/special-sale/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeSpecialSaleStatus = createAsyncThunk(
  'special-sale/status',
  async ({ state, specialSaleId, dispatch,tab }) => {
    try {
      const URL = `/special-sale/admin/status/active/${specialSaleId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return { response, tab };
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkSpecialSaleTitleValid = createAsyncThunk(
  'special-sale/check/special-sale-title',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/special-sale/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSpecialSaleDetails = createAsyncThunk(
  'special-sale/single',
  async ({ specialSaleId, dispatch }) => {
    try {
      const response = await get(`/special-sale/admin/single/${specialSaleId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSpecialSaleDetails = createAsyncThunk(
  'special-sale/update',
  async ({ state, specialSaleId, dispatch, router, isImageRemove }) => {
    try {
      const URL = `/special-sale/admin/${specialSaleId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router?.push(paths.dashboard.inventory.special_sale);
        }
        if (isImageRemove) {
          dispatch(getSpecialSaleDetails({ specialSaleId: response?.uid, dispatch }));
        }
        dispatch(activeSnack({ type: 'success', message: 'Special Sale updated Successfully' }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSpecialSale = createAsyncThunk(
  'special-sale/delete',
  async ({ specialSaleId, dispatch, handleClose }) => {
    try {
      const response = await post(`/special-sale/admin/delete/${specialSaleId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Special Sale deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreSale = createAsyncThunk(
  'special-sale/unarchive',
  async ({ specialSaleId, dispatch, handleClose }) => {
    try {
      const response = await post(`/special-sale/admin/restore/${specialSaleId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Sale archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const hardDeleteSale = createAsyncThunk(
  'special-sale/hard/delete',
  async ({ specialSaleId, dispatch, handleClose }) => {
    try {
      const response = await del(`/special-sale/admin/hard-delete/${specialSaleId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Sale deleted successfully' }));
        return response?.uid;
      }

      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
