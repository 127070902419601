import { useState, useEffect } from 'react';

// Import the user icon

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Tab, Tabs, alpha } from '@mui/material';

import SEOPage from 'src/pages/Common/Others/SEOPage';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getSeoItemModule } from 'src/server/api/seoData';

import CollectionData from './sections/CollectionData';
import BannerList from '../Banners/sections/bannerList';

// ----------------------------------------------------------------------

export default function CollectionCreatePage() {
  const params = useParams();
  const dispatch = useDispatch();

  const { seoData } = useSelector((state) => ({
    seoData: state.seoData.seoData,
  }));

  const collectionId = params.id;

  const STATUS_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'products', label: 'Products' },
  ];

  if (collectionId) {
    STATUS_OPTIONS.push({ value: 'banners', label: 'Banners' }, { value: 'seo', label: 'SEO' });
  }

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  useEffect(() => {
    const filterDataObj = {
      module: 'COLLECTION',
      module_id: collectionId,
    };

    const credentials = {
      search: '',
      dispatch,
      state: filterDataObj,
    };

    if (collectionId) {
      dispatch(getSeoItemModule(credentials));
    }
  }, [collectionId, dispatch]);

  const currentSeoData = seoData;

  return (
    <>
      <MetaHelmet title="Collection Crud" />
      <Grid container spacing={2}>
        <Grid xs={12} md={12} xl={8}>
          <Card>
            <Tabs
              value={tabChange.value}
              onChange={handleFilterStatus}
              sx={{
                px: 2.5,
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {STATUS_OPTIONS.map((tab, key) => (
                <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <CollectionData tabChange={tabChange} setTabChange={setTabChange} />
            {tabChange.value === 'banners' && (
              <BannerList moduleName="COLLECTION" moduleId={collectionId} />
            )}
            {tabChange.value === 'seo' && (
              <SEOPage
                moduleName="COLLECTION"
                moduleId={collectionId} // category uid
                updateData={currentSeoData} // current seo data
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

CollectionCreatePage.propTypes = {};
