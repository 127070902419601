import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { getRedeemedUserList } from 'src/server/api/coupon';

// components
import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import RedeemedUserTableRow from './RedeemedUserTableRow';

// sections

export default function RedeemedUserList({ couponId }) {
  const dispatch = useDispatch();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 20,
    defaultDense: true,
  });

  const { redeemedUsers, loading } = useSelector((state) => ({
    redeemedUsers: state.coupon.redeemedUsers,
    loading: state.coupon.loading,
  }));

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  // const limit = 20;
  const total = redeemedUsers?.total;

  const TABLE_HEAD = [
    { id: 'redeemedOn', label: 'Redeemed On' },
    { id: 'user', label: 'User' },
    { id: 'order', label: 'Order' },
  ];

  useEffect(() => {
    setTableData(redeemedUsers?.userCoupons);
  }, [redeemedUsers]);
  console.log(redeemedUsers);
  useEffect(() => {
    if (couponId) {
      const credentials = {
        couponId,
        dispatch,
      };
      dispatch(getRedeemedUserList(credentials));
    }
  }, [dispatch, couponId]);
  // console.log(tableData)
  return (
    <Card>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={table.selected?.length}
              onSort={table.onSort}
            />

            <TableBody>
              {loading ? (
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress size="20px" color="inherit" />
                  </Paper>
                </TableCell>
              ) : (
                <>
                  {(tableData || []).map((row, index) => (
                    <RedeemedUserTableRow
                      index={index}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      loading={loading}
                    />
                  ))}
                </>
              )}
            </TableBody>

            {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={total || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}

RedeemedUserList.propTypes = {
  couponId: PropTypes.any,
};
