import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { React, useState } from 'react';

import {
  Box,
  Grid,
  Stack,
  Divider,
  MenuItem,
  TextField,
  CardMedia,
  Typography,
  InputAdornment,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';

import GroupProductData from './selection/dataSelection';
import overlap from '../../../../assets/images/group/overlap.png';
import carousel from '../../../../assets/images/group/carousel.png';
import highlight from '../../../../assets/images/group/highlight.png';
import imageGrid from '../../../../assets/images/group/image_grid.png';
import specialSell from '../../../../assets/images/group/specialSell.png';
import iconCarousel from '../../../../assets/images/group/iconCarousel.png';
import listHorizontal from '../../../../assets/images/group/listHorizontal.png';

function BasicDetails({ watch, handleAdd, selectedValue, handleRemoveProduct, isUpdate }) {
  const theme = useSettingsContext();
  const layoutOptions = [
    { label: 'Highlight', value: 'HIGHLIGHT' },
    { label: 'Carousel', value: 'CAROUSEL' },
    { label: 'Image Grid', value: 'IMAGE_GRID' },
    { label: 'Icon Carousel', value: 'ICON_CAROUSEL' },
    { label: 'List Horizontal', value: 'LIST_HORIZONTAL' },
    { label: 'Overlap List', value: 'OVERLAP_LIST' },
    { label: 'Special Sell Carousel', value: 'SPECIAL_SELL_CAROUSEL' },
  ];

  const platformOptions = [
    { label: 'Web', value: 'WEB' },
    { label: 'Mobile', value: 'MOBILE' },
    { label: 'Common', value: 'COMMON' },
  ];

  const typeOptions = [
    { label: 'Single Product', value: 'SINGLE_PRODUCT' },
    { label: 'Products', value: 'PRODUCTS' },
    { label: 'Single Category', value: 'SINGLE_CATEGORY' },
    { label: 'Categories', value: 'CATEGORIES' },
    { label: 'Single Brand', value: 'SINGLE_BRAND' },
    { label: 'Brands', value: 'BRANDS' },
    { label: 'Single Collections', value: 'SINGLE_COLLECTION' },
    { label: 'Collections', value: 'COLLECTIONS' },
    { label: 'Single Special Sale', value: 'SINGLE_SPECIAL_SALE' },
    { label: 'Special Sales', value: 'SPECIAL_SALES' },
    { label: 'Single Banner', value: 'SINGLE_BANNER' },
    { label: 'Banners', value: 'BANNERS' },
  ];

  const watchType = watch('type');
  const watchIsLive = watch('is_live');
  const layout = watch('layout');

  const [searchText, setSearchText] = useState();
  const [finalSearchText, setFinalSearchText] = useState('');

  const debouncedSetDebouncedText = debounce((value) => setFinalSearchText(value), 600);

  const handleChangeSearchText = (e) => {
    debouncedSetDebouncedText(e.target.value);
    setSearchText(e.target.value);
  };

  const renderContent = () => {
    const layoutImages = {
      HIGHLIGHT: highlight,
      CAROUSEL: carousel,
      IMAGE_GRID: imageGrid,
      OVERLAP_LIST: overlap,
      LIST_HORIZONTAL: listHorizontal,
      ICON_CAROUSEL: iconCarousel,
      SPECIAL_SELL_CAROUSEL: specialSell,
    };

    const imageSrc = layoutImages[layout];

    return imageSrc ? (
      <Stack>
        <Typography variant="caption" fontSize={10} px={1}>
          Example Design
        </Typography>
        <CardMedia
          src={imageSrc}
          component="img"
          alt={layout.toLowerCase()}
          sx={{ height: 250, width: '100%', objectFit: 'contain' }}
        />
      </Stack>
    ) : (
      <Box
        sx={{
          height: 250,
          width: '100%',
          backgroundColor: '#919EAB14',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack alignItems="center" justifyContent="center" textAlign="center">
          <Iconify icon="humbleicons:image" />
          <Typography variant="caption">Layout Preview</Typography>
        </Stack>
      </Box>
    );
  };
  return (
    <Stack spacing={3} sx={{ p: 2, mt: 1 }}>
      <Typography variant="subtitle2" sx={{ mb: 0 }}>
        Title
        <Divider sx={{ mt: 1 }} />
      </Typography>
      <Grid container maxWidth="xl" spacing={2}>
        <Grid xs={12} md={12} item>
          <RHFTextField
            name="title.english"
            label="English Name"
            type="string"
            placeholder="English Name"
            size="small"
          />
        </Grid>
        <Grid xs={12} md={12} item>
          <Stack className={theme?.themeMode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}>
            <RHFTextField
              name="title.arabic"
              label="Arabic Name"
              type="string"
              size="small"
              placeholder="Arabic Name"
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={12} md={6} lg={6} item>
          <RHFSelect name="platform" label="Platform" size="small">
            {platformOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <RHFTextField
            name="priority"
            label="Priority"
            type="number"
            size="small"
            placeholder="Enter Priority"
          />
        </Grid>
      </Grid>

      <Grid xs={12} md={6} item>
        <RHFSelect name="layout" label="Layout" size="small">
          {layoutOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>

      <Grid xs={12} md={12} lg={12} item>
        {renderContent()}
      </Grid>

      <Grid container>
        <Grid xs={12} md={6} lg={6} item pr={1}>
          <RHFSelect name="type" label="Type" size="small" required>
            {typeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid pl={4}>
          <RHFSwitch name="is_live" color="success" label="Live Items" />
        </Grid>
      </Grid>

      {watchIsLive !== true && watchType && (
        <>
          {watchType === 'BANNERS' || watchType === 'SINGLE_BANNER' ? (
            ''
          ) : (
            <Box sx={{ my: 1, px: 2 }}>
              <TextField
                fullWidth
                value={searchText}
                onChange={handleChangeSearchText}
                placeholder="Search.."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{ mx: 1 }}
              />
            </Box>
          )}
          <GroupProductData
            isUpdate={isUpdate}
            watch={watch}
            handleAdd={handleAdd}
            selectedValue={selectedValue}
            handleRemoveProduct={handleRemoveProduct}
            searchText={finalSearchText}
          />
        </>
      )}
    </Stack>
  );
}

BasicDetails.propTypes = {
  watch: PropTypes.func,
  isUpdate: PropTypes.bool,
  handleAdd: PropTypes.func,
  selectedValue: PropTypes.any,
  handleRemoveProduct: PropTypes.func,
};

export default BasicDetails;
