import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------

export default function RHFAutocompleteBasic({
  label,
  size,
  placeholder,
  handleChangeValue,
  handleChangeSearch,
  options,
  ...other
}) {
  // const { setValue } = useFormContext();

  return (
    <Autocomplete
      className="RHFAutocompleteBasic"
      id="country-select-demo"
      options={options} // to give options(array)
      autoHighlight
      size={size} // size
      getOptionLabel={(option) => option?.label} // conform this is name to give must label
      onChange={handleChangeValue} // to get selected or change value
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option?.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label} // label
          placeholder={placeholder} // placeholder
          onChange={handleChangeSearch} //
          inputProps={{
            ...params.inputProps,
            autoComplete: '',
          }}
        />
      )}
    />
  );
}

RHFAutocompleteBasic.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  handleChangeValue: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  options: PropTypes.array,
};
