import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Box, Dialog, Tooltip, Typography, IconButton, DialogContent } from '@mui/material';

// components

import YouTube from 'react-youtube';

import Iconify from 'src/components/iconify';

VideoPreviewYoutube.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function VideoPreviewYoutube({ open, setOpen, data }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ overflow: 'unset' }}
        className="video_preview_modal"
      >
        <DialogContent sx={{ minWidth: '550px', p: 0 }}>
          {data ? (
            <YouTube videoId={data} iframeClassName="youtube_video_frame" />
          ) : (
            <Box
              sx={{
                height: '320px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#919eab1a',
                borderRadius: '13px',
              }}
            >
              <Tooltip title="Upon entering the video ID, the preview will become visible.">
                <Typography variant="body2" color="#637381">
                  Video Preview
                </Typography>
              </Tooltip>
            </Box>
          )}
        </DialogContent>
        <IconButton
          size="small"
          sx={{
            height: '40px',
            width: '40px',
            color: '#fff',
            backgroundColor: '#00000066',
            position: 'absolute',
            bottom: '-5rem',
            left: '50%',
          }}
          onClick={handleClose}
        >
          <Iconify icon="mingcute:close-line" width={16} />
        </IconButton>
      </Dialog>
    </div>
  );
}
