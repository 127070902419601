import PropTypes from 'prop-types';

import DoneIcon from '@mui/icons-material/Done';
import { Box, Card, Stack, Tooltip, CardMedia, IconButton, Typography } from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import TextMaxLine from 'src/components/text-max-line';

export default function SelectedDetails({ products, handleAdd, selectedValue = [], setType }) {
  console.log('productDetails =>', products);

  return (
    <>
      {setType === 'PRODUCTS' || setType === 'SINGLE_PRODUCT' ? (
        <Box
          columnGap={2}
          rowGap={5}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          {products?.map((item, key) => (
            <Card
              onClick={() => handleAdd('products', item)}
              key={key}
              sx={{
                p: 1.5,
                borderRadius: '10px',
                position: 'relative',
                overflow: 'unset',
                cursor: 'pointer',
                border: selectedValue?.some((i) => i?.uid === item?.uid)
                  ? '1px solid #B8E3BB'
                  : '1px solid transparent',
                transition: 'border 0.3s ease-in-out',
              }}
            >
              <CardMedia
                component="img"
                // eslint-disable-next-line react-hooks/rules-of-hooks
                src={item?.images.length > 0 ? useResource(item?.images[0]?.url, 'w_500') : ''}
                alt="products"
                sx={{ aspectRatio: 1 / 1 }}
              />
              <Stack direction="row" justifyContent="space-between" py={1}>
                <Typography variant="caption" color="#637381">
                  {item?.sapId}
                </Typography>
                <Typography variant="caption" color="#14AE5C">
                  In Stock({item?.stock})
                </Typography>
              </Stack>
              <TextMaxLine line={2} variant="subtitle2">
                {item?.name?.english}
              </TextMaxLine>
              <Typography display="flex" alignItems="center" pt={1} variant="subtitle1">
                OMR{' '}
                <Typography pl={1} variant="h6">
                  {item?.offer_price > 0 ? item?.offer_price : item?.price}
                </Typography>
                {item?.offer_price > 0 && (
                  <Typography pl={0.5} color="#637381" sx={{ textDecoration: 'line-through' }}>
                    {item?.price}
                  </Typography>
                )}
              </Typography>
              {selectedValue
                ?.filter((i) => i?.uid === item?.uid)
                .map((filteredItem) => (
                  <Tooltip key={filteredItem?.uid} title="Remove">
                    <IconButton
                      size="small"
                      color="default"
                      sx={{
                        backgroundColor: '#B8E3BB',
                        position: 'absolute',
                        top: -10,
                        right: -10,
                      }}
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ))}
            </Card>
          ))}
        </Box>
      ) : (
        <Box
          columnGap={2}
          rowGap={5}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          {products?.map((item, key) => (
            <Card
              onClick={() => handleAdd('products', item)}
              key={key}
              sx={{
                p: 1.5,
                borderRadius: '10px',
                position: 'relative',
                overflow: 'unset',
                cursor: 'pointer',
                border: selectedValue?.some((i) => i?.uid === item?.uid)
                  ? '1px solid #B8E3BB'
                  : '1px solid transparent',
                transition: 'border 0.3s ease-in-out',
              }}
            >
              <CardMedia
                component="img"
                // eslint-disable-next-line react-hooks/rules-of-hooks
                src={useResource(
                  item?.banner_image?.url || item?.logo?.url || item?.image?.url,
                  'w_500'
                )}
                alt="products"
                sx={{ aspectRatio: 1 / 1, objectFit: 'contain' }}
              />
              <TextMaxLine line={2} variant="subtitle2">
                {item?.name?.english || item?.title?.english}
              </TextMaxLine>
              {selectedValue
                ?.filter((i) => i?.uid === item?.uid)
                ?.map((filteredItem) => (
                  <Tooltip key={filteredItem?.uid} title="Remove">
                    <IconButton
                      size="small"
                      color="default"
                      sx={{
                        backgroundColor: '#B8E3BB',
                        position: 'absolute',
                        top: -10,
                        right: -10,
                      }}
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ))}
            </Card>
          ))}
        </Box>
      )}
    </>
  );
}

SelectedDetails.propTypes = {
  products: PropTypes.array,
  handleAdd: PropTypes.func.isRequired,
  selectedValue: PropTypes.array, // Ensure selectedValue is treated as an array
  setType: PropTypes.string,
};
