import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Grid, Stack, Button, Divider, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import OspImageUploader from 'src/pages/Common/upload';
import ProductData from 'src/pages/Common/Others/ProductData';
import {
  createCollection,
  getCollectionDetails,
  updateCollectionDetails,
  checkCollectionTitleValid,
} from 'src/server/api/collections';

// import OspSelectAutocomplete from 'src/components/hook-form/osp-select';
import FormProvider from 'src/components/hook-form';

import BasicDetailsForm from './BasicDetails';

// ----------------------------------------------------------------------

export default function CollectionData({ tabChange, setTabChange }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const params = useParams();
  const collectionId = params.id;

  const { collectionDetails, isCollectionTitleValid, loading } = useSelector((state) => ({
    collectionDetails: state.collection.collectionDetails,
    isCollectionTitleValid: state.collection.isCollectionTitleValid,
    loading: state.collection.loading,
  }));

  useEffect(() => {
    if (collectionId) {
      const credentials = {
        collectionId,
        dispatch,
      };
      dispatch(getCollectionDetails(credentials));
    }
  }, [dispatch, collectionId]);

  const [imagePreview, setImagePreview] = useState([]);

  // main Category
  const CollectionSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string().required('Title in English is required'),
      arabic: Yup.string(),
    }),
    descriptionEnglish: Yup.string(),
    descriptionArabic: Yup.string(),
    priority: Yup.number(),
    product: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    items: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
  });

  const defaultValues = useMemo(() => {
    const modifiedItemsList = collectionDetails?.items?.map((i) => ({
      images: i?.images,
      label: i?.name?.english,
      offer_price: i?.offer_price,
      price: i?.price,
      sapId: i?.sap_id,
      stock: i?.stock,
      value: i?.uid,
    }));
    if (collectionId) {
      return {
        title: {
          english: collectionDetails?.title?.english || '',
          arabic: collectionDetails?.title?.arabic || '',
        },
        descriptionEnglish: collectionDetails?.description?.english || '',
        descriptionArabic: collectionDetails?.description?.arabic || '',
        priority: collectionDetails?.priority || 0,
        is_active: collectionDetails?.is_active || false,
        product: {},
        items: modifiedItemsList || [],
      };
    }
    return {
      title: {
        english: '',
        arabic: '',
      },
      descriptionEnglish: '',
      descriptionArabic: '',
      priority: 0,
      product: { label: '', value: '' },
      items: [],
    };
  }, [collectionDetails, collectionId]);

  const methods = useForm({
    resolver: yupResolver(CollectionSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const product = watch('product');
  const english_title = watch('title.english');

  const {
    fields: itemsList,
    append: addItems,
    remove: removeItems,
  } = useFieldArray({
    control,
    name: 'items',
  });

  const handleAddItemToList = () => {
    if (product?.value) {
      addItems([product]);
      reset({
        ...watch(),
        product: { label: '', value: '' },
      });
    } else {
      console.error('Product is not selected or invalid');
    }
  };

  const handleRemoveItemToList = (value, index) => {
    removeItems(index);
    if (collectionDetails?.uid) {
      const isImageRemove = true;
      const productsValues = itemsList?.filter((item) => value !== item?.value);
      const cred = {
        state: {
          ...collectionDetails,
          logo: imagePreview?.length > 0 ? imagePreview[0] : {},
          items: productsValues?.map((item) => item?.value),
        },
        collectionId: collectionDetails?.uid,
        dispatch,
        isImageRemove,
      };
      dispatch(updateCollectionDetails(cred));
    }
  };

  useEffect(() => {
    if (collectionDetails) {
      reset(defaultValues);
    }
  }, [defaultValues, collectionDetails, reset]);

  const onSubmit = async (data) => {
    const createState = {
      ...data,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      items: data?.items?.map((item) => item?.value),
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };
    ['descriptionArabic', 'descriptionEnglish', 'product']?.forEach((key) => {
      delete createState[key];
    });
    if (!data.priority) {
      delete createState.priority;
    }
    const createCredentials = {
      state: createState,
      dispatch,
      router,
    };

    const updateState = {
      ...data,
      logo: imagePreview?.length > 0 ? imagePreview[0] : {},
      items: data?.items?.map((item) => item?.value),
      description: {
        english: data?.descriptionEnglish,
        arabic: data?.descriptionArabic,
      },
    };
    ['descriptionArabic', 'descriptionEnglish', 'product']?.forEach((key) => {
      delete updateState[key];
    });

    const updateCredentials = {
      state: updateState,
      collectionId: collectionDetails?.uid,
      dispatch,
    };

    if (collectionId) {
      dispatch(updateCollectionDetails(updateCredentials));
    } else {
      dispatch(createCollection(createCredentials));
    }
  };

  const handleNext = () => {
    setTabChange({
      value: 'products',
    });
  };

  useEffect(() => {
    if (english_title && english_title !== collectionDetails?.title?.english) {
      const createCredentials = {
        state: {
          english_title,
        },
        dispatch,
      };
      dispatch(checkCollectionTitleValid(createCredentials));
    }
  }, [dispatch, english_title, collectionDetails?.title?.english, collectionDetails?.uid]);

  useEffect(() => {
    if (collectionDetails?.uid && collectionDetails?.logo?.public_id) {
      setImagePreview([collectionDetails.logo] || []);
    }
  }, [collectionDetails]);

  return (
    <>
      {errors?.title?.english?.message && (
        <Stack alignItems="center" justifyContent="center" direction="row" m={2} mb={0}>
          <ReportProblemIcon fontSize="small" color="error" sx={{ mr: 0.5 }} />
          <Typography variant="caption" color="error">
            {errors?.title?.english?.message && errors.title.english.message}
          </Typography>
        </Stack>
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {collectionId && tabChange.value !== 'banners' && tabChange.value !== 'seo' && (
          <Stack direction="row" justifyContent="end" mx={2} my={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              loading={isSubmitting || loading}
              sx={{
                backgroundColor: '#106E58',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0b4e3e',
                },
              }}
            >
              {collectionId ? 'Save Changes' : 'Create Collection'}
            </LoadingButton>
          </Stack>
        )}
        {tabChange?.value === 'basicDetails' && (
          <BasicDetailsForm
            handleNext={handleNext}
            title={english_title}
            data={collectionDetails}
            isValid={isCollectionTitleValid}
          />
        )}
        {tabChange?.value === 'products' && (
          <>
            <ProductData
              control={control}
              handleAddProduct={handleAddItemToList}
              isSubmitting={isSubmitting}
              productDetails={itemsList || []}
              addItems={addItems}
              handleRemoveProduct={handleRemoveItemToList}
              isDisabled={!product?.value}
              setValue={setValue}
            />
            <Stack direction="row" justifyContent="end" mx={2} my={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                loading={isSubmitting || loading}
                disabled={itemsList?.length === 0}
                sx={{
                  backgroundColor: '#106E58',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#0b4e3e',
                  },
                }}
              >
                {collectionId ? 'Save Changes' : 'Create Collection'}
              </LoadingButton>
            </Stack>
          </>
        )}
        {collectionId && tabChange.value !== 'banners' && tabChange.value !== 'seo' && (
          <Stack
            position="absolute"
            bottom="0px"
            right="0px"
            direction="row"
            justifyContent="end"
            mx={2}
            my={2}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              loading={isSubmitting || loading}
              sx={{
                backgroundColor: '#106E58',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0b4e3e',
                },
              }}
            >
              {collectionId ? 'Save Changes' : 'Create Collection'}
            </LoadingButton>
          </Stack>
        )}
      </FormProvider>
      {tabChange?.value === 'basicDetails' && (
        <Grid xs={12} md={12}>
          <Stack sx={{ p: 2, pt: 0 }}>
            <Typography variant="subtitle2" sx={{ mb: 0, pb: 2 }}>
              Image <Divider sx={{ mt: 1 }} />
            </Typography>
            <OspImageUploader
              folder="collections"
              setImagesPreview={setImagePreview}
              imagesPreview={imagePreview}
              single
            />
          </Stack>
          <Stack direction="row" justifyContent="end" mx={2} mb={2}>
            {!collectionId && (
              <Button variant="outlined" color="inherit" onClick={handleNext}>
                Next
              </Button>
            )}
          </Stack>
        </Grid>
      )}
    </>
  );
}

CollectionData.propTypes = {
  tabChange: PropTypes.any,
  setTabChange: PropTypes.any,
};
