import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BannerList from './sections/bannerList';

export default function Banner() {
  return (
    <>
      <MetaHelmet title="Banner" />

      <BannerList />
    </>
  );
}
