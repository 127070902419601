import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import SpecialSaleList from './specialSale';

export default function SpecialSale() {
  return (
    <>
      <MetaHelmet title="Special Sale" />

      <SpecialSaleList />
    </>
  );
}
