import { useState } from 'react';

// Import the user icon

import { useParams } from 'react-router';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Tab, Tabs, Stack, alpha } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BasicDetailsForm from '../BasicDetails';
import RedeemedUserList from '../RedeemedUserList';

// ----------------------------------------------------------------------

export default function CouponCreatePage() {
  const params = useParams();

  const couponId = params.id;

  const STATUS_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'redeemedUsers', label: 'Redeemed Users' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  return (
    <>
      <MetaHelmet title="Coupon Crud" />
      {/* <CustomBreadcrumbs
        heading={couponId ? 'Update Coupon' : 'Create Coupon'}
        links={[
          {
            name: 'Site Settings',
            href: paths.dashboard.root,
          },
          {
            name: 'Coupon',
            href: paths.dashboard.inventory.coupon,
          },

          {
            name: couponId ? 'Create Coupon' : 'Update Coupon',
          },
        ]}
        sx={{
          mb: { xs: 1, md: 2 },
        }}
      /> */}
      <Grid container spacing={2}>
        <Grid xs={12} md={10} xl={8}>
          <Card>
            {couponId && (
              <Tabs
                value={tabChange.value}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab, key) => (
                  <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            )}

            {tabChange.value === 'basicDetails' && <BasicDetailsForm />}
            {tabChange.value === 'redeemedUsers' && (
              <Stack p={2}>
                <RedeemedUserList couponId={couponId} />
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

CouponCreatePage.propTypes = {};
