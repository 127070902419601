import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createBrand,
  deleteBrand,
  getAllBrands,
  restoreBrand,
  archiveBrand,
  getBrandCounts,
  getBrandDetails,
  getAllMainBrand,
  changeBrandStatus,
  updateBrandDetails,
  checkBrandNameValid,
} from '../api/brand';

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    loading: false,
    credLoading: false,
    error: {},
    brands: {},
    staffDropList: [],
    brandDetails: {},
    brandCounts: {},
    isBrandValid: {},
    brandNameLoading: false,
    delLoading: false,
    mainBrands: {},
    statusLoading: false,
    mainBrandLoading: false,
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearBrandDetailsState: (state) => {
      state.brandDetails = {};
    },
  },
  extraReducers: {
    // create brand details
    [createBrand.pending]: (state) => {
      state.credLoading = true;
    },
    [createBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const newDistrictsList = jsonState?.brands;

      const modifiedBrandList = {
        ...jsonState,
        brands: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };

      state.credLoading = false;
      state.brandDetails = action.payload;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [createBrand.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    // get all brands
    [getAllBrands.pending]: (state) => {
      state.loading = true;
    },
    [getAllBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload;
      state.error = {};
    },
    [getAllBrands.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all main brands
    [getAllMainBrand.pending]: (state) => {
      state.mainBrandLoading = true;
    },
    [getAllMainBrand.fulfilled]: (state, action) => {
      state.mainBrandLoading = false;
      state.mainBrands = action.payload;
      state.error = {};
    },
    [getAllMainBrand.rejected]: (state, action) => {
      state.mainBrandLoading = false;
      state.error = action.error;
    },

    [getBrandCounts.pending]: (state) => {
      state.loading = true;
    },
    [getBrandCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.brandCounts = action.payload;
      state.error = {};
    },
    [getBrandCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get single staff details
    [getBrandDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBrandDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.brandDetails = action.payload;
      state.error = {};
    },
    [getBrandDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update brand details
    [updateBrandDetails.pending]: (state) => {
      state.credLoading = true;
    },
    // [updateBrandDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateBrandDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
      };

      state.credLoading = false;
      // state.brands = modifiedBrandList;
      state.brandDetails = action.payload;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    // [updateBrandDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.brands;
    //   console.log(jsonState)
    //   const modifiedBrandList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CategoryDetails = action.payload;
    //   state.error = {};
    // },
    [updateBrandDetails.rejected]: (state, action) => {
      state.credLoading = false;
      state.error = action.error;
    },

    //  brand status change
    [changeBrandStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    // [changeBrandStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.brands;
    //   const modifiedBrandList = {
    //     ...jsonState,
    //     brands: jsonState?.brands?.map((item) =>
    //       item?.uid === action.payload?.uid
    //         ? { ...item, is_active: action.payload?.is_active }
    //         : item
    //     ),
    //   };
    //   state.statusLoading = false;
    //   state.brands = modifiedBrandList;
    //   state.error = {};
    // },
    [changeBrandStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState?.brands?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedBrandList2 = {
        ...jsonState,
        brands: jsonState?.brands?.filter((item) => item?.uid !== action.payload?.response?.uid),
      };
      state.statusLoading = false;
      state.brands =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedBrandList2
          : modifiedBrandList;
      state.error = {};
    },
    [changeBrandStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
    // check brand name
    [checkBrandNameValid.pending]: (state) => {
      state.brandNameLoading = true;
    },
    [checkBrandNameValid.fulfilled]: (state, action) => {
      state.brandNameLoading = false;
      state.isBrandValid = action.payload;
      state.error = {};
    },
    [checkBrandNameValid.rejected]: (state, action) => {
      state.brandNameLoading = false;
      state.error = action.error;
    },
    [deleteBrand.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.filter((brands) => brands.uid !== action.payload),
      };
      state.delLoading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [deleteBrand.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // archive brand
    [archiveBrand.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.filter((brand) => brand.uid !== action.payload),
      };

      state.delLoading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [archiveBrand.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore brand
    [restoreBrand.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.filter((brand) => brand.uid !== action.payload),
      };

      state.delLoading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [restoreBrand.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearBrandDetailsState } = brandSlice.actions;

export default brandSlice.reducer;
