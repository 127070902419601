import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Tooltip, Container } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { varBounce, MotionContainer } from 'src/components/animate';

import Searchbar from '../common/searchbar';
import { NAV, HEADER } from '../config-layout';
import ComponentTitle from '../common/componentTitle';
import AccountPopover from '../common/account-popover';
// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const settings = useSettingsContext();
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const changeTheme = (currentTheme) => {
    settings.onUpdate('themeMode', currentTheme);
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}
      <Stack flexDirection="row">
        <Tooltip title="go back">
          <IconButton size="small" onClick={() => navigate(-1)}>
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
        </Tooltip>
        <Searchbar />
        <Stack>
          {!mdUp && (
            <Logo
              sx={{
                zIndex: 9,
                m: { xs: 2, md: 5 },
              }}
            />
          )}
        </Stack>
      </Stack>

      <Stack />
      <Stack flexGrow={1}>
        <ComponentTitle />
      </Stack>
      <Stack
        // flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}

        <Stack>
          <Container
            component={MotionContainer}
            className="padding-0-container"
            sx={{ m: 0, p: 0 }}
          >
            <Stack flexDirection="row" alignItems="center">
              {settings?.themeMode === 'dark' ? (
                <m.div
                  variants={varBounce().inDown}
                  transition={{
                    mass: 400,
                    stiffness: 400,
                  }}
                >
                  <WbSunnyIcon sx={{ cursor: 'pointer' }} onClick={() => changeTheme('light')} />
                </m.div>
              ) : (
                <DarkModeIcon sx={{ cursor: 'pointer' }} onClick={() => changeTheme('dark')} />
              )}
            </Stack>
          </Container>
        </Stack>

        <AccountPopover changeTheme={changeTheme} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
