import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProductVariationValue,
  getAllProductVariationValue,
  deleteProductVariationValue,
  archiveProductVariationValue,
  restoreProductVariationValue,
  getProductVariationValueCounts,
  getProductVariationValueDetails,
  updateProductVariationValueDetails,
} from '../api/productVariationValue';

export const productVariationValueSlice = createSlice({
  name: 'product-variation-value',
  initialState: {
    loading: false,
    error: {},
    productVariationValues: {},
    staffDropList: [],
    ProductVariationValueDetails: {},
    delLoading: false,
    productVariationValueCounts: {},
  },
  reducers: {
    clearProductVariationValueError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createProductVariationValue.pending]: (state) => {
      state.loading = true;
    },
    [createProductVariationValue.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariationValues;
      const newDistrictsList = jsonState?.productVariationValues;

      const modifiedDistrictList = {
        ...jsonState,
        productVariationValues: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.ProductVariationValueDetails = action.payload;
      state.productVariationValues = modifiedDistrictList;
      state.error = {};
    },
    [createProductVariationValue.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProductVariationValue.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductVariationValue.fulfilled]: (state, action) => {
      state.loading = false;
      state.productVariationValues = action.payload;
      state.error = {};
    },
    [getAllProductVariationValue.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getProductVariationValueDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductVariationValueDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.ProductVariationDValueetails = action.payload;
      state.error = {};
    },
    [getProductVariationValueDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProductVariationValueDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductVariationValueDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductVariationDValueetails = action.payload;
    //   state.error = {};
    // },
    [updateProductVariationValueDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariationValues;

      console.log('jsonState =>', jsonState);

      const modifiedDistrictList = {
        ...jsonState,
        productVariationValues: jsonState.productVariationValues?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      console.log('modifiedDistrictList =>', modifiedDistrictList);

      state.loading = false;
      // state.productVariationValues = modifiedDistrictList;
      state.ProductVariationDValueetails = action.payload;
      state.productVariationValues = modifiedDistrictList;
      state.error = {};
    },

    [updateProductVariationValueDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // archive product
    [archiveProductVariationValue.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveProductVariationValue.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariationValues;
      console.log(action?.payload);

      console.log('data :', jsonState);

      const modifiedProductVariationValue = {
        ...jsonState,
        productVariationValues: jsonState.productVariationValues?.filter(
          (productVariationValues) => productVariationValues.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.productVariationValues = modifiedProductVariationValue;
      state.error = {};
    },
    [archiveProductVariationValue.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // delete product
    [deleteProductVariationValue.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteProductVariationValue.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariationValues;

      const modifiedProductVariationValue = {
        ...jsonState,
        productVariationValues: jsonState.productVariationValues?.filter(
          (productVariationValues) => productVariationValues.uid !== action.payload
        ),
      };
      state.delLoading = false;
      state.productVariationValues = modifiedProductVariationValue;
      state.error = {};
    },
    [deleteProductVariationValue.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // restore product
    [restoreProductVariationValue.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreProductVariationValue.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariationValues;

      const modifiedProductVariationValue = {
        ...jsonState,
        productVariationValues: jsonState.productVariationValues?.filter(
          (productVariationValues) => productVariationValues.uid !== action.payload
        ),
      };

      state.delLoading = false;
      state.productVariationValues = modifiedProductVariationValue;
      state.error = {};
    },
    [restoreProductVariationValue.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // get product count
    [getProductVariationValueCounts.pending]: (state) => {
      // state.loading = true;
    },
    [getProductVariationValueCounts.fulfilled]: (state, action) => {
      // state.loading = false;
      state.productVariationValueCounts = action.payload;
      state.error = {};
    },
    [getProductVariationValueCounts.rejected]: (state, action) => {
      // state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductVariationError } = productVariationValueSlice.actions;

export default productVariationValueSlice.reducer;
